import { IconType } from 'react-icons';

import useTranslation from '@/hooks/i18n';

interface Props {
  text: string
  textColor: string
  Icon: IconType
  iconColor: string
}

function IconText({ text, textColor, Icon, iconColor }: Props) {
  const { t } = useTranslation()

  return (
    <div className="relative flex items-center mb-8">
      <Icon className={`absolute top-2 ${iconColor}`} />
      <div className="ml-6">
        <p className={`text-md mt-1 ${textColor}`}>{t(text)}</p>
      </div>
    </div>
  )
}

export default IconText
