import OutFrame from '@/components/PublicPages/OutFrame'
import { privatePaths } from '@/utils/path'

export default function SomethingWentWrong() {
  return (
    <OutFrame
      title="tr_oops"
      subTitle="tr_somethingWentWrong"
      titleColor="text-red-500"
      buttonName="tr_goBackHome"
      to={privatePaths.home}
    />
  )
}
