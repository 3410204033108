import { useEffect, useState } from 'react'

import { passwordChange } from '@/auth/token'
import Button from '@/components/Parts/Button/Blue'
import Password from '@/components/Parts/Inputs/Password'
import { useSpinner } from '@/hooks/spinner'
import useTranslation from '@/hooks/i18n'

interface Props {
  success: () => void
}

function Operation({ success }: Props) {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState('')
  const { enableSpinner, disableSpinner } = useSpinner()
  const { t } = useTranslation()

  useEffect(() => {
    setDisabled(oldPassword.length < 8 || newPassword.length < 8)
  }, [oldPassword, newPassword])

  const click = async (oldPwd: string, newPwd: string) => {
    if (oldPwd.length < 8 || newPwd.length < 8) {
      return
    }

    if (oldPwd === newPwd) {
      setError(t('tr_samePassword'))
      return
    }

    setError('')
    enableSpinner()
    passwordChange(oldPwd, newPwd)
      .then(() => {
        success()
        disableSpinner()
      })
      .catch((err) => {
        disableSpinner()
        const result =
          err.message === 'Incorrect username or password.'
            ? 'Incorrect password'
            : err.message
        setError(result)
      })
  }

  return (
    <>
      <div className="mb-4 px-8">
        <Password
          title="tr_oldPassword"
          autoFocus={true}
          password={oldPassword}
          onChange={(password) => {
            setOldPassword(password)
          }}
          keyDown={(password) => {
            setOldPassword(password)
            click(password, newPassword)
          }}
        />
      </div>
      <div className="mb-4 px-8">
        <Password
          title="tr_newPassword"
          password={newPassword}
          onChange={(password) => {
            setNewPassword(password)
          }}
          keyDown={(password) => {
            setNewPassword(password)
            click(oldPassword, password)
          }}
        />
      </div>
      <p className="mb-8 text-red-500 text-sm px-8">{error}</p>

      <div className="mt-4 text-right px-8 py-2 pb-4">
        <Button
          name="tr_changePassword"
          onClick={() => click(oldPassword, newPassword)}
          disabled={disabled}
        />
      </div>
    </>
  )
}

export default Operation
