import useTranslation from '@/hooks/i18n'
import Button from '@/components/Parts/Button/Red'
import { useModal } from '@/hooks/modal'

type Props = {
  message: string
}

export default function ConfirmError({ message }: Props) {
  const { t } = useTranslation()
  const { closeModal } = useModal()
  return (
    <div className="py-8 w-96">
      <h1 className="text-red-600 text-xl font-bold">
        {t('tr_failedToPayment')}
      </h1>
      <p>{message}</p>
      <div className="mt-8">
        <Button name="tr_close" onClick={closeModal} />
      </div>
    </div>
  )
}
