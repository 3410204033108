import { ReactNode } from 'react'
import useTranslation from '@/hooks/i18n'
import PageTitle from '@/components/Parts/PageTitle'
import { useVersion } from '@/hooks/version'
import Reload from '@/components/Parts/Modal/Reload'

interface Props {
  title: string
  children: ReactNode
}

export default function PageFrame({ children, title }: Props) {
  const { t } = useTranslation()
  const isChanged = useVersion()

  console.log(isChanged)

  return (
    <>
      <PageTitle title={title}>
        <div className="max-w-4xl mx-auto">
          <div className="w-full border-b-2 border-indigo-500 p-1">
            <h1 className="text-2xl font-bold py-2">{t(title)}</h1>
          </div>
          <div className="mt-8">{children}</div>
        </div>
      </PageTitle>
      {isChanged && <Reload />}
    </>
  )
}
