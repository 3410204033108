import PageFrame from './PageFrame'

export default function withPageFrame(
  title: string,
  Component: () => JSX.Element
) {
  return () => (
    <PageFrame title={title}>
      <Component />
    </PageFrame>
  )
}
