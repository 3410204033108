import useTranslation, { useNumberFormatter } from '@/hooks/i18n'
import { InvoiceForService } from '@/types'
import { toDate } from '@/utils'

type Props = {
  invoices: InvoiceForService[]

  currency: string
}

export default function Breakdown({ invoices, currency }: Props) {
  const { t, d } = useTranslation()
  const n = useNumberFormatter(currency)

  return (
    <div className="mt-8 w-full overflow-auto">
      <table className="w-full ">
        <thead className="border-b border-gray-300">
          <tr className="text-yellow-600">
            <th className="text-left">{t('tr_service')}</th>
            <th className="w-1/3 text-left">{t('tr_option')}</th>
            <th className="text-right">{t('tr_rate')}</th>
            <th className="text-right">{t('tr_quantity')}</th>
            <th className="text-right">{t('tr_subtotal')}</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, i) => {
            const isDiscount = invoice.options.some((option) =>
              option.optionName.startsWith('Discount for')
            )

            return invoice.options.map((option, index) => {
              const rate = isDiscount ? option.cost : option.rate
              const subtotal = isDiscount
                ? option.beforeDiscount
                : option.subtotal

              return (
                <>
                  <tr className="text-sm border-b border-gray-300" key={index}>
                    {index === 0 && (
                      <td
                        className="text-left breakwords py-2 pr-2"
                        rowSpan={invoice.options.length}
                      >
                        <p
                          className="text-gray-800"
                          id={`qcp-invoice-breakdown-p-service-name-${index}`}
                        >
                          {t(invoice.serviceName)}
                        </p>
                      </td>
                    )}
                    <td className="w-1/3 text-left break-words py-2 pr-2">
                      <p>
                        <span
                          className=""
                          id={`qcp-invoice-breakdown-span-option-name-${index}`}
                        >
                          {t(option.optionName)}
                        </span>
                        <br />
                        {option.startAt &&
                          option.endAt &&
                          option.startAt !== option.endAt && (
                            <span className="text-gray-600">
                              {d('ymd', toDate(option.startAt))} 〜{' '}
                              {d('ymd', toDate(option.endAt))}{' '}
                            </span>
                          )}
                      </p>
                    </td>
                    <td
                      className="text-right break-words pr-2"
                      id={`qcp-invoice-breakdown-td-rate-${index}`}
                    >
                      {n('currency', rate)}
                    </td>
                    <td
                      className="text-right break-words pr-2"
                      id={`qcp-invoice-breakdown-td-quantity-${index}`}
                    >
                      {n('default', option.quantity)}
                    </td>
                    <td
                      className="text-right break-words pr-2"
                      id={`qcp-invoice-breakdown-td-subtotal-${index}`}
                    >
                      {n('currency', subtotal)}
                    </td>
                  </tr>
                </>
              )
            })
          })}
        </tbody>
      </table>
    </div>
  )
}
