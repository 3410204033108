import { FaKey } from 'react-icons/fa'

import useTranslation from '@/hooks/i18n'
import { useModal } from '@/hooks/modal'

import ModalChangePassword from './ModalChangePassword'

interface Props {
  close: () => void
}

const ChangePassword = ({ close }: Props) => {
  const { openModal } = useModal()
  const { t } = useTranslation()

  return (
    <div
      className={`flex items-center hover:bg-gray-100 cursor-pointer px-4 py-2`}
      onClick={() => {
        openModal(<ModalChangePassword />)
        close()
      }}
    >
      <FaKey />
      <p className="ml-2">{t('tr_changePassword')}</p>
    </div>
  )
}

export default ChangePassword
