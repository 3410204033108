import useTranslation from '@/hooks/i18n'
import Stripe from '@/components/Svg/StripeWordmarkBlurple'
import QCLogo from '@/components/Svg/CustomerportalLogo'
import { ImArrowRight } from 'react-icons/im'

type Props = {
  message: string
}

export default function ConfirmRedirect({ message }: Props) {
  const { t } = useTranslation()

  return (
    <div className="max-w-md">
      <div className="w-full flex items-center mb-4">
        <div className="w-24">
          <QCLogo subColor={'customerportal_logo_svg__st1'} />
        </div>
        <ImArrowRight className=" text-gray-400 font-bold ml-4" />
        <div className="w-24">
          <Stripe
            className="cursor-pointer"
            onClick={() => window.open('https://stripe.com/', '_blank')}
          />
        </div>
      </div>
      <p className="text-gray-700 mb-4">{t(message)}</p>
    </div>
  )
}
