import TrialToSubscription from './TrialToSubscription'
import Button from '@/components/Parts/Button/Blue'
import { useModal } from '@/hooks/modal'

type Props = {
  serviceKey: string
  currency: string
}

export default function Buy({ serviceKey, currency }: Props) {
  const { openModal } = useModal()

  return (
    <Button
      name="Buy"
      onClick={() => {
        openModal(
          <TrialToSubscription serviceKey={serviceKey} currency={currency} />
        )
      }}
      id="qcp-qtc-button-buy"
    />
  )
}
