import { FaBars } from 'react-icons/fa'
import DropDown from '@/components/Parts/Menu/DropDown'
import { useLeftBar } from '@/hooks/leftBar'
import { UserIcon, UserMenu } from './User'
import QPLogo from '@/components/Svg/CustomerportalLogo'

function Menu() {
  const { isOpenLeftBar, openLeftBar, closeLeftBar } = useLeftBar()

  const toggleNav = () => {
    if (isOpenLeftBar) {
      closeLeftBar()
    } else {
      openLeftBar()
    }
  }

  return (
    <div className="z-50 ">
      <header className="fixed w-full bg-gray-900 border-b border-blue-700 outline-none focus:outline-none shadow-lg print:hidden">
        <nav className="flex items-center mx-auto  py-2 px-2 md:px-8">
          <div
            // ref={refNavBurger}
            className="text-4xl mr-2 cursor-pointer text-white"
            onClick={toggleNav}
          >
            <FaBars />
          </div>
          <QPLogo className="w-36 mx-auto sm:ml-8" />
          <div className="ml-auto h-full flex items-center justify-left">
            <div className="flex items-center ml-4">
              <DropDown Icon={UserIcon} Menu={UserMenu} />
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}

export default Menu
