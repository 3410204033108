import { useState } from 'react'
import withPageFrame from '@/components/PrivatePages'
import CardList from '@/components/CreditCardList'
import Box from '@/components/PrivatePages/Box'
import { useQuery } from '@apollo/client'
import { AVAILABLE_CARDS } from '@/api/graphql/queries'
import { Card } from '@/types'
import Loader from '@/components/Parts/Loader'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { publicPaths } from '@/utils/path'
import RegisterCard from '@/components/RegisterCard'
import useTranslation from '@/hooks/i18n'
import ConfirmSelect from '@/components/CreditCard/ConfirmSelect'
import { useModal } from '@/hooks/modal'
import { privatePaths } from '@/utils/path'
import { useSpinner } from '@/hooks/spinner'

function Page() {
  const [searchParams] = useSearchParams()
  const serviceKey = searchParams.get('id')
  const { data, error, loading } = useQuery<{
    availableCards: Card[]
  }>(AVAILABLE_CARDS)
  const { t } = useTranslation()
  const [cardKey, setCardKey] = useState('')
  const navigate = useNavigate()
  const { openModal, closeModal } = useModal()
  const { disableSpinner } = useSpinner()

  if (serviceKey == null) {
    navigate(publicPaths.somethingWentWrong)
    return <></>
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    navigate(publicPaths.somethingWentWrong)
    return <></>
  }

  const select = (cardKey: string) => {
    openModal(
      <ConfirmSelect
        cardKey={cardKey}
        serviceKey={decodeURIComponent(serviceKey)}
        refetch={() =>
          new Promise(() => {
            setCardKey(cardKey)
            closeModal()
            disableSpinner()
            navigate(privatePaths.home)
          })
        }
      />
    )
  }

  console.log(data!.availableCards.length)

  return (
    <>
      {data!.availableCards.length > 0 && (
        <Box title="Purchasing by a registered card">
          <>
            <p className="mb-4 text-gray-700">
              {t('tr_selectCardForAutoRenew')}
            </p>

            <div className="md:flex md:items-start px-2 py-4">
              <div className="w-full grid 2xl:grid-cols-3 grid-cols-1 gap-4">
                <CardList
                  cards={data!.availableCards}
                  cardKey={cardKey}
                  select={select}
                />
              </div>
            </div>
          </>
        </Box>
      )}
      <div className={`${data!.availableCards.length > 0 && 'mt-32'}`}>
        <RegisterCard
          redirectURL={privatePaths.home}
          serviceKey={decodeURIComponent(serviceKey)}
        />
      </div>
    </>
  )
}

export default withPageFrame('Select a card for auto renew', Page)
