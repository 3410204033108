import axios from 'axios'
import { ApiStatus } from '../version'

const instance = axios.create({
  baseURL: process.env.REACT_APP_REST_API_ENDPOINT!,
})

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const apiStatus = new ApiStatus(error.response.status)
      if (apiStatus.shouldReload()) {
        apiStatus.reload()
        return
      }
    }
    return Promise.reject(error)
  }
)

export default instance
