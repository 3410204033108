import { gql } from '@apollo/client'

export const QUERY_ME = gql`
  {
    me {
      role
      email
      organization
      fullName
    }
  }
`

export const QUERY_SERVICE_LIST = gql`
  {
    services {
      name
      serviceType
      serviceKey
      startAt
      expireAt
      status
      processing
      option1
      option2
      option3
      option4
      option5
      option6
      option7
    }
  }
`

export const QUERY_SERVICE = gql`
  query getService($serviceKey: String!) {
    service(serviceKey: $serviceKey) {
      name
      serviceType
      serviceKey
      startAt
      expireAt
      status
      processing
      currency
      option1
      option2
      option3
      option4
      option5
      option6
      option7
    }
  }
`

export const SESSION_CARDS = gql`
  query getSessionCards($sessionId: String!) {
    availableCards {
      cardKey
      name
      lastNumber
      expMonth
      expYear
      brand
    }
    sessionInvoice(sessionId: $sessionId) {
      subtotal
      total
      tax
      currency
    }
  }
`

export const INVOICE = gql`
  query getInvoice($invoiceId: String!) {
    invoice(invoiceId: $invoiceId) {
      invoiceId
      issuedAt
      fromOrg {
        name
        country
        zip
        address1
        address2
        address3
        phone
      }
      toOrg {
        name
        country
        zip
        address1
        address2
        address3
        phone
      }
      currency
      invoices {
        serviceName
        options {
          optionName
          rate
          quantity
          subtotal
          startAt
          endAt
          cost
          beforeDiscount
          paymentTerm
        }
      }
      subtotal
      total
      tax
    }
  }
`

export const TRANSACTION_DETAILS = gql`
  query getInvoice($invoiceId: String!) {
    invoice(invoiceId: $invoiceId) {
      invoiceId
      issuedAt
      currency
      invoices {
        serviceName
        options {
          optionName
          rate
          quantity
          subtotal
          startAt
          endAt
          paymentTerm
          cost
          beforeDiscount
        }
      }
      subtotal
      total
      tax
      status
    }
    eventAndLogs(invoiceId: $invoiceId) {
      eventId
      message
      createdAt
      eventType
      option1
      option2
      option3
      option4
      option5
      option6
      option7
    }
  }
`

export const SESSION_INVOICE = gql`
  query getSessionInvoice($sessionId: String!) {
    sessionInvoice(sessionId: $sessionId) {
      invoiceId
      issuedAt
      fromOrg {
        name
        country
        zip
        address1
        address2
        address3
        phone
      }
      toOrg {
        name
        country
        zip
        address1
        address2
        address3
        phone
      }
      currency
      invoices {
        serviceName
        options {
          optionName
          rate
          cost
          quantity
          subtotal
          beforeDiscount
          paymentTerm
          startAt
          endAt
        }
      }
      subtotal
      total
      tax
    }
  }
`

export const SERVICE_CONTRACT_DETAILS = gql`
  query getServiceContractDetails($serviceKey: String!) {
    serviceContractDetails(serviceKey: $serviceKey) {
      customerName
      customerKey
      billingMethod
      serviceName
      description
      status
      createAt
      startAt
      expireAt
      purchaseAt
      purchasedBy
      currency
      lastAvailableDate
      quantity
      option1
      option2
      option3
      option4
      option5
      option6
      option7
      nextContract {
        startAt
        endAt
        quantity
        paymentDate
        paymentStatus
        option1
        option2
        option3
        option4
        option5
        option6
        option7
      }
      servicePrice {
        serviceName
        serviceKey
        quantity
        total
        term
      }
    }
    cardForService(serviceKey: $serviceKey) {
      name
      lastNumber
      brand
      expMonth
      expYear
      cardKey
      cardOwner {
        userName
        email
      }
    }
  }
`

export const INVOICE_HISTORY = gql`
  query getInvoiceHistory($offset: Int!, $limit: Int!) {
    invoiceHistory(offset: $offset, limit: $limit) {
      invoiceId
      createAt
      status
      subtotal
      tax
      total
      services
      currency
    }
  }
`

export const AVAILABLE_CARDS = gql`
  {
    availableCards {
      cardKey
      name
      lastNumber
      expMonth
      expYear
      brand
      cardOwner {
        userName
        email
      }
      services {
        serviceName
        serviceKey
      }
      processing
    }
  }
`

export const AVAILABLE_CARDS_FOR_SERVICE = gql`
  query getAvailableCardForService($serviceKey: String!) {
    availableCards {
      cardKey
      name
      lastNumber
      expMonth
      expYear
      brand
      cardOwner {
        userName
        email
      }
      services {
        serviceName
        serviceKey
      }
    }
    cardForService(serviceKey: $serviceKey) {
      name
      lastNumber
      brand
      expMonth
      expYear
      cardKey
      cardOwner {
        userName
        email
      }
    }
  }
`

export const CARD_FOR_SERVICE = gql`
  query getCardForService($serviceKey: String!) {
    cardForService(serviceKey: $serviceKey) {
      name
      lastNumber
      brand
      expMonth
      expYear
      cardKey
      cardOwner {
        userName
        email
      }
    }
  }
`

// export const EVENT_LOG = gql`
//   {
//     eventLog {
//       eventKey
//       customerKey
//       logLevel
//       traceId
//       code
//       eventAt
//       rawData
//       ipAddr
//       actor {
//         actorType
//         email
//       }
//       order {
//         orderKey
//         orderAt
//         services {
//           serviceKey
//           serviceName
//           status
//           options {
//             details
//             quantity
//             startAt
//             endAt
//           }
//         }
//         quantity
//         subtotal
//         total
//         tax
//         currency
//       }
//       payment {
//         paymentKey
//         orderKey
//         paymentAt
//         subtotal
//         total
//         tax
//         currency
//         status
//         services {
//           serviceKey
//           serviceName
//           options {
//             details
//             quantity
//             startAt
//             endAt
//           }
//           status
//         }
//         card {
//           cardKey
//           name
//           lastNumber
//           expMonth
//           expYear
//         }
//       }
//       service {
//         serviceKey
//         serviceName
//         options {
//           details
//           quantity
//           startAt
//           endAt
//         }
//         status
//       }
//       emailTo {
//         name
//         email
//         kind
//       }
//       card {
//         cardKey
//         name
//         lastNumber
//         expMonth
//         expYear
//       }
//       userAccount {
//         name
//         email
//         role
//       }
//     }
//   }
// `

export const CANCELLING_SUBSCRIPTION = gql`
  query getCancellingSubscription($serviceKey: String!) {
    cancellingSubscription(serviceKey: $serviceKey) {
      endAt
    }
  }
`

export const SERVICE_CONFIGURATION = gql`
  query getServiceConfiguration($serviceKey: String!, $lang: String!) {
    serviceConfiguration(serviceKey: $serviceKey, lang: $lang) {
      html
    }
  }
`

export const CALC_PRICE = gql`
  query getCalcPrice(
    $id: ID
    $serviceKey: String
    $orderType: String
    $couponCode: String
    $quantity: String
  ) {
    calcPrice(
      id: $id
      serviceKey: $serviceKey
      orderType: $orderType
      couponCode: $couponCode
      quantity: $quantity
    ) {
      id
      total
      currency
      tax
      options {
        optionName
        rate
        quantity
        subtotal
        paymentTerm
        option1
        option2
        option3
        option4
        option5
        option6
        option7
      }
    }
  }
`
