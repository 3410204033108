import { useEffect, useState } from 'react';

import { useLeftBar } from './leftBar';

/**
 * Return current window height
 * When window size is changed, the height is also changed.
 *
 * @returns height
 *
 */
export function useHeight() {
  const [height, setHeight] = useState(0)

  const { isOpenLeftBar } = useLeftBar()

  useEffect(() => {
    const resizeEvent = () => {
      if (window != null) {
        setHeight(window.innerHeight)
      }
    }

    if (window != null) {
      setHeight(window.innerHeight)
      window.addEventListener('resize', resizeEvent)
    }

    return () => {
      if (window != null) {
        window.removeEventListener('resize', resizeEvent)
      }
    }
  }, [isOpenLeftBar])

  return { height } as const
}
