import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import version from '@/api/restApi/version'

export function useVersion() {
  const location = useLocation()
  const [isChanged, setIsChanged] = useState(false)

  const checkVersionChanged = useCallback(() => {
    version()
      .then((result) => {
        setIsChanged(result)
        console.log(result)
      })
      .catch(() => {
        // Maybe network error, nothing to do
      })
  }, [])

  useEffect(() => {
    checkVersionChanged()
    // console.log(location)
  }, [location, checkVersionChanged])

  useEffect(() => {
    const timer = setInterval(() => {
      checkVersionChanged()
    }, 300 * 1000)

    return () => {
      clearInterval(timer)
    }
  }, [checkVersionChanged])

  return isChanged
}
