import Support from './Support'
import { officialCompanyName } from '@/utils'

function Footer() {
  return (
    <footer className="bg-gray-200 text-gray-800 bottom-0 mt-10 pt-8 w-full">
      <div className="mx-auto w-full md:w-56 mb-4">
        <Support />
      </div>
      <div>
        <p className="p-2 text-xs text-gray-700  text-center">
          {`Copyright © 2022 ${officialCompanyName} All Rights Reserved.`}
        </p>
      </div>
    </footer>
  )
}

export default Footer
