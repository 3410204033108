import useTranslation from '@/hooks/i18n'
import { NEXT_MONTH_INVOICE_ID, toDate } from '@/utils'

interface Props {
  invoiceID: string
  issuedAt: string
}

export default function InvoiceMeta({ invoiceID, issuedAt }: Props) {
  const { t, d } = useTranslation()

  return (
    <div className="text-sm px-2 md:px-8">
      <table>
        <tbody>
          {invoiceID.startsWith(NEXT_MONTH_INVOICE_ID) === false && (
            <tr className="">
              <td className="font-bold pr-8 align-top">{t('ID')}</td>
              <td>{invoiceID}</td>
            </tr>
          )}
          {invoiceID.startsWith(NEXT_MONTH_INVOICE_ID) === true && (
            <tr className="">
              <td className="font-bold pr-8 align-top">
                {t('tr_invoiceForNextMonth')}
              </td>
              <td className="text-red-600 font-bold rounded-lg">
                {t('tr_draft')}
              </td>
            </tr>
          )}

          <tr className="">
            <td className="font-bold pr-8 align-top">{t('tr_dateOfIssue')}</td>
            <td>{d('ymdhms', toDate(issuedAt))}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
