import { Outlet } from 'react-router-dom';

import Modal from '@/components/Parts/Modal/CommonLayout';
import Spinner from '@/components/Parts/Spinner';
import { useHeight } from '@/hooks/height';

import LeftBar from './LeftBar';
import Main from './Main';
import Menu from './Menu';

const Layout = () => {
  const { height } = useHeight()

  return (
    <>
      <div className={`relative flex h-full`}>
        <Menu />
        <LeftBar height={height} />
        <Main>
          <Outlet />
        </Main>
      </div>
      <Modal />
      <Spinner />
    </>
  )
}

export default Layout
