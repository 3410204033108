import '@/hooks/i18n/configs'

import Context from '@/contexts'
import Router from '@/router'

function App() {
  return (
    <Context>
      <Router />
    </Context>
  )
}

export default App
