import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  checked: boolean
  onClick: () => void
  id?: string
}

function Index({ children, checked, onClick, id }: Props) {
  return (
    <div className="flex">
      <div className="">
        <input
          checked={checked}
          className="text-blue-500 w-4 h-4 mr-2 focus:ring-blue-400 focus:ring-opacity-25 border border-gray-300 rounded"
          type="checkbox"
          onClick={onClick}
          onChange={() => {}}
          id={id}
        />
      </div>
      {children}
    </div>
  )
}

export default Index
