import { IconType } from 'react-icons'
import { MdAccountCircle, MdBusiness, MdOutlineEmail } from 'react-icons/md'
import ReactTooltip from 'react-tooltip'

interface Props {
  fullName: string
  email: string
  organization: string
}

function IconText({ Icon, text }: { Icon: IconType; text: string }) {
  return (
    <div className="flex items-center">
      <Icon className="mr-2" />
      <div
        className="py-1 text-gray-800 w-40 text-sm truncate cursor-default "
        data-tip={text}
      >
        {text}
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          backgroundColor="black"
        />
      </div>
    </div>
  )
}

function UserProfile({ fullName, email, organization }: Props) {
  return (
    <div className="flex-none">
      <IconText Icon={MdBusiness} text={organization} />
      <IconText Icon={MdAccountCircle} text={fullName} />
      <IconText Icon={MdOutlineEmail} text={email} />
    </div>
  )
}

export default UserProfile
