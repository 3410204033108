import { useState } from 'react'
import Auth from '@aws-amplify/auth'
import Plain2 from '@/components/Parts/Inputs/Plain2'
import Button from '@/components/Parts/Button/Blue'
import { useSpinner } from '@/hooks/spinner'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import useTranslation from '@/hooks/i18n'
import { privatePaths } from '@/utils/path'

interface Props {
  user: any
  allFailed: () => void
}

export default function MFA({ user, allFailed }: Props) {
  const [verifyCode, setVerifyCode] = useState('')
  const { enableSpinner, disableSpinner } = useSpinner()
  const navigate = useNavigate()
  const location = useLocation()
  const search = location.search
  const query = queryString.parse(search)
  const [authError, setAuthError] = useState(false)
  const [userInfo, setUserInfo] = useState(user)
  const { t } = useTranslation()

  const verify = () => {
    setAuthError(false)
    enableSpinner()
    Auth.sendCustomChallengeAnswer(userInfo, verifyCode)
      .then((res) => {
        if (res.challengeName === 'CUSTOM_CHALLENGE') {
          setUserInfo(res)
          setAuthError(true)
        } else {
          navigate(
            query.redirect === undefined
              ? privatePaths.home
              : (query.redirect as string)
          )
        }
        disableSpinner()
      })
      .catch(() => {
        setAuthError(true)
        disableSpinner()
        allFailed()
      })
  }

  return (
    <div>
      <h1 className="text-gray-700 font-bold mb-4 text-2xl">Enter 123456</h1>
      <div className="mb-8">
        <Plain2
          title="Auth Code"
          maxLength={6}
          value={verifyCode}
          autoFocus={true}
          change={(value) => setVerifyCode(value)}
          keydown={verify}
        />
      </div>
      <p className={`${!authError && 'invisible'} text-red-400 my-2`}>
        {t('Authentication failed')}
      </p>

      <Button name="Verify auth code" onClick={verify} />
    </div>
  )
}
