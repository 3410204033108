import { CardForService } from '@/types'
import Button from '@/components/Parts/Button/Blue'
import { useNavigate } from 'react-router-dom'
import LabelText from './LabelText'
import { privatePaths } from '@/utils/path'

type Props = {
  serviceKey: string
  cardForService: CardForService
}

export default function Cards({ serviceKey, cardForService }: Props) {
  const navigate = useNavigate()

  const click = () => {
    navigate({
      pathname: privatePaths.serviceContractCardService,
      search: `?id=${encodeURIComponent(serviceKey)}`,
    })
  }

  return (
    <div className="sm:flex sm:items-center text-sm">
      <div className="mb-2">
        <LabelText title="tr_name">
          <p id="qcp-service-contract-p-name">{cardForService.name}</p>
        </LabelText>
        <LabelText title="tr_brand">
          <p id="qcp-service-contract-p-brand">{cardForService.brand}</p>
        </LabelText>
        <LabelText title="tr_lastNumber">
          <p id="qcp-service-contract-p-last-number">
            ****{cardForService.lastNumber}
          </p>
        </LabelText>
        <LabelText title="tr_expire">
          <p id="qcp-service-contract-p-expire">
            {cardForService.expMonth}/{cardForService.expYear}
          </p>
        </LabelText>
      </div>
      <div className="sm:ml-auto">
        <Button
          name="tr_changeCredCard"
          onClick={click}
          id="qcp-service-contract-button-change-card"
        />
      </div>
    </div>
  )
}
