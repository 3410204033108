import { ReactNode } from 'react'
import { useHeight } from '@/hooks/height'

import Overlay from '../Overlay'

type Props = {
  children: ReactNode
  close: () => void
}

const Modal = ({ children, close }: Props) => {
  const { height } = useHeight()

  return (
    <Overlay click={close}>
      <div
        className="h-auto p-0 m-0 text-left bg-white rounded shadow-xl z-50 overflow-auto"
        style={{ maxHeight: height }}
      >
        <div className="m-0">{children}</div>
      </div>
    </Overlay>
  )
}

export default Modal
