import useTranslation from '@/hooks/i18n'
import Button from '@/components/Parts/Button/Blue'
import { useMutation } from '@apollo/client'
import { SELECT_CARD_FOR_SERVICE } from '@/api/graphql/mutations'
import {
  QUERY_SERVICE_LIST,
  AVAILABLE_CARDS,
  INVOICE_HISTORY,
} from '@/api/graphql/queries'
import { SelectCardForService } from '@/types'
import { useSpinner } from '@/hooks/spinner'
import { useModal } from '@/hooks/modal'

type Props = {
  cardKey: string
  serviceKey: string
  refetch: () => Promise<void>
}

export default function ConfirmChange({ cardKey, serviceKey, refetch }: Props) {
  const [selectCardForService] = useMutation<{
    selectCardForService: SelectCardForService
  }>(SELECT_CARD_FOR_SERVICE, {
    refetchQueries: [
      { query: QUERY_SERVICE_LIST },
      { query: AVAILABLE_CARDS },
      { query: INVOICE_HISTORY, variables: { offset: 100, limit: 100 } },
    ],
    awaitRefetchQueries: true,
  })
  const { enableSpinner, disableSpinner } = useSpinner()
  const { t } = useTranslation()
  const { closeModal } = useModal()

  const select = () => {
    enableSpinner()
    selectCardForService({
      variables: {
        cardKey,
        serviceKey,
      },
    })
      .then(() => {
        refetch()
          .then(() => {
            disableSpinner()
            closeModal()
          })
          .catch(() => {
            // Go to wrong
            disableSpinner()
            closeModal()
          })
      })
      .catch((error) => {
        console.log(error)
        disableSpinner()
      })
  }

  return (
    <div className="p-4 max-w-md">
      <h1 className="text-xl mb-2">{t('tr_selectingCreditCardTitle')}</h1>
      <p className="text-gray-700 mb-8">{t('tr_selectingCreditCard')}</p>
      <Button name="tr_yes" onClick={select} />
    </div>
  )
}
