import axios from 'axios'
import { ApiError } from '@/types'
import fatalError from './fatalError'
import unkownError from './unkownError'
import networkError from './networkError'
import kownError from './kownError'
import * as Sentry from '@sentry/react'

function _errorFactory(path: string, params: any, err: any): () => ApiError {
  if (axios.isAxiosError(err)) {
    if (err.response) {
      if (err.response.status === 422) {
        // No retry
        // Kown Error:
        return () => kownError(err)
      } else if (err.response.status === 423) {
        window.alert('Hello')
        return () => networkError()
      } else {
        // No retry: Fatal error
        // Send a message to sentry. Could be bug
        return () => unkownError(path, params, err)
      }
    } else {
      // Retry later: Could be Network Nerror.
      return () => networkError()
    }
  }

  // No retry: Unkown error
  // Send a message to sentry
  return () => fatalError(path, params, err)
}

function errorHandler(path: string, params: any, err: any) {
  const handler = _errorFactory(path, params, err)
  const result = handler()

  if (result.trace !== undefined) {
    Sentry.captureMessage(result.trace)
  }

  return result
}

export default errorHandler
