import { ReactNode } from 'react'

import Footer from '@/components/Parts/Footer'
import Modal from '@/components/Parts/Modal/CommonPageFrame'
import PageTitle from '@/components/Parts/PageTitle'
import QualitiaIcon from '@/components/Parts/QualitiaIcon'
import { useVersion } from '@/hooks/version'

import Spinner from '../Parts/Spinner'
import Reload from '@/components/Parts/Modal/Reload'

interface Props {
  title: string
  children: ReactNode
}

function PageFrame({ title, children }: Props) {
  const isChanged = useVersion()

  return (
    <>
      <PageTitle title={title}>
        <div className="relative flex flex-col min-h-screen">
          <main className="flex-grow justify-center mx-auto mb-8">
            {children}
          </main>

          <div className="w-full bottom-32 md:bottom-48">
            <QualitiaIcon />
          </div>

          <Footer />
        </div>
        <Modal />
        <Spinner />
      </PageTitle>
      {isChanged && <Reload />}
    </>
  )
}

export default PageFrame
