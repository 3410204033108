import Input from '@/components/Parts/Inputs/Plain2'
import { useState } from 'react'
import useTranslation from '@/hooks/i18n'
import { MdHighlightOff } from 'react-icons/md'

type Props = {
  couponCode: string
  error: boolean
  loading: boolean
  apply: (coupon: string) => Promise<void>
}

export default function Coupon({ couponCode, error, apply, loading }: Props) {
  const [coupon, setCoupon] = useState(couponCode)
  const [enableCoupon, setEnableCoupon] = useState(false)
  const { t } = useTranslation()
  const isDisable = coupon.length === 0 || loading

  return (
    <div>
      <p className="text-gray-900 font-bold mb-2">{t('tr_couponCode')}</p>
      {enableCoupon && (
        <div className="flex items-center py-1 ">
          <p
            className={`mr-4 ${
              isDisable ? 'text-gray-400' : 'text-gray-900'
            } text-lg`}
          >
            {t(coupon)}
          </p>
          <MdHighlightOff
            className="text-xl cursor-pointer"
            onClick={() => {
              if (loading) return
              apply('')
                .then(() => {
                  setCoupon('')
                  setEnableCoupon(false)
                })
                .catch(() => {})
            }}
          />
        </div>
      )}
      {!enableCoupon && (
        <>
          <div className="flex items-center">
            <Input
              // title="Coupon code"
              disabled={loading}
              value={coupon}
              change={(value) => {
                setCoupon(value)
              }}
              maxLength={10}
            />
            <div className="max-w-xs ml-4">
              <button
                className={`rounded px-4 py-1 ${
                  isDisable ? 'bg-gray-400' : 'bg-blue-500'
                } text-sm text-white font-bold`}
                disabled={isDisable}
                onClick={() =>
                  apply(coupon)
                    .then(() => setEnableCoupon(true))
                    .catch(() => {})
                }
              >
                {t('tr_apply')}
              </button>
            </div>
          </div>
          <p className={`${error ? 'visible' : 'invisible'} text-red-500 mb-2`}>
            {t('tr_invalidCouponCode')}
          </p>
        </>
      )}
    </div>
  )
}
