import Amplify from 'aws-amplify'

// console.log(process.env.REACT_APP_AWS_COGNIT_REGION)
// console.log(process.env.REACT_APP_AWS_USER_POOLS_ID)
// console.log(process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID)

Amplify.configure({
  aws_cognito_region: process.env.REACT_APP_AWS_COGNIT_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  authenticationFlowType: 'CUSTOM_AUTH',
})

export * from './token'
