import { AxiosError } from 'axios'
import { RestAPIError } from '@/types'
import { ApiError } from '@/types'

function unkownError(path: string, params: any, err: AxiosError<RestAPIError>) {
  const trace = `Path: ${path}, params or query: ${JSON.stringify(
    params
  )}, unkown status: ${err.response!.status}, err: ${JSON.stringify(err)}`

  return {
    type: 'Unkown',
    retry: false,
    status: err.response!.status,
    title: 'Unkown error has been occured',
    message: 'Sorry for the inconvinient. We are fixing this problem.',
    trace,
  } as ApiError
}

export default unkownError
