import QualitiaCloud from './QualitiaCloud/NextContract'
import { getServiceType, serviceTypeTable } from './helper'
import { NextContractDetails } from '@/types'
import useTranslation from '@/hooks/i18n'

type Props = {
  serviceKey: string
  nextContract?: NextContractDetails
  reload: () => Promise<void>
}

export default function CurrentContractDetails(props: Props) {
  const { serviceKey, nextContract, reload } = props
  const serviceType = getServiceType(serviceKey)

  const { t } = useTranslation()

  if (nextContract == null) {
    return <p className="text-sm text-gray-600">{t('tr_noNextContract')}</p>
  }

  if (serviceType.startsWith(serviceTypeTable.qualitiaCloud)) {
    return (
      <QualitiaCloud
        serviceKey={serviceKey}
        nextContract={nextContract}
        reload={reload}
      />
    )
  }

  return <div>CurrentContractDetails</div>
}
