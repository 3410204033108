import { ResultCheckSession } from '@/types'
import axios from './axios'
import errorHandler from './error'

function checkSession(sessionKey: string): Promise<ResultCheckSession> {
  return new Promise<ResultCheckSession>((resolve, reject) => {
    const path = 'check-session'

    axios
      .post<ResultCheckSession>(path, {
        sessionKey,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(errorHandler(path, sessionKey, err))
      })
  })
}

export default checkSession
