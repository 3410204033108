import useTranslation from '@/hooks/i18n'
import { useLocation, useNavigate } from 'react-router-dom'

function StyleLink({
  path,
  name,
  indent,
  py,
  id,
}: {
  path: string
  name: string
  indent: string
  py: string
  id?: string
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <li
      className={`${indent} ${py} hover:bg-gray-200 rounded-2xl cursor-pointer ${
        location.pathname.startsWith(path) &&
        'bg-gray-100 text-indigo-600 font-bold'
      }`}
      onClick={() => navigate(path)}
      id={id}
    >
      {t(name)}
    </li>
  )
}

export default StyleLink
