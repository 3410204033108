import PlainInput from '@/components/Parts/Inputs/Plain2'
import { countryIsoList } from '@/utils/countries'
import DropDownScroll from '@/components/Parts/DropDown/Scroll'
import PhoneNumber from '@/components/Parts/Inputs/PhoneNumber'
import 'react-phone-number-input/style.css'
import { Txt } from '@/types'
import * as validate from '@/utils/validate'

interface Props {
  org: Txt
  country: Txt
  zip: Txt
  addr1: Txt
  addr2: Txt
  addr3: Txt
  phone: Txt
  currency: Txt
}

function Organization({
  org,
  country,
  zip,
  addr1,
  addr2,
  addr3,
  phone,
  currency,
}: Props) {
  return (
    <div className="">
      <div className="mb-8">
        <PlainInput
          title="tr_organization"
          maxLength={255}
          value={org.text}
          autoFocus={true}
          validate={validate.organization}
          change={org.onChange}
          keydown={org.keyDown}
          id="qcp-register-input-organization"
        />
      </div>

      <div className="mb-8">
        <DropDownScroll
          title="tr_countryReq"
          selected={country.text}
          onSelect={(select) => {
            country.onChange(select)
          }}
          list={Object.keys(countryIsoList).map((key) => key)}
          id="qcp-register-select-country"
        />
      </div>
      <div className="mb-8">
        <DropDownScroll
          title="tr_paymentCurrency"
          selected={currency.text.toUpperCase()}
          onSelect={(select) => {
            currency.onChange(select.toLowerCase())
          }}
          list={['USD', 'SGD']}
          id="qcp-register-select-currency"
        />
      </div>

      <div className="mb-8">
        <PlainInput
          title="tr_zipReq"
          maxLength={255}
          value={zip.text}
          validate={validate.zip}
          change={zip.onChange}
          keydown={zip.keyDown}
          id="qcp-register-input-zip"
        />
      </div>
      <div className="mb-8">
        <PlainInput
          title="tr_address1"
          maxLength={255}
          value={addr1.text}
          validate={validate.address1}
          change={addr1.onChange}
          keydown={addr1.keyDown}
          id="qcp-register-input-address1"
        />
      </div>
      <div className="mb-8">
        <PlainInput
          title="tr_address2"
          maxLength={255}
          value={addr2.text}
          change={addr2.onChange}
          keydown={addr2.keyDown}
          id="qcp-register-input-address2"
        />
      </div>
      <div className="mb-8">
        <PlainInput
          title="tr_address3"
          maxLength={255}
          value={addr3.text}
          change={addr3.onChange}
          keydown={addr3.keyDown}
          id="qcp-register-input-address3"
        />
      </div>
      <div className="mb-8">
        <PhoneNumber
          value={phone.text}
          onChange={phone.onChange}
          keyDown={phone.keyDown}
          id="qcp-register-phone-number"
        />
      </div>
    </div>
  )
}

export default Organization
