import { useEffect, useState } from 'react'
import { RegisterForm } from '@/types'
import * as validate from '@/utils/validate'

function _create(
  key: string,
  form: RegisterForm,
  setForm: (param: RegisterForm) => void
) {
  return {
    text: form[key],
    onChange: (text: string) => {
      form[key] = text
      setForm({ ...form })
    },
  }
}

function useForm() {
  const [disabled, setDisabled] = useState(false)
  const [form, setForm] = useState<RegisterForm>({
    org: '',
    country: '',
    zip: '',
    addr1: '',
    addr2: '',
    addr3: '',
    fullName: '',
    department: '',
    title: '',
    phone: '',
    password: '',
    memo: '',
    currency: '',
  } as RegisterForm)

  useEffect(() => {
    setDisabled(
      validate.organization(form.org).length > 0 ||
        form.country.length === 0 ||
        validate.zip(form.zip).length > 0 ||
        validate.address1(form.addr1).length > 0 ||
        validate.fullName(form.fullName).length > 0 ||
        validate.department(form.department).length > 0 ||
        form.phone === undefined ||
        validate.currency(form.currency) ||
        validate.password(form.password).length > 0 ||
        validate.currency(form.currency)
    )
  }, [setDisabled, form])

  const org = _create('org', form, setForm)
  const country = _create('country', form, setForm)
  const zip = _create('zip', form, setForm)
  const addr1 = _create('addr1', form, setForm)
  const addr2 = _create('addr2', form, setForm)
  const addr3 = _create('addr3', form, setForm)
  const fullName = _create('fullName', form, setForm)
  const department = _create('department', form, setForm)
  const title = _create('title', form, setForm)
  const phone = _create('phone', form, setForm)
  const password = _create('password', form, setForm)
  const memo = _create('memo', form, setForm)
  const currency = _create('currency', form, setForm)

  return {
    disabled,
    form: {
      org,
      country,
      zip,
      addr1,
      addr2,
      addr3,
      fullName,
      department,
      title,
      phone,
      password,
      memo,
      currency,
    },
  } as const
}

export default useForm
