import React, { createContext } from 'react';

import * as Auth from './auth';
import * as ErrorMsg from './errorMsg';
import * as LeftBar from './leftBar';
import * as Modal from './modal';
import * as Progress from './progress';
import * as Spinner from './spinner';
import * as Tooltip from './tooltip';

export type State = {
  modal: Modal.State
  progress: Progress.State
  error: ErrorMsg.State
  leftBar: LeftBar.State
  spinner: Spinner.State
  tooltip: Tooltip.State
  auth: Auth.State
}

export type Action =
  | Modal.Action
  | Progress.Action
  | ErrorMsg.Action
  | LeftBar.Action
  | Spinner.Action
  | Tooltip.Action
  | Auth.Action

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'signin':
    case 'signout': {
      const { auth, ...rest } = state
      return {
        auth: Auth.reducer(action),
        ...rest,
      }
    }
    case 'setTooltip':
    case 'clearTooltip': {
      const { tooltip, ...rest } = state
      return {
        tooltip: Tooltip.reducer(action),
        ...rest,
      }
    }
    case 'openModal':
    case 'closeModal': {
      const { modal, ...rest } = state
      return {
        modal: Modal.reducer(action),
        ...rest,
      }
    }
    case 'openLeftBar':
    case 'closeLeftBar': {
      const { leftBar, ...rest } = state
      return {
        leftBar: LeftBar.reducer(action),
        ...rest,
      }
    }
    case 'start':
    case 'stop': {
      const { progress, ...rest } = state
      return {
        progress: Progress.reducer(action),
        ...rest,
      }
    }
    case 'enableSpinner':
    case 'disableSpinner': {
      const { spinner, ...rest } = state
      return {
        spinner: Spinner.reducer(action),
        ...rest,
      }
    }
    case 'addErr':
    case 'removeErr': {
      const { error, ...rest } = state
      return {
        error: ErrorMsg.reducer(error, action),
        ...rest,
      }
    }
    default:
      return state
  }
}

export const AppContext = createContext(
  {} as {
    state: State
    dispatch: React.Dispatch<Action>
  }
)

export const initialState = {
  modal: Modal.initialState,
  progress: Progress.initialState,
  error: ErrorMsg.initialState,
  leftBar: LeftBar.initialState,
  spinner: Spinner.initialState,
  tooltip: Tooltip.initialState,
  auth: Auth.initialState,
}
