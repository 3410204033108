import withPageFrame from '@/components/PrivatePages'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Loader from '@/components/Parts/Loader'
import { ServiceConfiguration } from '@/types'
import { SERVICE_CONFIGURATION } from '@/api/graphql/queries'
import { useTranslation } from 'react-i18next'

function Page() {
  const [searchParams] = useSearchParams()
  const query = searchParams.get('service')
  const serviceKey = decodeURIComponent(query == null ? '' : query)
  const { loading, data, error } = useQuery<{
    serviceConfiguration: ServiceConfiguration
  }>(SERVICE_CONFIGURATION, {
    variables: {
      serviceKey,
      lang: 'en',
    },
    fetchPolicy: 'no-cache',
  })
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (loading) {
    return <Loader />
  }

  if (error) {
    if (error.graphQLErrors) {
      const res = error.graphQLErrors[0]
      if (
        res != null &&
        res.extensions != null &&
        res.extensions.code === 'NoServiceConfiguration'
      ) {
        return <>{t('tr_noConfiguration')}</>
      }
    }

    navigate('/something-went-wrong')
    return <></>
  }

  const html = { __html: data!.serviceConfiguration.html }

  // return <img src={'https://portal.sg.order.cdev.jp/logo192.png'} alt="hello" />
  return <div dangerouslySetInnerHTML={html} />
}

export default withPageFrame('Service configuration', Page)
