import { useNavigate } from 'react-router-dom'
import { FaHandshake } from 'react-icons/fa'
import { RiSettings3Fill } from 'react-icons/ri'
import ReactTooltip from 'react-tooltip'
import { privatePaths } from '@/utils/path'
import { Service } from '@/types'
import useTranslation from '@/hooks/i18n'

type Props = {
  service: Service
}

export default function Footer({ service }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (service.status === 'creating') {
    return <></>
  }

  return (
    <>
      <div className="ml-auto">
        <div className="flex items-center ">
          <div className="mr-8">
            <div className="" data-tip={t('tr_contractDetails')}>
              <FaHandshake
                className="text-gray-500 text-3xl cursor-pointer"
                onClick={() => {
                  navigate({
                    pathname: privatePaths.serviceContract,
                    search: `?service=${encodeURIComponent(
                      service.serviceKey
                    )}`,
                  })
                }}
                id="qcp-qtc-contract-icon"
              />
              <ReactTooltip
                effect="float"
                type="info"
                place="right"
                backgroundColor="black"
              />
            </div>
          </div>

          <div className="" data-tip={t('tr_serviceConfiguration')}>
            <RiSettings3Fill
              className="text-gray-500 text-3xl cursor-pointer"
              onClick={() => {
                navigate({
                  pathname: privatePaths.serviceServiceConfiguration,
                  search: `?service=${encodeURIComponent(service.serviceKey)}`,
                })
              }}
              id="qcp-qtc-config-icon"
            />
            <ReactTooltip
              effect="float"
              type="info"
              place="right"
              backgroundColor="black"
            />
          </div>
        </div>
      </div>
    </>
  )
}
