import { useContext } from 'react'

import { AppContext } from '@/contexts'

import { useErr } from './error'

let _loaderTimeoutId: NodeJS.Timeout | null = null

/**
 * Manage the spinner global state
 * All component can use the state to show spinner.
 * Layout or upper layer must show Spinner when showSpinner is enabled.
 * After showSpinner is enabled, and past 4 minutes, showSpinner will be disabled
 * and setError UnkownAndRetry.
 *
 * @returns showSpinner flag
 * @returns enableSpinner enables showSpinner
 * @returns disableSpinner disable showSpinner
 */
export function useSpinner() {
  const { state, dispatch } = useContext(AppContext)
  const { setErr } = useErr()

  const enableSpinner = () => {
    dispatch({ type: 'enableSpinner' })
    _loaderTimeoutId = setTimeout(() => {
      dispatch({ type: 'disableSpinner' })
      setErr('UnkownAndRetry')
    }, 200000)
  }

  const disableSpinner = () => {
    if (_loaderTimeoutId != null) {
      clearTimeout(_loaderTimeoutId)
      _loaderTimeoutId = null
    }
    dispatch({ type: 'disableSpinner' })
  }

  if (state === undefined || state.spinner === undefined) {
    return { showSpinner: false, enableSpinner, disableSpinner }
  }

  return {
    showSpinner: state.spinner.showSpinner,
    enableSpinner,
    disableSpinner,
  } as const
}
