import { MdCheckCircle } from 'react-icons/md'
import useTranslation from '@/hooks/i18n'
import Button from '@/components/Parts/Button/Blue'
import QualitiaIcon from '@/components/Parts/QualitiaIcon'
import { useNavigate } from 'react-router-dom'

type Props = {
  title: string
  message1: string
  message2: string
  goBackWhere: string
  goBackTo: string
}

export default function Success({
  title,
  message1,
  message2,
  goBackWhere,
  goBackTo,
}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="w-full">
      <div className="sm:w-120 mx-auto py-24">
        <MdCheckCircle className="text-green-400 text-8xl mx-auto mb-8" />
        <h1 className="text-xl text-gray-600 text-center font-bold">
          {t(title)}
        </h1>
        <p className="text-gray-500 text-center mt-12">{t(message1)}</p>
        <p className="text-gray-500 text-center">{t(message2)}</p>
        <div className="w-48 mx-auto mt-24">
          <Button
            name={goBackWhere}
            onClick={() => navigate(goBackTo)}
            id="qcp-purchase-button-success"
          />
        </div>
        <div className="mt-48">
          <QualitiaIcon />
        </div>
      </div>
    </div>
  )
}
