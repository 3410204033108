import OutFrame from '@/components/PublicPages/OutFrame'
import { privatePaths } from '@/utils/path'

function NotFound() {
  return (
    <OutFrame
      title="tr_pageNotFound"
      subTitle="tr_requestPageNotFound"
      titleColor="text-blue-500"
      buttonName="tr_goBackHome"
      to={privatePaths.home}
    />
  )
}

export default NotFound
