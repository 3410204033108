import { HTMLProps, ChangeEvent, useState, useEffect, useRef } from 'react'
import useTranslation, { useNumberFormatter } from '@/hooks/i18n'
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa'

type Props = {
  title?: string
  verify?: (value: number) => boolean
  change?: (value: number) => void
  load?: () => void
  min?: number
  max?: number
  inputMin?: number
} & HTMLProps<HTMLInputElement>

export default function InputNumber(props: Props) {
  const { title, verify, className, change, min, max, value, ...rest } = props
  const [disableError, setDisableError] = useState(true)
  const { t } = useTranslation()
  const n = useNumberFormatter('default')
  const [currentValue, setCurrentValue] = useState(Number(value))
  const first = useRef(true)

  useEffect(() => {
    if (first.current) {
      first.current = false
      return
    }

    props.load && props.load()
    const timer = setTimeout(() => {
      if (change) {
        change(currentValue)
        if (min && verify) {
          setDisableError(currentValue === min || verify(Number(currentValue)))
        }
      }
    }, 300)

    return () => {
      clearTimeout(timer)
    }
  }, [currentValue])

  const wrapperChange = (inputNum: number) => {
    setCurrentValue(inputNum)

    if (min && verify) {
      setDisableError(inputNum === min || verify(Number(inputNum)))
    }
  }

  const onchange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const maxUserNum = Number(event.target.value)

    if (event.target.validity.valid) {
      wrapperChange(maxUserNum)
    }
  }

  const inputMin = props.inputMin === undefined ? min : props.inputMin

  return (
    <div className="">
      {title && <p className="text-gray-800 mb-4 font-bold">{t(title)}</p>}

      <div className="flex items-center text-xl">
        <FaMinusCircle
          className={`${
            Number(props.min) < Number(value)
              ? 'text-green-400 hover:text-green-500 cursor-pointer'
              : 'text-gray-400'
          } text-2xl  `}
          onClick={() =>
            min &&
            Number(value) > min &&
            wrapperChange(Number(currentValue) - 1)
          }
          id="qcp-input-number-icon-minus"
        />

        <input
          className={`mx-2 text-center outline-none w-24 ${className}`}
          pattern="[0-9]{1,}"
          onChange={onchange}
          value={currentValue}
          {...rest}
        />
        <FaPlusCircle
          className="text-green-400 text-2xl cursor-pointer hover:text-green-500"
          onClick={() => {
            wrapperChange(Number(currentValue) + 1)
          }}
          id="qcp-input-number-icon-plus"
        />
      </div>

      {verify && (
        <p
          className={`text-red-500 text-sm mt-1 ${
            disableError === true ? 'invisible' : 'visible'
          }`}
        >
          {t('tr_errorInputNumber {{min}} {{max}}', {
            min: inputMin,
            max: n('default', max!),
          })}
        </p>
      )}
    </div>
  )
}
