import axios from './axios'
import errorHandler from './error'

function createUser(email: string, service?: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const path = 'create'
    axios
      .post(path, { email, service })
      .then(() => resolve())
      .catch((err) => reject(errorHandler(path, { email }, err)))
  })
}

export default createUser
