import { ChangeEvent, useState } from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'

import useTranslation from '@/hooks/i18n'

interface Props {
  password: string
  title?: string
  autoFocus?: boolean
  validate?: (password: string) => string
  onChange: (password: string) => void
  keyDown: (password: string) => void
  autoComplete?: string
  id?: string
}

function Password({
  password,
  title,
  onChange,
  keyDown,
  validate,
  id,
  autoFocus = false,
}: Props) {
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [toggle, setToggle] = useState(false)

  const change = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const password = event.target.value

    if (validate) {
      setError(validate(password))
    }
    onChange(password)
  }

  const keydown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      keyDown != null && keyDown(event.target.value)
    }
  }

  return (
    <div className="flex items-center w-full">
      <div className="w-full">
        <p className="qcp-input-title">
          {title === undefined ? t('tr_password') : t(title)}
        </p>
        <div className="relative">
          <input
            type={toggle ? 'text' : 'password'}
            // name="password"
            value={password}
            autoFocus={autoFocus}
            minLength={0}
            maxLength={90}
            onChange={change}
            onKeyDown={keydown}
            className="qcp-input"
            //http://mantis.cdev.jp/view.php?id=3
            //方法を調べたのですが、autoCompleteを検知することができません。
            //refで値をチェックしても値が入っておらず、どうしてよいのかよくわかりません。
            //別途調査します。
            autoComplete="new-password"
            id={id}
          ></input>
          <div className="absolute top-0 right-0 h-full px-2 py-1">
            {toggle && (
              <FaRegEye
                className="text-xl text-gray-700 cursor-pointer"
                onClick={() => setToggle(!toggle)}
              />
            )}
            {!toggle && (
              <FaRegEyeSlash
                className="text-xl text-gray-400 cursor-pointer"
                onClick={() => {
                  setToggle(!toggle)
                }}
              />
            )}
          </div>
        </div>
        <p className="text-red-500 text-sm">{t(error)}</p>
      </div>
    </div>
  )
}

export default Password
