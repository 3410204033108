import SuccessFormat from '@/components/Result/Success'
import { privatePaths } from '@/utils/path'

export default function Success() {
  return (
    <SuccessFormat
      title="tr_transactionCompletedSuccessfully"
      message1="tr_transactionCompletedThankYou"
      message2="tr_transactionCompletedDetails"
      goBackWhere="tr_goBackHome"
      goBackTo={privatePaths.home}
    />
  )
}
