import { HTMLProps } from 'react'

import useTranslation from '@/hooks/i18n'

type Props = {
  name: string
  textColor: string
  bgColor: string
  bgColorHover: string
  onClick?: () => void
} & HTMLProps<HTMLButtonElement>

function Plain(props: Props) {
  const { t } = useTranslation()

  return (
    <button
      disabled={props.disabled}
      className={`${props.textColor} rounded px-4 py-2 font-bold w-full ${
        props.disabled
          ? 'bg-gray-300 cursor-default'
          : `${props.bgColor} hover:${props.bgColorHover}`
      }`}
      onClick={props.onClick != null ? props.onClick : () => {}}
      id={props.id}
    >
      {t(props.name)}
    </button>
  )
}

export default Plain
