import useTranslation from '@/hooks/i18n';

interface Props {
  text: string
  to: string
  label: string
}

function Anchor({ text, to, label }: Props) {
  const { t } = useTranslation()

  return (
    <div className="flex items-center mt-4 ml-4 md:ml-8">
      <p className="text-gray-700 text-sm">{t(text)}</p>
      <a href={to} className="ml-1 text-blue-500 font-bold hover:text-blue-600">
        {t(label)}
      </a>
    </div>
  )
}

export default Anchor
