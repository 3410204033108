import { ReactNode, useReducer } from 'react';

import { AppContext, initialState, reducer } from './reducer';

type Props = {
  children: ReactNode
}

export const Context = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}
