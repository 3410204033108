import { ReactNode } from 'react';

type Props = {
  children: ReactNode
  click?: () => void
  backgroundColor?: string
}

const Overlay = ({ children, click, backgroundColor }: Props) => {
  return (
    <div
      className="fixed top-0 left-0 flex items-center justify-center w-full h-full"
      style={{
        backgroundColor:
          backgroundColor === undefined ? 'rgba(0,0,0,.5)' : backgroundColor,
        zIndex: 99999,
      }}
      onClick={() => {
        if (click != null) click()
      }}
      x-show="open"
    >
      {children}
    </div>
  )
}

export default Overlay
