export function address1(text: string) {
  return text.length === 0 ? 'Address 1 is required' : ''
}

export function fullName(text: string) {
  return text.length === 0 ? 'Full-name is required' : ''
}

export function organization(text: string) {
  return text.length === 0 ? 'Organization is required' : ''
}

export function department(text: string) {
  return text.length === 0 ? 'Department is required' : ''
}

export function phone(text: string) {
  if (text.length === 0) return 'Phone number is required'

  if (/[^0-9\-.+\s]/.test(text)) {
    return 'Valid characters are number, +, -, . and space.'
  }

  if (!/[0-9]{1,}/.test(text)) {
    return 'Numbers are required'
  }

  return ''
}

export function checkTenantCode(text: string) {
  if (text.length !== 8 || !/[0-9a-z]{8}/.test(text)) {
    return 'Tenant code must 8 lower case alphabet characters or numbers'
  }

  return ''
}

export function checkTenantName(text: string) {
  if (text.length <= 0 || text.length > 2048) {
    return 'Tenant name must be greater than 0 and equal or less than 2048 charactors'
  }

  return ''
}

export function checkMaxUser(text: string) {
  const maxUser = Number(text)

  if (isNaN(maxUser) || maxUser <= 0 || maxUser > 1000000) {
    return 'The number of max user must greater than 0 and equal or less than 1000,000'
  }

  return ''
}

export function zip(text: string) {
  if (text.length === 0) return 'Zip is required'

  if (/[^a-zA-Z0-9\-\s]{1,}/.test(text)) {
    return 'Valid characters are number, alphabet, - and space.'
  }

  if (!/[0-9]{1,}/.test(text)) {
    return 'Numbers are required'
  }
  return ''
}

export function password(text: string) {
  if (text.length < 8) {
    return 'At least 8 characters is required'
  }

  if (!/[a-z]{1,}/.test(text)) {
    return 'At least one lower case is required'
  }

  if (!/[A-Z]{1,}/.test(text)) {
    return 'At least one upper case is required'
  }

  if (!/[0-9]{1,}/.test(text)) {
    return 'Numbers are required'
  }

  if (!/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]{1,}/.test(text)) {
    return 'At least one special character is required. ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `'
  }

  return ''
}

export function currency(text: string) {
  const c = text.toLowerCase()
  return c !== 'usd' && c !== 'sgd'
}
