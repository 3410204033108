import { QUERY_SERVICE_LIST } from '@/api/graphql/queries'
import { Service } from '@/types'
import { useNavigate } from 'react-router-dom'
import withPageFrame from '@/components/PrivatePages'
import Loader from '@/components/Parts/Loader'
import { publicPaths } from '@/utils/path'
import ProductList from '@/components/Services/Products'
import CardService from '@/components/Services/CardService'
import { useCallback, useEffect, useState } from 'react'
import { gqlClient } from '@/api/graphql'

function Page() {
  const navigate = useNavigate()
  const [services, setServices] = useState<Service[] | undefined>(undefined)

  const fetch = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      gqlClient
        .query<{
          services: Service[]
        }>({
          query: QUERY_SERVICE_LIST,
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          setServices([...res.data!.services])
          resolve()
        })
        .catch(() => {
          navigate(publicPaths.somethingWentWrong)
          reject()
        })
    })
  }, [navigate])

  useEffect(() => {
    fetch().catch(() => {})
  }, [fetch])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (services && services.some((service) => service.processing)) {
        fetch().catch(() => {})
      }
    }, 5 * 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [services, fetch])

  if (services === undefined) {
    return <Loader />
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8">
      {services.map((srv, index) => {
        return <CardService service={srv} key={index} reload={fetch} />
      })}
      {services.length === 0 && <ProductList reload={fetch} />}
    </div>
  )
}

export default withPageFrame('Service list', Page)
