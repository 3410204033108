import useTranslation from '@/hooks/i18n'
import Button from '@/components/Parts/Button/Red'
import { useMutation } from '@apollo/client'
import { Subscription } from '@/types'
import { SUBSCRIPTION } from '@/api/graphql/mutations'
import { useSpinner } from '@/hooks/spinner'
import { useModal } from '@/hooks/modal'
import errorHandler from '@/api/graphql/error'
import { useEffect, useState } from 'react'
import { GoAlert } from 'react-icons/go'
import { CancellingSubscription } from '@/types'
import {
  CANCELLING_SUBSCRIPTION,
  SERVICE_CONTRACT_DETAILS,
  AVAILABLE_CARDS,
  QUERY_SERVICE_LIST,
  INVOICE_HISTORY,
} from '@/api/graphql/queries'
import Loader from '@/components/Parts/Loader'
import { useNavigate } from 'react-router-dom'
import { publicPaths } from '@/utils/path'
import { gqlClient } from '@/api/graphql'
import { toDate } from '@/utils'

type Props = {
  serviceKey: string
  refech: () => Promise<void>
}

export default function Cancel({ serviceKey, refech }: Props) {
  const [subscription] = useMutation<{ subscription: Subscription }>(
    SUBSCRIPTION,
    {
      refetchQueries: [
        { query: QUERY_SERVICE_LIST },
        { query: AVAILABLE_CARDS },
        { query: SERVICE_CONTRACT_DETAILS, variables: { serviceKey } },
        { query: INVOICE_HISTORY, variables: { offset: 100, limit: 100 } },
      ],
      awaitRefetchQueries: true,
    }
  )
  const { t, d } = useTranslation()
  const { enableSpinner, disableSpinner } = useSpinner()
  const { closeModal } = useModal()
  const [errorForCancelling, setErrorForCancelling] = useState('')
  const [cancellingSubscription, setCancellingSubscription] = useState<
    CancellingSubscription | undefined
  >(undefined)
  const navigate = useNavigate()

  useEffect(() => {
    gqlClient
      .query<{
        cancellingSubscription: CancellingSubscription
      }>({ query: CANCELLING_SUBSCRIPTION, variables: { serviceKey } })
      .then((result) => {
        setCancellingSubscription(result.data!.cancellingSubscription)
      })
      .catch(() => {
        closeModal()
        navigate(publicPaths.somethingWentWrong)
      })
  }, [serviceKey, navigate, closeModal])

  const cancel = () => {
    enableSpinner()
    setErrorForCancelling('')
    subscription({ variables: { serviceKey, enable: false } })
      .then(() => {
        refech()
          .then(() => {
            disableSpinner()
            closeModal()
          })
          .catch(() => {
            disableSpinner()
            closeModal()
          })
      })
      .catch((error) => {
        console.log(error)
        const result = errorHandler(error)
        setErrorForCancelling(
          result.message === undefined ? 'Unkown error' : result.message
        )
        disableSpinner()
      })
  }

  if (cancellingSubscription === undefined) {
    return (
      <div className="px-4 py-8 md:w-104">
        <div className="flex items-center text-red-500 text-lg sm:text-2xl">
          <GoAlert />
          <div className="ml-2 mb-2">{t('tr_cancelAutoRenewal')}</div>
        </div>
        <Loader title={'tr_nowLoading'} />
      </div>
    )
  }

  return (
    <div className="px-4 py-8 md:w-104">
      <div className="flex items-center text-red-500 text-lg sm:text-2xl">
        <GoAlert />
        <div className="ml-2 mb-2">{t('tr_cancelAutoRenewalTitle')}</div>
      </div>
      <div className="flex items-center text-gray-700 mb-4">
        <p className="">{t('tr_lastAvailableDate')}</p>
        <div className="ml-2">
          {d('ymdhms', toDate(cancellingSubscription.endAt))}
        </div>
      </div>

      <p>{t('tr_cancelAutoRenewal')}</p>
      <p className="text-red-500 font-bold">{t(errorForCancelling)}</p>
      <div className="mt-8">
        <Button
          name="tr_yes"
          onClick={cancel}
          id="qcp-qtc-cancel-confirm-button-yes"
        />
      </div>
    </div>
  )
}
