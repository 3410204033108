import PageFrame from '@/components/PublicPages/PageFrame'

import CreateUser from './CreateUser'
import Describe from './Describe'

/**
 * User can register from this page.
 * The registered user will be registered as a super user.
 */
function Register() {
  return (
    <PageFrame title="Create an account">
      <div className="mt-12 flex">
        <div className="hidden lg:w-108 lg:block ">
          <Describe />
        </div>
        <div className="lg:w-108">
          <CreateUser />
        </div>
      </div>
    </PageFrame>
  )
}

export default Register
