import { ApiError } from '@/types'

function networkError() {
  return {
    type: 'Network',
    retry: true,
    status: 500,
    title: 'Network error',
    message: 'Sorry for the inconvinient. Please retry later.',
  } as ApiError
}

export default networkError
