export type State = {
  inProgress: boolean
}

export type Action = { type: 'start' } | { type: 'stop' }

export const reducer = (action: Action) => {
  switch (action.type) {
    case 'start':
      return {
        inProgress: true,
      }
    case 'stop': {
      return {
        inProgress: false,
      }
    }
  }
}

export const initialState = {
  inProgress: false,
}
