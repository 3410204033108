import withPageFrame from '@/components/PrivatePages'
import Summary from './Summary'
import Breakdown from '@/components/Invoice/Breakdown'
import Subtotal from './Subtotal'
import Activity from './EventAndLogs'
import { Invoice } from '@/types'
import { EventAndLogs } from '@/types'
import { useSearchParams } from 'react-router-dom'
import { TRANSACTION_DETAILS } from '@/api/graphql/queries'
import { useQuery } from '@apollo/client'
import Loader from '@/components/Parts/Loader'

function TransactionDetails() {
  const [searchParams] = useSearchParams()
  const invoiceId = searchParams.get('id')
  const { loading, data, error } = useQuery<{
    invoice: Invoice
    eventAndLogs: EventAndLogs[]
  }>(TRANSACTION_DETAILS, { variables: { invoiceId } })

  if (loading) {
    return <Loader />
  }

  if (error || data!.invoice.invoices == null) {
    throw Error('Invalid error')
  }

  return (
    <div>
      <Summary
        total={data!.invoice.total!}
        currency={data!.invoice.currency!}
        paymentAt={data!.invoice.issuedAt!}
        status={data!.invoice.status!}
        paymentId={data!.invoice.invoiceId!}
      />
      <Breakdown
        invoices={data!.invoice.invoices}
        currency={data!.invoice.currency!}
      />
      <Subtotal
        subtotal={data!.invoice.subtotal!}
        tax={data!.invoice.tax!}
        currency={data!.invoice.currency!}
      />
      <Activity events={data!.eventAndLogs} />
    </div>
  )
}

export default withPageFrame('Transaction details', TransactionDetails)
