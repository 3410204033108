import DescribeFrame from './DescribeFrame'
import Logo from '@/components/Svg/CustomerportalLogo'

function Describe() {
  return (
    <div className="pt-12 px-8 ">
      <div className="w-48 ml-8 mb-8">
        <Logo subColor={'customerportal_logo_svg__st1'} />
      </div>
      <DescribeFrame
        title="tr_freeRegistrationTitle"
        content="tr_freeRegistrationQP"
      />
      <DescribeFrame
        title="tr_creditCardPaymentTitle"
        content="tr_creditCardPayment"
      />
      <DescribeFrame title="tr_easyToUseTitle" content="tr_easyToUse" />
      <DescribeFrame
        title="tr_purchaseHistoryTitle"
        content="tr_purchaseHistory"
      />
    </div>
  )
}

export default Describe
