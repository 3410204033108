import { useState } from 'react'

import PageFrame from '@/components/PublicPages/PageFrame'
import MFA from '@/components/SignIn/MFA'

import SendVerification from './SendVerification'
import SetNewPassword from './SetNewPassword'
import Logo from '@/components/Svg/CustomerportalLogo'

function ForgotPassword() {
  const [sent, setSent] = useState('')
  const [user, setUser] = useState<any>(undefined)

  if (sent.length === 0 && user === undefined) {
    return <SendVerification sent={(email) => setSent(email)} />
  }

  if (sent.length > 0 && user === undefined) {
    return <SetNewPassword email={sent} signedIn={setUser} />
  }

  return (
    <div className="w-full md:w-96 h-96 md:border md:shadow-lg bg-white p-4 md:p-8">
      <MFA user={user} allFailed={() => setUser(undefined)} />
    </div>
  )
}

function Index() {
  return (
    <PageFrame title="tr_forgotPassword">
      <div>
        <div className="w-48 mx-auto mt-12 my-4">
          <Logo subColor={'customerportal_logo_svg__st1'} />
        </div>
        <div className="container mx-auto flex items-center ">
          <div className=" w-full">
            <div className="flex justify-center">
              <ForgotPassword />
            </div>
          </div>
        </div>
      </div>
    </PageFrame>
  )
}

export default Index
