import useTranslation from '@/hooks/i18n'

type Props = {
  status: string
  id?: string
}

type Status = {
  name: string
  className: string
}

const statusList: { [key: string]: Status } = {
  PaymentStart: {
    name: 'tr_started',
    className: 'bg-gray-100 text-gray-900 font-bold inline-block',
  },
  PaymentSuccess: {
    name: 'tr_success',
    className: 'bg-green-100 text-green-900 font-bold inline-block',
  },
  PaymentFailed: {
    name: 'tr_failed',
    className: 'bg-red-600 text-white font-bold inline-block',
  },
}

export default function StatusComponent({ status, id }: Props) {
  const { t } = useTranslation()

  return (
    <div className={`px-4 ${statusList[status].className}`} id={id}>
      {t(statusList[status].name)}
    </div>
  )
}
