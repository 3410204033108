import { CardStyle } from '@/types'

export function selectCardStyle(isSelected: boolean): CardStyle {
  if (isSelected) {
    return {
      border: 'py-2 px-4 border-2 border-yellow-500 shadow-md rounded-lg',
      title: 'font-bold pr-4 align-top',
      value: 'pb-2 break-all',
    } as CardStyle
  }

  return {
    border:
      'py-2 px-4 border border-gray-300 shadow-md rounded-lg cursor-pointer transform transition duration-200 hover:scale-110',
    title: 'text-gray-600 font-bold pr-4 align-top',
    value: 'pb-2 break-all',
  } as CardStyle
}
