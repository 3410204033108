import { HTMLProps } from 'react'

import BasciBtn from './Plain'

type Props = {
  name: string
  onClick?: () => void
} & HTMLProps<HTMLButtonElement>

function Blue(props: Props) {
  return (
    <BasciBtn
      {...props}
      textColor="text-white"
      bgColor="bg-red-500"
      bgColorHover="bg-red-700"
    />
  )
}

export default Blue
