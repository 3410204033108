import ButtonWhite from '@/components/Parts/Button/White'
import { useMutation } from '@apollo/client'
import { BUYING_SERVICE_BY_NEW_CARD } from '@/api/graphql/mutations'
import { BuyingServiceResult } from '@/types'
import { useSpinner } from '@/hooks/spinner'
import { stripeRedirect } from '@/utils/stripe'
import { useNavigate } from 'react-router-dom'
import { publicPaths } from '@/utils/path'
import { useRef } from 'react'
import ConfirmRedirect from '@/components/ConfirmRedirectToStripe'

type Props = {
  sessionId: string
}

export default function NewCard({ sessionId }: Props) {
  const [buyingServiceByNewCard] = useMutation<{
    buyingServiceByNewCard: BuyingServiceResult
  }>(BUYING_SERVICE_BY_NEW_CARD)
  const { enableSpinner, disableSpinner } = useSpinner()
  const navigate = useNavigate()
  const ref = useRef(false)

  const next = () => {
    if (ref.current) return
    ref.current = true

    enableSpinner()
    buyingServiceByNewCard({
      variables: { sessionId, cancelRedirectUrl: window.location.pathname },
    })
      .then(({ data }) => {
        stripeRedirect(data!.buyingServiceByNewCard.stripeSessionId!).catch(
          () => {
            navigate(publicPaths.somethingWentWrong)
          }
        )
      })
      .finally(() => {
        disableSpinner()
        ref.current = false
      })
  }

  return (
    <>
      <div className="md:flex md:items-center">
        <ConfirmRedirect message={'tr_redirectToStripe'} />
        <div className="mt-4 ml-auto">
          <ButtonWhite name="tr_redirect" onClick={next} />
        </div>
      </div>
    </>
  )
}
