import useTranslation from '@/hooks/i18n'
import { ReactNode } from 'react'

type Props = {
  title: string
  children: ReactNode
}

export default function Box({ title, children }: Props) {
  const { t } = useTranslation()

  return (
    <div className="mt-8">
      <div className="w-full border-b border-gray-200">
        <h1 className="font-bold text-lg pb-2 ">{t(title)}</h1>
      </div>
      <div className="py-4">{children}</div>
    </div>
  )
}
