import PhoneNumberInput from 'react-phone-number-input'
import useTranslation from '@/hooks/i18n'
import { countryIsoRevertedList } from '@/utils/countries'

interface Props {
  value: string
  autoFocus?: boolean
  onChange: (value: string) => void
  keyDown?: (value: string) => void
  id?: string
}

function Select({ value, autoFocus = false, onChange, keyDown, id }: Props) {
  const { t } = useTranslation()

  const keydown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      keyDown != null && keyDown(event.target.value)
    }
  }

  return (
    <div className="w-full">
      <p className={`qcp-input-title`}>{t('tr_phoneNumber')}</p>
      <PhoneNumberInput
        autoFocus={autoFocus}
        style={{}}
        defaultCountry="US"
        value={value}
        onChange={onChange}
        onKeyDown={keydown}
        labels={countryIsoRevertedList}
        addInternationalOption={false}
        id={id}
      />
    </div>
  )
}

export default Select
