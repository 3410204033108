import Overlay from '@/components/Parts/Overlay'
import { useModal } from '@/hooks/modal'
import { useHeight } from '@/hooks/height'

/**
 * Show a plain modal dialog box. This modal for PageFrame.
 * Basically, PageFrame is used for non-signedin user (not-found-page is exceptional),
 * probably the purpose of Dialogs will be unique like showing confirmation.
 * In those cases, close button might not be needed.
 */
const Modal = () => {
  const { isOpenModal, children } = useModal()
  const { height } = useHeight()

  return (
    <>
      {isOpenModal && (
        <Overlay click={() => {}}>
          <div
            className="h-auto p-0 m-0 text-left bg-white rounded shadow-xl z-50 overflow-auto"
            style={{ maxHeight: height }}
          >
            <div className="m-0">{children}</div>
          </div>
        </Overlay>
      )}
    </>
  )
}

export default Modal
