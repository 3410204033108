import { DeleteCard } from '@/types'
import { DELETE_CARD } from '@/api/graphql/mutations'
import { useMutation } from '@apollo/client'
import { useSpinner } from '@/hooks/spinner'
import useTranslation from '@/hooks/i18n'
import Button from '@/components/Parts/Button/Red'
import Cancel from '@/components/Parts/Button/White'
import { useModal } from '@/hooks/modal'
import { useNavigate } from 'react-router-dom'
import errorHandler from '@/api/graphql/error'
import { useState } from 'react'
import { GoAlert } from 'react-icons/go'
import { publicPaths } from '@/utils/path'

interface Props {
  cardKey: string
  refetch: () => Promise<void>
}

export default function ConfirmDelete(props: Props) {
  const navigate = useNavigate()
  const [deleteCard] = useMutation<{ deleteCard: DeleteCard }>(DELETE_CARD)
  const [error, setError] = useState('')
  const { enableSpinner, disableSpinner } = useSpinner()
  const { t } = useTranslation()
  const { closeModal } = useModal()

  const reload = () => {
    props
      .refetch()
      .then(() => {
        disableSpinner()
        closeModal()
      })
      .catch(() => {
        disableSpinner()
        closeModal()
        navigate(publicPaths.somethingWentWrong)
      })
  }

  const del = () => {
    setError('')
    enableSpinner()
    deleteCard({ variables: { cardKey: props.cardKey } })
      .then(() => {
        reload()
      })
      .catch((error) => {
        const result = errorHandler(error)
        if (
          result &&
          result.extentions &&
          result.extentions.code === 'DetachPaymentMethodFailed'
        ) {
          // Delete the card from DB but detached the pm because the card is not attached to customer.
          // We are sure why it is happened. We asked to Stripe the reason.
          reload()
          return
        }
        disableSpinner()

        if (result.message) {
          setError(result.message)
        } else {
          navigate(publicPaths.somethingWentWrong)
        }
      })
  }

  return (
    <div id="qcp-delete-card-div-confirm">
      <div className="px-4 md:w-96">
        <div className="mt-8 mb-2 sm:text-xl text-red-500 flex items-center ">
          <GoAlert className="mr-2" />
          <h1 className="font-bold">{t('tr_deletingCreditCardTitle')}</h1>
        </div>

        <p>{t('tr_cofirmDeletingCreditCard')} </p>

        {error && (
          <p className="my-2 text-red-500" id="qcp-delete-crad-p-error">
            {t(error)}
          </p>
        )}

        <div className="md:max-w-96 ml-auto my-8">
          <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
            <Cancel
              name="tr_cancel"
              onClick={closeModal}
              id="qcp-delete-card-button-cancel"
            />
            <Button
              name="tr_yes"
              onClick={del}
              id="qcp-delete-card-button-yes"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
