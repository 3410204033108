import { Service } from '@/types'
import Frame from './Frame'
import Created from './Created'

type Props = {
  service: Service
  reload: () => void
}

function Operation(props: Props) {
  return <Created {...props} />
}

export default function CardService(props: Props) {
  return (
    <Frame service={props.service}>
      <Operation {...props} />
    </Frame>
  )
}
