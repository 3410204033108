import useTranslation from '@/hooks/i18n'
import Plain from './Plain'
import { MdInfo, MdCheckCircle } from 'react-icons/md'
import Button from '@/components/Parts/Button/Blue'
import { useSearchParams } from 'react-router-dom'
import { useState, useRef } from 'react'

function useStripeResult() {
  const [searchParams] = useSearchParams()
  const result = searchParams.get('result')
  const ref = useRef(false)

  const [stripeResult, setIsCanceled] = useState<boolean | undefined>(
    result == null ? undefined : result === 'success'
  )

  const clear = () => {
    if (ref.current) {
      return
    }
    ref.current = true
    const url = new URL(window.location.href)
    const params = url.searchParams
    params.delete('result')
    window.history.replaceState('', '', url.href)
    setIsCanceled(undefined)
  }

  return [stripeResult, clear] as const
}

type Props = {
  successMessage: string
  cancelMessage: string
}

export default function StripeResult({ successMessage, cancelMessage }: Props) {
  const { t } = useTranslation()
  const [stripeResult, clear] = useStripeResult()

  if (stripeResult === undefined) {
    return <></>
  }

  if (stripeResult) {
    return (
      <Plain close={() => {}}>
        <div className="relative">
          <div className="p-8 ">
            <MdCheckCircle className="text-7xl text-green-500 mx-auto mb-4" />
            <p className="text-xl text-gray-600 mb-8 max-w-md text-center">
              {t('tr_successOperation')}
            </p>
            <p className="text-gray-600 mb-8 max-w-md text-center">
              {t(successMessage)}
            </p>
            <div className="max-w-xs mx-auto">
              <Button
                name="tr_close"
                onClick={clear}
                id="qcp-card-register-success-button"
              />
            </div>
          </div>
        </div>
      </Plain>
    )
  }

  return (
    <Plain close={() => {}}>
      <div className="relative">
        <div className="p-8 ">
          <MdInfo className="text-7xl text-red-500 mx-auto mb-4" />
          <p className="text-xl text-gray-600 mb-8 max-w-md text-center">
            {t('tr_canceledOperation')}
          </p>
          <p className="text-gray-600 mb-8 max-w-md text-center">
            {t(cancelMessage)}
          </p>
          <div className="max-w-xs mx-auto">
            <Button name="tr_close" onClick={clear} />
          </div>
        </div>
      </div>
    </Plain>
  )
}
