import useTranslation from '@/hooks/i18n'
import Plain from './Plain'
import { MdCheckCircle } from 'react-icons/md'
import Button from '@/components/Parts/Button/Blue'
import { useRef, useState } from 'react'
import Loader from '@/components/Parts/CardLoader'

export default function Reload() {
  const { t } = useTranslation()
  const ref = useRef(false)
  const [reload, setReload] = useState(false)

  return (
    <Plain close={() => {}}>
      <div className="relative">
        <div className="p-8 ">
          <MdCheckCircle className="text-7xl text-green-500 mx-auto mb-4" />
          <p className="text-xl text-gray-600 mb-8 max-w-md text-center">
            {t('tr_newVersionAvailable')}
          </p>
          <div className="max-w-xs mx-auto">
            <Button
              name="tr_refreshNow"
              onClick={() => {
                if (ref.current) {
                  return
                }
                ref.current = true
                setReload(true)
                window.location.reload()
              }}
            />
          </div>
        </div>
        {reload && <Loader />}
      </div>
    </Plain>
  )
}
