import { useState } from 'react'
import Btn from '@/components/Parts/Button/Blue'
import Password from '@/components/Parts/Inputs/Password'
import PlainInput from '@/components/Parts/Inputs/Plain2'
import useTranslation from '@/hooks/i18n'
import { useSpinner } from '@/hooks/spinner'
import Auth from '@aws-amplify/auth'
import { publicPaths } from '@/utils/path'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { privatePaths } from '@/utils/path'

function SignIn() {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { enableSpinner, disableSpinner } = useSpinner()
  const [authError, setAuthError] = useState(false)
  const disable = email.length === 0 || password.length === 0
  const navigate = useNavigate()
  const location = useLocation()
  const search = location.search
  const query = queryString.parse(search)

  const signin = (eml: string, pass: string) => {
    if (eml.length === 0 || pass.length === 0) return

    setAuthError(false)
    enableSpinner()

    Auth.signIn(eml, pass)
      .then((user) => {
        Auth.sendCustomChallengeAnswer(user, '123456')
          .then((res) => {
            if (res.challengeName === 'CUSTOM_CHALLENGE') {
              setAuthError(true)
            } else {
              navigate(
                query.redirect === undefined
                  ? privatePaths.home
                  : (query.redirect as string)
              )
            }
          })
          .catch(() => {
            setAuthError(true)
          })
          .finally(() => {
            disableSpinner()
          })
      })
      .catch(() => {
        setAuthError(true)
        disableSpinner()
      })
  }

  return (
    <>
      <div className="mb-8">
        <p className="text-2xl text-gray-800 font-bold">{t('Sign in')}</p>
      </div>
      <div className="mb-8">
        <PlainInput
          value={email}
          title="Email"
          maxLength={254}
          change={setEmail}
          autoFocus={true}
          keydown={(value) => {
            setEmail(value)
            signin(value, password)
          }}
          id="qcp-sign-in-input-email"
        />
      </div>
      <div className="mb-4">
        <Password
          password={password}
          onChange={(value) => setPassword(value)}
          keyDown={(value) => {
            setPassword(value)
            signin(email, value)
          }}
          id="qcp-sign-in-input-password"
        />
      </div>
      <a
        href={publicPaths.forgotPassword}
        className="font-bold text-blue-500 hover:text-blue-600 text-sm mb-4 cursor-pointer"
      >
        {t('tr_forgotPasswordLink')}
      </a>
      <p className={`${!authError && 'invisible'} text-red-400 mt-2`}>
        {t('tr_authenticationFailed')}
      </p>

      <div className="text-right mt-2">
        <Btn
          disabled={disable}
          name="tr_signIn"
          onClick={() => signin(email, password)}
        />
      </div>
    </>
  )
}

export default SignIn
