import useTranslation from '@/hooks/i18n'

type Props = {
  title?: string
}

export default function Index({ title = 'tr_nowLoading' }: Props) {
  const { t } = useTranslation()

  return (
    <div className="flex items-center mt-2">
      <div className="qcp-loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4" />
      <p className="text-sm text-blue-400 ml-2 font-bold">{t(title)}</p>
    </div>
  )
}
