import Box from '@/components/PrivatePages/Box'
import Summary from './Summary'
import { EventAndLogs } from '@/types'
import useTranslation from '@/hooks/i18n'
import { useState } from 'react'
import Details from './Details'

type Props = {
  events: EventAndLogs[]
}

export default function Index({ events }: Props) {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(0)

  if (events.length === 0) {
    return (
      <Box title="tr_eventAndLogs">
        <p className="text-gray-600">{t('tr_noLogs')}</p>
      </Box>
    )
  }

  return (
    <Box title="tr_eventAndLogs">
      <div className="w-full overflow-auto">
        <div className="grid grid-cols-2 gap-8">
          <div>
            <p className="text-gray-700 mb-4">{t('tr_eventHistory')}</p>
            {events.map((event, index) => (
              <Summary
                eventAndLogs={event}
                isSelected={index === selected}
                key={index}
                onClick={() => setSelected(index)}
              />
            ))}
          </div>
          <div className="px-8 border-l border-gray-300">
            <p className="text-gray-700 mb-4">{t('tr_eventDetails')}</p>
            <Details event={events[selected]} />
          </div>
        </div>
      </div>
    </Box>
  )
}
