import useTranslation from '@/hooks/i18n'

type Props = {
  status: string
}

type Status = {
  name: string
  className: string
}

const serviceList: { [key: string]: Status } = {
  subscription: {
    name: 'Auto-renew',
    className: 'bg-green-100 text-green-900 font-bold inline-block',
  },
  creating: {
    name: 'Creating service',
    className: 'bg-gray-100 text-gray-900 font-bold inline-block',
  },
  canceled: {
    name: 'Canceled',
    className: 'bg-red-100 text-red-900 font-bold inline-block',
  },
  trial: {
    name: 'Trial',
    className: 'bg-yellow-100 text-yellow-900 font-bold inline-block',
  },
}

export default function ServiceStatus({ status }: Props) {
  const { t } = useTranslation()
  return (
    <div
      className={`px-4 ${serviceList[status].className}`}
      id="qcp-qtc-div-status"
    >
      {t(serviceList[status].name)}
    </div>
  )
}
