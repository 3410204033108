import PageFrame from '@/components/PublicPages/PageFrame'
import SignIn from './SignIn'
import Logo from '@/components/Svg/CustomerportalLogo'

function SignInPage() {
  return (
    <PageFrame title="Sign in">
      <div>
        <div className="w-48 mx-auto mt-12 mb-4">
          <Logo subColor={'customerportal_logo_svg__st1'} />
        </div>

        <div className="container mx-auto flex items-center ">
          <div className=" w-full">
            <div className="flex justify-center">
              <SignIn />
            </div>
          </div>
        </div>
      </div>
    </PageFrame>
  )
}

export default SignInPage
