import CreditCard from '@/components/CreditCard'
import { Card } from '@/types'
import { selectCardStyle } from '@/utils/styles'

type Props = {
  cardKey: string
  cards: Card[]
  select: (cardKey: string) => void
}

export default function CardList({ cards, cardKey, select }: Props) {
  return (
    <>
      {cards.map((card, index) => {
        const styles = selectCardStyle(card.cardKey === cardKey)

        return (
          <CreditCard
            styles={styles}
            key={index}
            select={() => select(card.cardKey!)}
            isSelected={card.cardKey! === cardKey}
            cardKey={card.cardKey!}
            name={card.name!}
            expMonth={card.expMonth!}
            expYear={card.expYear!}
            lastNumber={card.lastNumber!}
            brand={card.brand!}
            processing={card.processing}
            id={index}
          />
        )
      })}
    </>
  )
}
