import { useCallback, useState, useEffect } from 'react'
import withPageFrame from '@/components/PrivatePages'
import CardList from '@/components/CreditCardList'
import Box from '@/components/PrivatePages/Box'
import OrderSummary from './OrderSummary'
import { SESSION_CARDS } from '@/api/graphql/queries'
import { Card, Invoice } from '@/types'
import Loader from '@/components/Parts/Loader'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import NewCard from './NewCard'
import useTranslation from '@/hooks/i18n'
import { publicPaths } from '@/utils/path'
import StripeResult from '@/components/Parts/Modal/StripeResult'
import { gqlClient } from '@/api/graphql'
import errorHandler from '@/api/graphql/error'

type ServicePriceWithCards = {
  availableCards: Card[]
  sessionInvoice: Invoice
}

function Index() {
  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get('id')
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [cardKey, setCardKey] = useState('')
  const [priceWithCards, setPriceWithCards] = useState<
    ServicePriceWithCards | undefined
  >(undefined)
  const [loading, setLoading] = useState(true)

  const fetch = useCallback(() => {
    setLoading(true)
    return new Promise<void>((resolve, reject) => {
      gqlClient
        .query<{ availableCards: Card[]; sessionInvoice: Invoice }>({
          query: SESSION_CARDS,
          variables: { sessionId },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          setPriceWithCards({ ...res.data! })
          setLoading(false)
          resolve()
        })
        .catch((error) => {
          const res = errorHandler(error)
          if (res.extentions && res.extentions.code === 'InProcessing') {
            setLoading(true)
          } else {
            navigate(publicPaths.somethingWentWrong)
          }
          reject()
        })
    })
  }, [])

  useEffect(() => {
    fetch().catch(() => {})
  }, [fetch])

  useEffect(() => {
    if (!loading) return

    const timer = setInterval(() => {
      if (loading) {
        fetch().catch(() => {})
      }
    }, 3 * 1000)

    return () => {
      clearInterval(timer)
    }
  }, [loading, fetch])

  if (!sessionId || !priceWithCards) {
    navigate(publicPaths.somethingWentWrong)
    return <></>
  }

  if (loading) {
    return <Loader />
  }

  const { subtotal, tax, total, currency } = priceWithCards.sessionInvoice

  return (
    <>
      <Box title="Select credit card for purchase">
        <p className="mb-4 text-gray-700">{t('tr_clickCreditCard')}</p>

        <div className="md:flex md:items-start px-2 py-4">
          <div className="w-full grid 2xl:grid-cols-2 grid-cols-1 gap-4">
            <CardList
              cards={priceWithCards.availableCards}
              cardKey={cardKey}
              select={setCardKey}
            />
          </div>
          <div className="hidden md:block md:w-110 md:ml-auto md:pl-8">
            <OrderSummary
              cardKey={cardKey}
              subtotal={subtotal!}
              tax={tax!}
              total={total!}
              sessionId={sessionId}
              currency={currency!}
              id="qcp-session-select-card-button-purchase-now-laptop"
            />
          </div>
        </div>

        <div className="mt-8 md:hidden block mx-2">
          <OrderSummary
            cardKey={cardKey}
            subtotal={subtotal!}
            tax={tax!}
            total={total!}
            sessionId={sessionId}
            currency={currency!}
            id="qcp-session-select-card-button-purchase-now-sp"
          />
        </div>
      </Box>
      <div className="mt-32">
        <Box title="tr_purchaseWithNewCard">
          <NewCard sessionId={sessionId} />
        </Box>
      </div>
      <StripeResult
        successMessage="tr_successBuy"
        cancelMessage="tr_canceledCheckout"
      />
    </>
  )
}

export default withPageFrame('Purchase of services', Index)
