import { HTMLProps } from 'react';

import PlainButton from './Plain';

type Props = {
  name: string
  onClick?: () => void
} & HTMLProps<HTMLButtonElement>

function Blue(props: Props) {
  return (
    <PlainButton
      {...props}
      textColor="text-white"
      bgColor="bg-blue-500"
      bgColorHover="bg-blue-700"
    />
  )
}

export default Blue
