import { useContext } from 'react';

import { AppContext } from '@/contexts';
import { defaultMe } from '@/contexts/auth';
import { Me } from '@/types';

/**
 * Manage the global state of authorization.
 *
 * @returns loading: If true, does not use isAuth and me.
 * @returns isAuth: If true, authorized.
 * @returns me: Include user's role.
 * @returns signin: Pass Me. It will make isAuth true
 * @returns signout: It will make isAuth false
 */
export function useAuth() {
  const { state, dispatch } = useContext(AppContext)

  const signin = (me: Me) => dispatch({ type: 'signin', payload: { me } })

  const signout = () => dispatch({ type: 'signout' })

  if (state === undefined || state.auth === undefined) {
    return {
      loading: true,
      isAuth: false,
      me: defaultMe,
      signin,
      signout,
    } as const
  }

  const loading = state.auth.loading
  const isAuth = state.auth.isAuth
  const me = state.auth.me

  return { loading, isAuth, me, signin, signout } as const
}
