import Canceled from '@/components/Result/Canceled'
import { privatePaths } from '@/utils/path'

export default function CanceledPage() {
  return (
    <Canceled
      title="Failed to register a new card"
      message="Please check Credit Card."
      goBackWhere="tr_goBack"
      goBackTo={privatePaths.paymentCards}
    />
  )
}
