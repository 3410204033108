import PlainInput from '@/components/Parts/Inputs/Plain2'
import Password from '@/components/Parts/Inputs/Password'
import { Txt } from '@/types'
import useTranslation from '@/hooks/i18n'
import * as validate from '@/utils/validate'

interface Props {
  fullName: Txt
  department: Txt
  title: Txt

  password: Txt
}

function User({ fullName, department, title, password }: Props) {
  const { t } = useTranslation()
  return (
    <div>
      <div className="mb-8">
        <PlainInput
          title="tr_fullName"
          maxLength={255}
          validate={validate.fullName}
          value={fullName.text}
          change={fullName.onChange}
          keydown={fullName.keyDown}
          id="qcp-register-input-full-name"
        />
      </div>
      <div className="mb-8">
        <PlainInput
          title="tr_department"
          maxLength={255}
          validate={validate.department}
          value={department.text}
          change={department.onChange}
          keydown={department.keyDown}
          id="qcp-register-input-department"
        />
      </div>
      <div className="mb-8">
        <PlainInput
          title="tr_title"
          maxLength={255}
          value={title.text}
          change={title.onChange}
          keydown={title.keyDown}
          id="qcp-register-input-title"
        />
      </div>

      <div className="mb-8">
        <Password
          title={'tr_password'}
          validate={validate.password}
          password={password.text}
          onChange={password.onChange}
          keyDown={password.keyDown}
          autoComplete={'new-password'}
          id="qcp-register-input-password"
        />
      </div>
      <p className="my-2 text-red-500 font-bold">{t('tr_required')}</p>
    </div>
  )
}

export default User
