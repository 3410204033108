import useTranslation from '@/hooks/i18n'
import ServiceStatus from '@/components/Parts/ServiceStatus'
import Subscription from '@/components/Services/Subscription'
import { toDate } from '@/utils'

type Props = {
  status: string
  purchaseAt: string
  purchasedBy: string
  serviceKey: string
  lastAvailableDate?: string
  currency: string
  refech: () => Promise<void>
}

export default function ServiceStatusComponent(props: Props) {
  const { t, d } = useTranslation()

  return (
    <div className="md:flex md:items-center">
      <div className="text-sm mb-2 md:pr-8 md:border-r md:border-gray-200">
        <p className="mb-1 text-gray-600">{t('tr_status')}</p>
        <ServiceStatus status={props.status} />
      </div>
      <div className="text-sm mb-2 md:px-8 md:border-r md:border-gray-200">
        <p className="mb-1 text-gray-600">{t('tr_orderedDate')}</p>
        <p>{d('ymdhms', toDate(props.purchaseAt))}</p>
      </div>
      <div className="text-sm mb-2 md:px-8 md:border-r md:border-gray-200">
        <p className="mb-1 text-gray-600">{t('tr_orderedBy')}</p>
        <p>{props.purchasedBy}</p>
      </div>
      {props.status === 'canceled' && props.lastAvailableDate !== undefined && (
        <div className="text-sm mb-2 mr-2 md:px-8 md:border-r md:border-gray-200">
          <p className="mb-1 text-gray-600">{t('tr_contractLastDate')}</p>
          <p>{d('ymdhms', toDate(props.lastAvailableDate))}</p>
        </div>
      )}
      <div className="ml-auto">
        <Subscription {...props} />
      </div>
    </div>
  )
}
