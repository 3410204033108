import useTranslation from '@/hooks/i18n'
import { CardOwner, CardService } from '@/types'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa'
import ConfirmDelete from './ConfirmDelete'
import { useModal } from '@/hooks/modal'
import ConfirmChange from './ConfirmChange'
import Loader from '@/components/Parts/CardLoader'

type CardStype = {
  border: string
  title: string
  value: string
}

interface Props {
  isSelected: boolean
  cardKey: string
  name: string
  brand: string
  lastNumber: string
  expMonth: number
  expYear: number
  styles: CardStype
  id: number
  services?: CardService[]
  cardOwner?: CardOwner
  select: (cardKey: string) => void
  refetch?: () => Promise<void>
  processing?: boolean
}

function CreditCard(props: Props) {
  const { t } = useTranslation()
  const { openModal } = useModal()

  const click = (cardKey: string) =>
    props.refetch &&
    openModal(<ConfirmDelete cardKey={cardKey} refetch={props.refetch} />)

  const change = (cardKey: string) =>
    openModal(<ConfirmChange cardKey={cardKey} />)

  return (
    <div
      className={`relative text-sm ${props.styles.border} min-w-32`}
      onClick={() => props.select(props.cardKey)}
      id="qcp-cards-div"
    >
      <div className="flex items-center border-b border-gray-300 mb-1 pb-1">
        <h1 className="font-bold  text-gray-500">{t('tr_card')}</h1>

        {props.refetch && (
          <div className="ml-auto flex items-center">
            <FaRegEdit
              className="cursor-pointer"
              onClick={() => change(props.cardKey)}
              id={`qcp-card-icon-reg-${props.id}`}
            />

            <FaRegTrashAlt
              className="ml-4 cursor-pointer"
              onClick={() => click(props.cardKey)}
              id={`qcp-card-icon-trash-${props.id}`}
            />
          </div>
        )}
      </div>
      <table>
        <tbody>
          <tr className="">
            <td className={props.styles.title}>{t('tr_name')}</td>
            <td
              className={props.styles.value}
              id={`qcp-card-td-name-${props.id}`}
            >
              {props.name}
            </td>
          </tr>
          <tr className="">
            <td className={props.styles.title}>{t('tr_brand')}</td>
            <td
              className={props.styles.value}
              id={`qcp-card-td-brand-${props.id}`}
            >
              {props.brand}
            </td>
          </tr>
          <tr className="">
            <td className={props.styles.title}>{t('tr_number')}</td>
            <td
              className={props.styles.value}
              id={`qcp-card-td-last-number-${props.id}`}
            >{`****${props.lastNumber}`}</td>
          </tr>
          <tr className="">
            <td className={props.styles.title}>{t('tr_expiration')}</td>
            <td
              className={props.styles.value}
              id={`qcp-card-td-expiration-${props.id}`}
            >{`${props.expMonth}/${props.expYear}`}</td>
          </tr>
        </tbody>
      </table>
      {props.cardOwner && (
        <div>
          <h1 className="mb-1 pb-1 border-b mt-4 font-bold border-gray-300 text-gray-500">
            {t('tr_owner')}
          </h1>
          <table>
            <tbody>
              <tr className="">
                <td className={props.styles.title}>{t('tr_name')}</td>
                <td
                  className={props.styles.value}
                  id={`qcp-card-owner-td-user-name-${props.id}`}
                >
                  {props.cardOwner.userName}
                </td>
              </tr>
              <tr className="">
                <td className={props.styles.title}>{t('tr_email')}</td>
                <td
                  className={props.styles.value}
                  id={`qcp-card-owner-td-email-${props.id}`}
                >
                  {props.cardOwner.email}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {props.services !== undefined && (
        <div>
          <h1 className="mb-1 pb-1 border-b mt-4 font-bold border-gray-300 text-gray-500">
            {t('tr_billed')}
          </h1>
          <table>
            <tbody>
              <tr className="">
                <td className={props.styles.title}>{t('tr_services')}</td>
                <td className={props.styles.value}>
                  {props.services && (
                    <p id={`qcp-card-p-services-${props.id}`}>
                      {props.services.map((srv) => srv.serviceName).join(', ')}
                    </p>
                  )}
                  {props.services.length === 0 && t('tr_noServiceNow')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {props.processing && <Loader />}
    </div>
  )
}

export default CreditCard
