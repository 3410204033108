import { useEffect, useState } from 'react'
import useTranslation, { useNumberFormatter } from '@/hooks/i18n'
import { useCheckPrice } from './hooks'
import { CalcPrice } from '@/types'
import Loader from '@/components/Parts/Loader'
import InputNumber from '@/components/Parts/Inputs/InputNumber'
import Button from '@/components/Parts/Button/Blue'
import { useSpinner } from '@/hooks/spinner'
import { CREATE_ORDER_SESSION } from '@/api/graphql/mutations'
import { useMutation } from '@apollo/client'
import { serviceTypeTable } from '../helper'
import { privatePaths } from '@/utils/path'
import { useNavigate } from 'react-router-dom'
import { useModal } from '@/hooks/modal'
import { thirdPartyPaths } from '@/utils/path'

type MaxUserProps = {
  calcPrice: CalcPrice
  serviceKey: string
  userNum: number
  fetch: (maxUser?: number) => Promise<void>
}

function IncreaseMaxUserDialog({
  userNum,
  fetch,
  calcPrice,
  serviceKey,
}: MaxUserProps) {
  const min = Number(userNum)
  const [maxUser, setMaxUser] = useState(userNum)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const max = Number(calcPrice.options[0].option2)
  const { enableSpinner, disableSpinner } = useSpinner()
  const [createOrderSession] = useMutation<{
    createOrderSession: { sessionKey: string }
  }>(CREATE_ORDER_SESSION)
  const navigate = useNavigate()
  const { closeModal } = useModal()
  const n = useNumberFormatter(calcPrice.currency)

  const verify = (num: Number) => {
    return !(num < min + 1 || num > max)
  }

  const isDisable = !verify(maxUser)

  const createOrder = () => {
    enableSpinner()
    createOrderSession({
      variables: {
        serviceType: serviceTypeTable.qualitiaCloud,
        serviceKey,
        option1: 'increase_user',
        option2: maxUser,
      },
    })
      .then(({ data }) => {
        navigate({
          pathname: privatePaths.sessionInvoiceForSubscription,
          search: `?id=${data!.createOrderSession.sessionKey}`,
        })
        closeModal()
        disableSpinner()
      })
      .catch((err) => {
        closeModal()
        disableSpinner()
      })
  }

  return (
    <div>
      <p>
        {t('tr_explainUpdateMaxUser {{rate}} {{min}}', {
          rate: n('currency', Number(calcPrice.options[0].rate)),
          min: n('default', min),
        })}
      </p>

      <a
        href={thirdPartyPaths.qualitiaCloudPrivaryPolicy}
        className="cursor-point text-blue-500 hover:text-blue-600"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('tr_seeTermsAndConditions')}
      </a>

      <div className="mt-4 mb-2">
        <InputNumber
          title="tr_maximumUser"
          value={maxUser}
          change={(user) => {
            setMaxUser(user)

            setLoading(true)
            fetch(user)
              .then(() => setLoading(false))
              .catch(() => setLoading(false))
          }}
          load={() => {
            setLoading(true)
          }}
          verify={verify}
          inputMin={min + 1}
          min={min}
          max={max}
          maxLength={7}
          id="qcp-qtc-contract-detail-input-max-user"
        />
      </div>

      {loading && (
        <div className="h-32">
          <Loader />
        </div>
      )}

      {/* {isDisable && <div className="h-32"></div>} */}

      {!loading && (
        <div className="h-32">
          <p
            className="text-2xl sm:text-5xl font-bold text-gray-700 mb-2"
            id="qcp-qtc-current-contract-p-total"
          >
            {n('currency', Number(calcPrice.total))}
          </p>
          <p className="text-gray-500">
            {t('tr_increasedNumberOfUsersTitle')}
            <span className="ml-2">
              {n('default', Number(calcPrice.options[0].quantity))}
            </span>
          </p>

          <p className="text-gray-500">
            {t('tr_remaingPeriod')}
            <span className="ml-2">
              {n('default', Number(calcPrice.options[0].option5!))}
            </span>
          </p>
        </div>
      )}

      <div>
        <Button
          name="tr_next"
          disabled={isDisable}
          onClick={createOrder}
          id="qcp-qtc-current-contract-button"
        />
      </div>
    </div>
  )
}

type Props = {
  serviceKey: string
  maxUser: number
}

export default function IncreaseMaxUser({ serviceKey, maxUser }: Props) {
  const { t } = useTranslation()
  const { fetch, price, error } = useCheckPrice(serviceKey, 'increase_user')
  const { closeModal } = useModal()

  useEffect(() => {
    fetch(maxUser).catch(() => {})
  }, [fetch, maxUser])

  if (error) {
    return (
      <div className="md:w-104 p-8">
        <p className="my-4 text-gray-600 text-xl font-bold">
          {t('tr_increasedNumberOfUsersTitle')}
        </p>
        <p className="text-gray-700">{t('tr_increasedNumberOfUsersError')}</p>
        <div className="mt-4">
          <Button name="tr_gotIt" onClick={closeModal} />
        </div>
      </div>
    )
  }

  return (
    <div className="md:w-104 px-2 sm:p-8">
      <p className="my-4 text-gray-600 text-xl font-bold">
        {t('tr_increasedNumberOfUsersTitle')}
      </p>

      {!price && error === false && <Loader />}

      {price && (
        <IncreaseMaxUserDialog
          userNum={maxUser}
          fetch={fetch}
          calcPrice={price}
          serviceKey={serviceKey}
        />
      )}
    </div>
  )
}
