import { useState } from 'react'
import { FaKey } from 'react-icons/fa'

import useTranslation from '@/hooks/i18n'

import Operation from './Operation'
import Success from './Success'

const Modal = () => {
  const [result, setResult] = useState(false)
  const { t } = useTranslation()

  return (
    <div className="md:w-100">
      <div className="p-8">
        <div className="flex items-center text-gray-700 text-xl">
          <FaKey className="mr-2 text-yellow-500" />
          <h1 className=" font-bold">{t('tr_changePassword')}</h1>
        </div>
      </div>
      {!result && <Operation success={() => setResult(true)} />}
      {result && <Success />}
    </div>
  )
}

export default Modal
