import { Invoice } from '@/types'
import Organization from './InvoiceOrganization'
import InvoiceMeta from './InvoiceMeta'
import BreakDown from './Breakdown'
import InvoiceSummary from './InvoiceSummary'
import QualitiaIcon from '@/components/Parts/QualitiaIcon'
import useTranslation from '@/hooks/i18n'

interface Props {
  title: string
  invoice: Invoice
}

export default function InvoiceFormat({ title, invoice }: Props) {
  const { t } = useTranslation()

  const {
    issuedAt,
    invoiceId,
    fromOrg,
    toOrg,
    invoices,
    subtotal,
    tax,
    total,
    currency,
  } = invoice

  return (
    <div className="w-full">
      <div className="md:w-120 md:mx-auto border border-gray-300 py-4">
        <div className="border-b-2 border-indigo-400 px-8 mb-1 text-4xl font-bold pb-1">
          {t(title)}
        </div>
        <div className="mt-4 mb-4">
          <InvoiceMeta invoiceID={invoiceId!} issuedAt={issuedAt!} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 mb-8">
          <Organization direction="tr_from" invoiceOrganization={fromOrg!} />
          <Organization direction="tr_to" invoiceOrganization={toOrg!} />
        </div>

        <div className="mt-12">
          <div className="px-8">
            <BreakDown currency={currency!} invoices={invoices!} />
          </div>
        </div>
        <div className="mt-8 w-full">
          <InvoiceSummary
            currency={currency!}
            total={total!}
            subtotal={subtotal!}
            tax={tax!}
          />
        </div>
        <div className="w-full mt-8">
          <QualitiaIcon />
        </div>
      </div>
    </div>
  )
}
