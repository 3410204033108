import { privatePaths } from '@/utils/path'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function RedirectToHome() {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(privatePaths.home)
  })

  return <></>
}

export default RedirectToHome
