import { getToken } from '@/auth'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  fromPromise,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { retrieveIdToken } from '@/auth'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import { ApiStatus } from '../version'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT!,
})

const authLink = setContext(async (_, { headers }) => {
  const token = getToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const retryLink = new RetryLink({
  delay: (count, operation, error) => {
    return count * 200
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => error && error.networkError,
  },
})

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
    if (networkError) {
      const res = networkError as any
      const apiStatus = new ApiStatus(Number(res.statusCode))

      if (apiStatus.shouldReload()) {
        apiStatus.reload()
        // return forward(operation)
        return
      }

      console.log(`[Network error]: ${networkError}`)
      return fromPromise(retrieveIdToken()).flatMap(() => {
        const token = getToken()

        const { authorization, ...rest } = operation.getContext().headers
        // modify the operation context with a new token
        operation.setContext({
          headers: {
            ...rest,
            authorization: token ? `Bearer ${token}` : '',
          },
        })
        // retry the request, returning the new observable
        return forward(operation)
      })
    }
  }
)

export const gqlClient = new ApolloClient({
  link: authLink.concat(retryLink).concat(errorLink).concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          invoiceHistory: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
        },
      },
    },
  }),
})
