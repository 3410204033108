import { useContext } from 'react'

import { AppContext } from '@/contexts'

/**
 * Manage the global state of leftBar open and close.
 *
 * @returns isOpenLeftBar: boolean
 * @returns openLeftBar: Set isOpenLeftBar true
 * @returns closeLeftBar: Set isOpenLeftBar false
 */
export function useLeftBar() {
  const { state, dispatch } = useContext(AppContext)

  const openLeftBar = () =>
    dispatch({ type: 'openLeftBar', payload: { isOpen: true } })

  const closeLeftBar = () => dispatch({ type: 'closeLeftBar' })

  if (state === undefined || state.leftBar === undefined) {
    const isOpenLeftBar = true

    return { isOpenLeftBar, openLeftBar, closeLeftBar } as const
  }

  const isOpenLeftBar = state.leftBar.isOpen

  return { isOpenLeftBar, openLeftBar, closeLeftBar } as const
}
