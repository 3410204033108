import * as React from 'react'

const SvgCustomerportalLogo = (props) => {
  const { subColor, ...rest } = props

  const subcolor = props.subColor
    ? props.subColor
    : 'customerportal_logo_svg__st1_white'

  return (
    <svg
      id="customerportal_logo_svg__\u30EC\u30A4\u30E4\u30FC_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 182 39.2"
      style={{
        enableBackground: 'new 0 0 182 39.2',
      }}
      xmlSpace="preserve"
      {...rest}
    >
      <style>
        {`.customerportal_logo_svg__st0{fill:#60a5fa}.customerportal_logo_svg__st1{fill:#757575}`}
      </style>
      <path
        className="customerportal_logo_svg__st0"
        d="M16.5.2h-4.3C8.3.2 5.3 1.4 3 3.9 1 6.1 0 8.8 0 12v.5c0 3.2 1 5.9 2.9 8.1 2.2 2.4 5.3 3.7 9.2 3.7h14.3l-1.8-2.8c.3-.3.7-.6 1-.9 2-2.2 2.9-4.9 2.9-8.1V12c0-3.2-1-5.9-2.9-8.1C23.4 1.5 20.3.2 16.5.2M4.7 12.3c.1-5.9 3.5-8.8 7.6-8.8h3.9c4.1 0 7.5 3 7.6 8.8-.1 5.9-3.5 8.8-7.6 8.8h-3.9c-4.1 0-7.5-3-7.6-8.8M88.2.2v21.1c0 1.6 1.3 3 3 3H109v-4.2H93.2c-.5 0-.8-.4-.8-.8V.3h-4.2zM111.4.2h4.2v24.1h-4.2zM118 4.4h9.5v19.9h4.2V4.4h9.5V.2H118zM143.6.2h4.2v24.1h-4.2zM71.7 5l4.6 8.4h-7.1L67 17.5h11.7l3.7 6.8h4.8L74.3 1.5c-1.1-2-4-2-5.1 0L56.3 24.3h4.8L71.7 5zM167.8 1.5c-1.1-2-4-2-5.1 0l-12.9 22.8h4.8L165.3 5l4.6 8.4h-7.1l-2.3 4.1h11.7l3.7 6.8h4.8L167.8 1.5zM57.8.2h-4.2v13.2c0 2.5-.8 4.3-2.3 5.4-1.3.9-3.3 1.3-6.1 1.3h-1.4c-2.7 0-4.8-.4-6.1-1.3-1.6-1-2.3-2.8-2.3-5.4V.2h-4.2v13.2c0 3.9 1.1 6.7 3.4 8.5 2 1.6 5.1 2.4 9.2 2.4h1.4c4 0 7.1-.8 9.1-2.4 2.3-1.8 3.4-4.6 3.4-8.5V.2z"
      />
      <path
        className={subcolor}
        d="M.9 35.1c0-2.4 1.8-4.1 4.2-4.1 1.3 0 2.4.5 3.1 1.3l-.9.9c-.6-.6-1.3-.9-2.1-.9-1.6 0-2.8 1.2-2.8 2.8 0 1.6 1.2 2.8 2.8 2.8.8 0 1.5-.3 2.1-.9l.9.9c-.7.9-1.8 1.3-3.1 1.3-2.4 0-4.2-1.7-4.2-4.1zM14.2 35.6v-4.5h1.5v4.4c0 1.6.7 2.3 2 2.3s2-.7 2-2.3v-4.4h1.4v4.5c0 2.3-1.3 3.6-3.5 3.6-2.1 0-3.4-1.3-3.4-3.6zM27 38.2l.5-1.1c.6.5 1.6.9 2.6.9 1.2 0 1.8-.4 1.8-1 0-1.7-4.7-.6-4.7-3.5 0-1.3 1-2.4 3.2-2.4.9 0 1.9.2 2.6.7l-.5 1.1c-.7-.4-1.5-.6-2.2-.6-1.2 0-1.7.5-1.7 1.1 0 1.7 4.7.6 4.7 3.5 0 1.3-1 2.4-3.2 2.4-1.2-.1-2.4-.5-3.1-1.1zM40.9 32.4h-2.6v-1.2H45v1.2h-2.6v6.7h-1.5v-6.7zM50.1 35.1c0-2.3 1.8-4.1 4.3-4.1s4.3 1.7 4.3 4.1c0 2.4-1.8 4.1-4.3 4.1s-4.3-1.8-4.3-4.1zm7 0c0-1.6-1.2-2.8-2.8-2.8-1.6 0-2.8 1.2-2.8 2.8 0 1.6 1.2 2.8 2.8 2.8 1.6 0 2.8-1.2 2.8-2.8zM72 39.1v-5.2l-2.6 4.3h-.7l-2.6-4.3v5.2h-1.4v-7.9h1.2l3.1 5.2 3.1-5.2h1.2v7.9H72zM85.9 37.8V39H80v-7.9h5.8v1.2h-4.3v2.1h3.8v1.2h-3.8v2.2h4.4zM97.3 39.1l-1.6-2.3h-2.1v2.3h-1.5v-7.9h3.3c2.1 0 3.4 1.1 3.4 2.8 0 1.2-.6 2.1-1.7 2.5l1.8 2.6h-1.6zm-2-6.7h-1.7v3.2h1.7c1.3 0 2-.6 2-1.6s-.7-1.6-2-1.6zM119.2 34c0 1.7-1.3 2.8-3.4 2.8H114v2.3h-1.5v-7.9h3.3c2.1-.1 3.4 1 3.4 2.8zm-1.5 0c0-1-.7-1.6-2-1.6H114v3.1h1.7c1.3 0 2-.5 2-1.5zM124.7 35.1c0-2.3 1.8-4.1 4.3-4.1s4.3 1.7 4.3 4.1c0 2.4-1.8 4.1-4.3 4.1s-4.3-1.8-4.3-4.1zm7 0c0-1.6-1.2-2.8-2.8-2.8s-2.8 1.2-2.8 2.8c0 1.6 1.2 2.8 2.8 2.8s2.8-1.2 2.8-2.8zM144.5 39.1l-1.6-2.3h-2.1v2.3h-1.5v-7.9h3.3c2.1 0 3.4 1.1 3.4 2.8 0 1.2-.6 2.1-1.7 2.5l1.8 2.6h-1.6zm-2-6.7h-1.7v3.2h1.7c1.3 0 2-.6 2-1.6s-.7-1.6-2-1.6zM153.7 32.4h-2.6v-1.2h6.7v1.2h-2.6v6.7h-1.5v-6.7zM168.5 37.2h-4l-.8 1.8h-1.5l3.6-7.9h1.4l3.6 7.9h-1.5l-.8-1.8zm-.4-1.1-1.5-3.5-1.5 3.5h3zM176.4 31.1h1.5v6.7h4.1V39h-5.6v-7.9z"
      />
    </svg>
  )
}

export default SvgCustomerportalLogo
