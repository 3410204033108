import useTranslation from '@/hooks/i18n'
import { useModal } from '@/hooks/modal'
import { MdOutlineContactMail, MdOutlineEmail } from 'react-icons/md'
import Button from '@/components/Parts/Button/Blue'
import { contactUsEmail } from '@/utils'

const ContactEmail = () => {
  const { t } = useTranslation()
  const { closeModal } = useModal()

  return (
    <div className="md:w-96 px-8">
      <h1 className="text-gray-700 font-bold text-lg">
        {t('tr_contactUsTitle')}
      </h1>
      <p className="my-2">{t('tr_contactUs')}</p>
      <div className="flex items-center mt-4 text-gray-800">
        <MdOutlineEmail className="text-xl" />
        <p className="ml-2 pb-1">{contactUsEmail}</p>
      </div>
      <div className="my-8">
        <Button
          name="tr_close"
          onClick={() => {
            closeModal()
          }}
        />
      </div>
    </div>
  )
}

export default function ContactUs({ close }: { close: () => void }) {
  const { t } = useTranslation()
  const { openModal } = useModal()

  const confirm = () => {
    close()
    openModal(<ContactEmail />)
  }
  return (
    <>
      <div
        className="flex items-center hover:bg-gray-100 cursor-pointer px-4 py-2"
        onClick={confirm}
      >
        <MdOutlineContactMail />
        <p className="px-2">{t('tr_contactUsTitle')}</p>
      </div>
    </>
  )
}
