import useTranslation, { useNumberFormatter } from '@/hooks/i18n'
import { Currency } from '@/types'

interface Props {
  currency: Currency
  subtotal: number
  total: number
  tax: number
}

export default function InvoiceSummary({
  currency,
  subtotal,
  total,
  tax,
}: Props) {
  const { t } = useTranslation()
  const n = useNumberFormatter(currency)

  return (
    <div className="px-2 md:px-8 flex justify-end">
      <table className="text-right">
        <tbody>
          <tr>
            <td className="font-bold py-2 pr-12">{t('tr_subtotal')}</td>
            <td id="qcp-qtc-invoice-summary-td-subtotal">
              {n('currency', subtotal)}
            </td>
          </tr>
          <tr>
            <td className="font-bold py-2 pr-12">{t('tr_tax')}</td>
            <td id="qcp-qtc-invoice-summary-td-tax">{n('currency', tax)}</td>
          </tr>
          <tr>
            <td className="font-bold py-2 pr-12">{t('tr_total')}</td>
            <td className="font-bold" id="qcp-qtc-invoice-summary-td-total">
              {n('currency', total)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
