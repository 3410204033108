import { FaSignOutAlt } from 'react-icons/fa'

import { signOut } from '@/auth/token'
import Button from '@/components/Parts/Button/Blue'
import Cancel from '@/components/Parts/Button/White'
import useTranslation from '@/hooks/i18n'
import { useModal } from '@/hooks/modal'
import { useSpinner } from '@/hooks/spinner'
import { useState, useRef } from 'react'
import { publicPaths } from '@/utils/path'

const Confirm = () => {
  const ref = useRef(false)
  const { closeModal } = useModal()
  const [error, setError] = useState('')
  const { enableSpinner, disableSpinner } = useSpinner()

  const { t } = useTranslation()

  const click = async () => {
    if (ref.current) return
    ref.current = true

    setError('')
    enableSpinner()
    signOut()
      .then(() => {
        // If redirect to signin, context will be cleared.
        // So, We do not need to disableSpinner
        window.location.href = publicPaths.signin
      })
      .catch((err) => {
        disableSpinner()
        setError(err.message)
      })
      .finally(() => {
        ref.current = false
      })
  }

  return (
    <div className="md:w-96">
      <div className="p-8">
        <div className="flex items-center text-gray-700 text-xl">
          <FaSignOutAlt className="mr-2 text-green-500" />
          <h1 className=" font-bold">{t('tr_signOutTitle')}</h1>
        </div>
        <p className="mt-2 text-gray-900">{t('tr_signOut')}</p>
        <p className="my-2 font-bold text-red-500">{error}</p>
      </div>

      <div className="px-8 py-2 pb-4">
        <div className="grid grid-cols-2 gap-4">
          <Cancel name="tr_cancel" onClick={closeModal} />
          <Button
            name="tr_signOutButton"
            onClick={click}
            id="qcp-signout-button-confirm"
          />
        </div>
      </div>
    </div>
  )
}

const SignOut = ({ close }: { close: () => void }) => {
  const { openModal } = useModal()
  const { t } = useTranslation()

  const confirm = () => {
    close()
    openModal(<Confirm />)
  }

  return (
    <>
      <div
        className="flex items-center hover:bg-gray-100 cursor-pointer px-4 py-2"
        onClick={confirm}
        id="qcp-signout-icon"
      >
        <FaSignOutAlt />
        <p className="px-2">{t('tr_signOutTitle')}</p>
      </div>
    </>
  )
}

export default SignOut
