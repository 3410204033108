import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import register from '@/api/restApi/register'
import { saveSessionToLocalStorage } from '@/auth/token'
import Button from '@/components/Parts/Button/Blue'

import PlainInput from '@/components/Parts/Inputs/Plain2'
import PageFrame from '@/components/PublicPages/PageFrame'
import { useSpinner } from '@/hooks/spinner'
import Organization from './Organization'
import User from './User'
import Agreement from '@/components/Parts/Agreement'
import useForm from './hook'
import { countryIsoList } from '@/utils/countries'
import { ParamRegister } from '@/types'
import { privatePaths, publicPaths, thirdPartyPaths } from '@/utils/path'
import { useSearchParams } from 'react-router-dom'
import checkSession from '@/api/restApi/checkSession'
import Logo from '@/components/Svg/CustomerportalLogo'

function Register() {
  const { disabled, form } = useForm()
  const [agreement, setAgreement] = useState(false)
  const { enableSpinner, disableSpinner } = useSpinner()
  const [error, setError] = useState('')
  const [searchParams] = useSearchParams()
  const sessionKey = searchParams.get('session')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (sessionKey) {
      checkSession(sessionKey)
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          navigate(publicPaths.somethingWentWrong)
        })
    } else {
      if (sessionKey == null) {
        navigate(publicPaths.somethingWentWrong)
      }
    }
  }, [sessionKey, navigate])

  if (loading) {
    //TODO: implement loading
    return <>Loading</>
  }

  const registerUser = () => {
    setError('')
    if (disabled || !agreement || sessionKey == null) {
      return // Nothing to do
    }

    const params: ParamRegister = {
      sessionKey,
      organization: form.org.text,
      country: countryIsoList[form.country.text],
      zip: form.zip.text,
      address1: form.addr1.text,
      address2: form.addr2.text,
      address3: form.addr3.text,
      fullName: form.fullName.text,
      department: form.department.text,
      title: form.title.text,
      phone: form.phone.text,
      password: form.password.text,
      memo: form.memo.text,
      currency: form.currency.text,
    }

    enableSpinner()
    register(params)
      .then((sessionInfo) => {
        saveSessionToLocalStorage(sessionInfo)
        disableSpinner()
        if (sessionInfo.serviceType) {
          navigate({
            pathname: privatePaths.home,
            search: `?service=${sessionInfo.serviceType}`,
          })
        } else {
          navigate(privatePaths.home)
        }
      })
      .catch((err) => {
        disableSpinner()
        setError(`${err.title}: ${err.message}`)
      })
  }

  const keyDown = () => registerUser()

  return (
    <PageFrame title="Register">
      <div className="mt-2 md:mt-12 flex">
        <div className="md:border md:shadow-xl w-full md:w-130 p-8">
          {/* <h1 className="text-gray-700 font-bold text-xl mb-8">
            {t('QUALITIA CUSTOMER PORTAL')}
          </h1> */}
          <div className="w-48 mb-4">
            <Logo subColor={'customerportal_logo_svg__st1'} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
            <Organization
              org={{ ...form.org, keyDown }}
              country={{ ...form.country, keyDown }}
              zip={{ ...form.zip, keyDown }}
              addr1={{ ...form.addr1, keyDown }}
              addr2={{ ...form.addr2, keyDown }}
              addr3={{ ...form.addr3, keyDown }}
              phone={{ ...form.phone, keyDown }}
              currency={{ ...form.currency, keyDown }}
            />
            <User
              fullName={{ ...form.fullName, keyDown }}
              department={{ ...form.department, keyDown }}
              title={{ ...form.title, keyDown }}
              password={{ ...form.password, keyDown }}
            />
          </div>
          <div className="my-8">
            <div className="mb-8">
              <PlainInput
                title="tr_memo"
                maxLength={100}
                value={form.memo.text}
                change={form.memo.onChange}
                keydown={keyDown}
              />
            </div>

            <div className="mb-2">
              <Agreement
                url={thirdPartyPaths.portalPrivacyPolicy}
                checked={agreement}
                setChecked={setAgreement}
              />
            </div>

            <p className="text-red-500 text-sm">{error}</p>
          </div>
          <div className="text-right">
            <Button
              disabled={disabled || !agreement}
              name="tr_createAccount"
              onClick={() => {
                registerUser()
              }}
            />
          </div>
        </div>
      </div>
    </PageFrame>
  )
}

export default Register
