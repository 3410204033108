import { MdInfo } from 'react-icons/md'
import Button from '@/components/Parts/Button/Red'
import { useNavigate } from 'react-router-dom'
import { privatePaths } from '@/utils/path'
import useTranslation from '@/hooks/i18n'

export default function Reload() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="w-full h-screen text-center mt-12">
      {/* <div className="flex items-center justify-items-center text-center"> */}
      <MdInfo className="text-red-500 text-8xl my-12 mx-auto" />
      <h1 className="font-bold text-gray-600 text-4xl">
        {t('tr_somethingWentWrong')}
      </h1>
      <p className="mt-4 text-gray-600 text-2xl">
        {t('tr_waitForAWhite')}
        <br />
        {t('tr_improveVersion')}
      </p>
      <div className="max-w-sm mx-auto mt-12">
        <Button
          name="tr_refreshAndGoBack"
          onClick={() => navigate(privatePaths.home)}
        />
      </div>
    </div>
  )
}
