import { v4 as uuidv4 } from 'uuid';

export type State = {
  errors: {
    id: string
    status?: number
    message?: string
    params?: any
  }[]
}

export type Action =
  | { type: 'addErr'; status?: number; message: string; params?: any }
  | { type: 'removeErr'; id: string }

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'addErr': {
      return {
        errors: [
          {
            id: uuidv4(),
            status: action.status,
            message: action.message,
            params: action.params,
          },
          ...state.errors,
        ],
      }
    }
    case 'removeErr': {
      return {
        errors: state.errors.filter((error) => error.id !== action.id),
      }
    }
  }
}

export const initialState: {
  errors: { id: string; status?: number; message?: string; param?: any }[]
} = {
  errors: [],
}
