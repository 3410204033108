import { Me } from '@/types'

export type State = {
  isAuth: boolean
  loading: boolean
  me: Me
}

export const defaultMe: Me = {
  role: 'user',
  organization: '',
  fullName: '',
  email: '',
}

export type Action =
  | { type: 'signin'; payload: { me: Me } }
  | { type: 'signout' }

export const reducer = (action: Action) => {
  switch (action.type) {
    case 'signin':
      return {
        isAuth: true,
        loading: false,
        ...action.payload,
      }
    case 'signout': {
      return {
        isAuth: false,
        loading: false,
        me: defaultMe,
      }
    }
  }
}

export const initialState = {
  isAuth: false,
  loading: true,
  me: defaultMe,
}
