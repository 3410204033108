import Button from '@/components/Parts/Button/Red'
import { useModal } from '@/hooks/modal'
import useTranslation from '@/hooks/i18n'
import { MdInfo } from 'react-icons/md'

type Props = {
  errMessage: string
}

export default function Failed({ errMessage }: Props) {
  const { closeModal } = useModal()
  const { t } = useTranslation()

  const click = () => {
    closeModal()
  }

  return (
    <div className="p-8">
      <MdInfo className="text-red-400 text-8xl mx-auto mb-8" />
      <h1 className="text-xl text-gray-600 text-center font-bold">
        {t('tr_failedCreateYourService')}
      </h1>
      <p className="text-gray-600 mt-4 mb-8">{t(errMessage)}</p>
      <Button name="tr_close" onClick={click}></Button>
    </div>
  )
}
