type formatOptinsType = {
  [key: string]: {
    [key: string]: {
      [key: string]: string | number | boolean | object
    }
  }
}

const _getTimeZone = (region?: string) => {
  if (region === 'ap-southeast-1') {
    return 'Asia/Singapore'
  } else if (region === 'ap-northeast-1') {
    return 'Asia/Tokyo'
  }
  return 'UTC'
}

const timeZone = _getTimeZone(process.env.REACT_APP_REGION)

const options: formatOptinsType = {
  date: {
    en: {
      ymd: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hourCycle: 'h23',
        timeZone,
      },
      ym: {
        year: 'numeric',
        month: 'short',
        hourCycle: 'h23',
        timeZone,
      },
      ymdhms: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hourCycle: 'h23',
        timeZone,
      },
      hms: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hourCycle: 'h23',
        timeZone,
      },
    },

    ja: {
      ymd: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hourCycle: 'h23',
        timeZone,
      },
      ym: {
        year: 'numeric',
        month: 'short',
        hourCycle: 'h23',
        timeZone,
      },
      ymdhms: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hourCycle: 'h23',
        timeZone,
      },
      hms: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hourCycle: 'h23',
        timeZone,
      },
    },
  },
  number: {
    sgd: {
      currency: {
        style: 'currency',
        currency: 'SGD',
        currencyDisplay: 'symbol',
      },
    },
    usd: {
      currency: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
      },
    },
    jpy: {
      currency: {
        style: 'currency',
        currency: 'JPY',
        currencyDisplay: 'name',
      },
    },
  },
}

export default options
