export const appVersion = process.env.REACT_APP_VERSION!

export const versionHeader = {
  'x-qcp-frontend-version': appVersion,
}

export class ApiStatus {
  _statusCode: number

  constructor(statusCode: number) {
    this._statusCode = statusCode
  }

  public shouldReload() {
    return this._statusCode === 501
  }

  public reload() {
    if (this._statusCode === 501) {
      // This should not be happened. 501 error will return
      // for protecting backend.
      alert('Sorry. Please refresh your browser')
    }
  }
}
