import { NextContractDetails } from '@/types'
import LabelText from '@/components/Parts/LabelText'
import useTranslation, { useNumberFormatter } from '@/hooks/i18n'
import { FaRegEdit } from 'react-icons/fa'
import { useModal } from '@/hooks/modal'
import UpdateTenant from './UpdateTenant'
import { toDate } from '@/utils'

type Props = {
  serviceKey: string
  nextContract: NextContractDetails
  reload: () => Promise<void>
}

export default function NextContract(props: Props) {
  const { d, t } = useTranslation()
  const n = useNumberFormatter('default')
  const { openModal } = useModal()

  const editMaxUser = () => {
    openModal(
      <UpdateTenant
        serviceKey={props.serviceKey}
        maxUser={props.nextContract.quantity}
        reload={props.reload}
      />
    )
  }

  return (
    <div className="2xl:flex text-sm">
      <div>
        <LabelText title="tr_nextPeriod">
          <div>
            {d('ymd', toDate(props.nextContract.startAt))} ～{' '}
            {d('ymd', toDate(props.nextContract.endAt))}
          </div>
        </LabelText>
        <LabelText title="tr_dateOfPayment">
          <div>{d('ymd', toDate(props.nextContract.paymentDate))}</div>
        </LabelText>
        <LabelText title="tr_paymentStatus">
          {props.nextContract.paymentStatus === 'waiting' && (
            <div>{t('tr_notYet')}</div>
          )}
          {props.nextContract.paymentStatus === 'completed' && (
            <div>{t('tr_completed')}</div>
          )}
        </LabelText>
      </div>
      <div className="2xl:ml-48">
        <LabelText title="Maximum users">
          <div className="flex items-center">
            {props.nextContract.paymentStatus === 'waiting' && (
              <FaRegEdit
                className="mr-2 text-gray-700 text-xl cursor-pointer"
                onClick={editMaxUser}
                id="qcp-qtc-next-contract-icon-reg"
              />
            )}
            <div id="qcp-qtc-next-contract-div-quantity">
              {n('default', props.nextContract.quantity)}
            </div>
          </div>
        </LabelText>
      </div>
    </div>
  )
}
