import { useContext } from 'react';

import { AppContext } from '@/contexts';

/**
 * Manage the global modal state. Control open and close modal dialog.
 * The state must be handle by layer or upper layer. All component can
 * call modal dialog whenever and wherever the component wants.
 *
 * @returns isOpenModal: boolean
 * @returns children: component which you want to put into the modal
 * @returns openModal: To be true isOpenModal
 * @returns closeModal: To be false isOpenModal
 */
export function useModal() {
  const { state, dispatch } = useContext(AppContext)

  const openModal = (children: JSX.Element) =>
    dispatch({ type: 'openModal', payload: { isOpen: true, children } })

  const closeModal = () => dispatch({ type: 'closeModal' })

  if (state === undefined) {
    return { isOpenModal: false, openModal, closeModal } as const
  }

  return {
    isOpenModal: state.modal.isOpen,
    children: state.modal.children,
    openModal,
    closeModal,
  } as const
}
