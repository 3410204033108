import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserSessionData,
} from 'amazon-cognito-identity-js'

import { sessionInfo } from '@/types'
import Auth from '@aws-amplify/auth'
import { redirectToSignIn } from '@/utils'
let _jwtToken: null | string = null

function _redirectToSignInIfTokenError(
  err: any,
  reject: (resource?: any) => void
) {
  if (err.code == null && err.message == null) {
    // Whene the first login will be here
    reject(err)
  }

  // For some reason, err.code might be NotAuthorizedException and message
  // is Access/Refresh Token has been revoked. We are not sure the reason, but it is happend.
  // Anyway we just go to signin anyway in that case. Token expired will be Refresh Token has expired by
  // the way.

  redirectToSignIn()
}

export function retrieveIdToken() {
  return new Promise<string>((resolve, reject) => {
    Auth.currentSession()
      .then((res) => {
        _jwtToken = res.getIdToken().getJwtToken()
        resolve(_jwtToken)
      })
      .catch((err) => {
        _jwtToken = null
        _redirectToSignInIfTokenError(err, reject)
      })
  })
}

export function getToken() {
  return _jwtToken
}

export function saveSessionToLocalStorage(session: sessionInfo) {
  const Pool = new CognitoUserPool({
    UserPoolId: session.userPoolId,
    ClientId: session.userPoolWebClientId,
  })

  const cognitoUser = new CognitoUser({
    Username: session.userName,
    Pool,
  })

  const sessionData: ICognitoUserSessionData = {
    IdToken: new CognitoIdToken({ IdToken: session.idToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: session.accessToken }),
    RefreshToken: new CognitoRefreshToken({
      RefreshToken: session.refreshToken,
    }),
  }

  const userSession = new CognitoUserSession(sessionData)

  cognitoUser.setSignInUserSession(userSession)
}

export function clearStorages() {
  localStorage.clear()
  sessionStorage.clear()
}

export function signOut() {
  return new Promise<void>((resolve, reject) => {
    Auth.signOut({ global: true })
      .then(() => {
        clearStorages()
        resolve()
      })
      .catch((err) => {
        _redirectToSignInIfTokenError(err, reject)
      })
  })
}

export function passwordChange(oldPassword: string, newPassword: string) {
  return new Promise<void>((resolve, reject) => {
    // TODO: この書き方の方がシンプルだけどちゃんと動作を把握してから
    // Refactoringする
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword)
      })
      .then(() => resolve())
      .catch((err) => {
        // _redirectToSignInIfTokenError(err, reject)
        reject(err)
      })
  })
}

export function forgotPassword(email: string) {
  return Auth.forgotPassword(email)
}

export function forgotPasswordSubmit(
  email: string,
  code: string,
  password: string
) {
  return Auth.forgotPasswordSubmit(email, code, password)
}

export function signIn(email: string, password: string) {
  return Auth.signIn(email, password)
}

export function setJwtToken(jwtToken: string) {
  // This is for playwright tesing function
  _jwtToken = jwtToken
}
