import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useSpinner } from '@/hooks/spinner'
import { clearStorages } from '@/auth/token'
import Button from '@/components/Parts/Button/Red'
import PlainInput from '@/components/Parts/Inputs/Plain2'
import useTranslation from '@/hooks/i18n'
import { CANCELE_CUSTOMER_PORTAL } from '@/api/graphql/mutations'
import { ApolloError } from 'apollo-server-errors'
import errorHandler from '@/api/graphql/error'
import { publicPaths } from '@/utils/path'

export default function DeleteMe() {
  const [cancel] = useMutation(CANCELE_CUSTOMER_PORTAL)
  const { enableSpinner, disableSpinner } = useSpinner()
  const { t } = useTranslation()
  const [input, setInput] = useState('')
  const [error, setError] = useState('')

  const deleteMe = () => {
    if (input !== t('tr_deleteMe')) return

    enableSpinner()

    cancel()
      .then(() => {
        clearStorages()
        window.location.href = publicPaths.thanks
      })
      .catch((error: ApolloError) => {
        const result = errorHandler(error)
        setError(`${result.message}`)
        disableSpinner()
      })
  }

  return (
    <div className="p-8">
      <h1 className="text-gray-800 text-2xl mb-2">
        {t('tr_confirmationDeleteTitle')}
      </h1>
      <p className="text-gray-800 mb-8">{t('tr_confirmationDelete')}</p>
      <PlainInput
        placeholder="tr_deleteMe"
        maxLength={30}
        autoFocus={true}
        value={input}
        change={setInput}
        keydown={deleteMe}
      />
      <p className={`${!error && 'invisible'} text-red-400 my-2`}>{t(error)}</p>
      <div className="mt-8">
        <Button
          disabled={input !== t('tr_deleteMe')}
          name="tr_yes"
          onClick={deleteMe}
        />
      </div>
    </div>
  )
}
