import { IconType } from 'react-icons'

import useTranslation from '@/hooks/i18n'

interface Props {
  email: string
  textColor: string
  Icon: IconType
  iconColor: string
}

function Invitation({ email, textColor, Icon, iconColor }: Props) {
  const { t } = useTranslation()

  return (
    <div className="relative flex items-center mb-8">
      <Icon className={`absolute top-2 ${iconColor}`} />
      <div className="ml-6">
        <p
          className={`qcp-sent-email text-md ${textColor}`}
          id="qcp-sent-email"
        >
          {t('tr_sentIntivationEmail {{email}}', { email })}
        </p>
      </div>
    </div>
  )
}

export default Invitation
