import useTranslation, { useNumberFormatter } from '@/hooks/i18n'
import { MdBusiness } from 'react-icons/md'
import { FaRegEdit } from 'react-icons/fa'
import LabelText from '@/components/Parts/LabelText'
import { useModal } from '@/hooks/modal'
import IncreaseMaxUser from './IncreaseMaxUser'
import { toDate } from '@/utils'

type Props = {
  customerName: string
  customerKey: string
  billingMethod: string
  serviceKey: string
  createAt: string
  startAt: string
  expireAt: string
  status: string
  quantity: number
  option1?: string
}

export default function CurrenctContractDetails(props: Props) {
  const { d } = useTranslation()
  const n = useNumberFormatter('default')
  const { openModal } = useModal()

  const editMaxUser = () => {
    openModal(
      <IncreaseMaxUser
        serviceKey={props.serviceKey}
        maxUser={Number(props.quantity)}
      />
    )
  }

  return (
    <div className="lg:flex text-sm">
      <div>
        <LabelText title="tr_customer">
          <div className="flex items-center">
            <MdBusiness className="mr-2 text-indigo-700" />
            <p>{props.customerName}</p>
          </div>
        </LabelText>
        <LabelText title="tr_created">
          <div>{d('ymd', toDate(props.createAt))}</div>
        </LabelText>
        <LabelText title="tr_currentPeriod">
          <div>
            {d('ymd', toDate(props.startAt))} ～{' '}
            {d('ymd', toDate(props.expireAt))}
          </div>
        </LabelText>
      </div>
      <div className="ml-auto">
        <LabelText title="tr_customerID">
          <div>{props.customerKey}</div>
        </LabelText>
        {props.status !== 'trial' && props.status !== 'creating' && (
          <>
            <LabelText title="tr_billingMethod">
              <div>{props.billingMethod}</div>
            </LabelText>
            <LabelText title="tr_maximumUsers">
              <div className="flex items-center">
                <FaRegEdit
                  className="mr-2 text-gray-700 text-xl cursor-pointer"
                  onClick={editMaxUser}
                  id="qcp-qtc-current-contract-icon-reg"
                />
                <div id="qcp-qtc-currenct-contract-div-quantity">
                  {n('default', props.quantity)}
                </div>
              </div>
            </LabelText>
          </>
        )}
      </div>
    </div>
  )
}
