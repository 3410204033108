import { useNavigate } from 'react-router-dom'
import { privatePaths } from '@/utils/path'
import useTranslation from '@/hooks/i18n'

type Props = {
  id: string
  className?: string
}

const Print = ({ id, className }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const save = (e: any) => {
    e.stopPropagation()

    navigate({
      pathname: privatePaths.paymentTransaction,
      search: `?id=${encodeURIComponent(id)}`,
    })
  }

  return (
    <>
      <button
        onClick={save}
        className={`bg-red-500 text-white px-8 py-1 hover:bg-red-600 rounded-md ${className}`}
        id={id}
      >
        {t('Print')}
      </button>
    </>
  )
}

export default Print
