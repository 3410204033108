import PageTitle from '@/components/Parts/PageTitle'
import { gql, useQuery } from '@apollo/client'

interface Hello2Type {
  hello2: string
}

const HELLO_WORLD2 = gql`
  {
    hello2
  }
`

function HelloAdmin() {
  const { loading, data } = useQuery<Hello2Type>(HELLO_WORLD2)

  if (loading) {
    return <></>
  }

  // if (error !== undefined) {
  //   const authCheck = error.graphQLErrors.some(
  //     (err) =>
  //       err.extensions !== undefined && err.extensions.code === 'Unauthorized'
  //   )

  //   if (authCheck) {
  //     window.location.href = `/page-not-found`
  //   }

  //   return <></>
  // }

  console.log(data?.hello2)

  return (
    <PageTitle title="Hello Admin">
      <p>{data?.hello2}</p>
    </PageTitle>
  )
}

export default HelloAdmin
