import useTranslation, { useNumberFormatter } from '@/hooks/i18n'
import { useEffect, useState } from 'react'
import { useCheckPrice } from '../hooks'
import Loader from '@/components/Parts/Loader'
import { CalcPrice } from '@/types'
import InputNumber from '@/components/Parts/Inputs/InputNumber'
import Button from '@/components/Parts/Button/Blue'
import { useMutation } from '@apollo/client'
import { UpdateNextContract } from '@/types'
import { UPDATE_NEXT_CONTRACT } from '@/api/graphql/mutations'
import { useSpinner } from '@/hooks/spinner'
import { useModal } from '@/hooks/modal'
import { ApolloError } from 'apollo-server-errors'

type MaxUserProps = {
  serviceKey: string
  calcPrice: CalcPrice
  userNum: number
  fetch: (maxUser?: number) => Promise<void>
  reload: () => Promise<void>
  setError: (err: ApolloError) => void
}

function UpdateTenantDialog({
  userNum,
  fetch,
  reload,
  calcPrice,
  serviceKey,
  setError,
}: MaxUserProps) {
  const n = useNumberFormatter(calcPrice.currency)
  const { t } = useTranslation()
  const [maxUser, setMaxUser] = useState(userNum)
  const [loading, setLoading] = useState(false)
  const [updateNextContract] = useMutation<{
    updateNextContract: UpdateNextContract
  }>(UPDATE_NEXT_CONTRACT)
  const { enableSpinner, disableSpinner } = useSpinner()
  const { closeModal } = useModal()

  const min = Number(calcPrice.options[0].option1)
  const max = Number(calcPrice.options[0].option2)

  const verify = (num: Number) => {
    return num >= min && num <= max
  }

  const isDisable = !verify(maxUser)

  const reserve = () => {
    enableSpinner()
    updateNextContract({ variables: { serviceKey, option1: maxUser } })
      .then(() => {
        reload()
          .then(() => {
            disableSpinner()
            closeModal()
          })
          .catch(() => {
            disableSpinner()
            closeModal()
          })
      })
      .catch((err) => {
        // 基本的にはここに来る前に親コンポーネントのfetchでエラーになるはず。
        // ただし、親コンポーネントを開いた状態で放置→月をまたいでからアップデート
        // するとここにくる。かなりレアケースではある。
        setError(err)
        disableSpinner()
      })
  }

  return (
    <div>
      <p>
        {t('tr_updateTenant {{rate}} {{min}}', {
          rate: n('currency', Number(calcPrice.options[0].rate)),
          min: n('default', min),
        })}
      </p>

      <div className="mt-4 mb-2">
        <InputNumber
          title="tr_maximumUser"
          value={maxUser}
          change={(user) => {
            setMaxUser(user)
            setLoading(true)
            fetch(user).finally(() => setLoading(false))
          }}
          load={() => {
            setLoading(true)
          }}
          verify={verify}
          min={min}
          max={max}
          maxLength={7}
          id="qcp-qtc-next-contract-input-max-user"
        />
      </div>

      {loading && (
        <div className="h-24">
          <Loader />
        </div>
      )}

      {!loading && isDisable && <div className="h-24"></div>}

      {!loading && !isDisable && (
        <div className="h-24">
          <p className="text-gray-500 text-sm mb-1">{t('tr_nextPayment')}</p>
          <p
            className="text-2xl sm:text-5xl font-bold text-gray-700 mb-2"
            id="qcp-qtc-next-contract-p-total"
          >
            {n('currency', Number(calcPrice.total))}
            <span className="text-lg text-gray-500">/{t('tr_year')}</span>
          </p>
        </div>
      )}

      <div>
        <Button
          name="tr_reserve"
          disabled={isDisable}
          onClick={reserve}
          id="qcp-qtc-next-contract-button-update"
        />
      </div>
    </div>
  )
}

type Props = {
  serviceKey: string
  maxUser: number
  reload: () => Promise<void>
}

export default function UpdateTenant({ serviceKey, maxUser, reload }: Props) {
  const { fetch, price, error, setError } = useCheckPrice(
    serviceKey,
    'next_contract'
  )
  const { closeModal } = useModal()
  const { t } = useTranslation()

  useEffect(() => {
    fetch(maxUser).catch(() => {})
  }, [fetch, maxUser])

  if (error) {
    return (
      <div className="md:w-104 p-8">
        <p className="my-4 text-gray-600 text-xl font-bold">
          {t('tr_notUpdateMaxNumberUserTitle')}
        </p>
        <p className="text-gray-700">{t('tr_notUpdateMaxNumberUser')}</p>
        <div className="mt-4">
          <Button name="Got it" onClick={closeModal} />
        </div>
      </div>
    )
  }

  return (
    <div className="md:w-104 px-2 sm:p-8">
      <p className="my-4 text-gray-600 text-xl font-bold">
        {t('tr_reservationOfNumber')}
      </p>

      {!price && <Loader />}

      {price && (
        <UpdateTenantDialog
          userNum={maxUser}
          fetch={fetch}
          calcPrice={price}
          serviceKey={serviceKey}
          reload={reload}
          setError={setError}
        />
      )}
    </div>
  )
}
