export type State = {
  isOpen: boolean
}

export type Action =
  | {
      type: 'openLeftBar'
      payload: { isOpen: boolean }
    }
  | { type: 'closeLeftBar' }

export const reducer = (action: Action) => {
  switch (action.type) {
    case 'openLeftBar':
      return {
        ...action.payload,
      }
    case 'closeLeftBar': {
      return {
        isOpen: false,
      }
    }
  }
}

export const initialState = {
  isOpen: window.innerWidth > 640,
}
