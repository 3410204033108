import ServiceDetail from './ServiceDetails'
import { SERVICE_CONTRACT_DETAILS } from '@/api/graphql/queries'
import { CardForService, ServiceContractDetails } from '@/types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Loader from '@/components/Parts/Loader'
import withPageFrame from '@/components/PrivatePages'
import { publicPaths } from '@/utils/path'
import { useCallback, useEffect, useState } from 'react'
import { gqlClient } from '@/api/graphql'

function Page() {
  const [searchParams] = useSearchParams()
  const query = searchParams.get('service')
  const serviceKey = decodeURIComponent(query == null ? '' : query)
  const [serviceContractDetails, setServiceContractDetails] = useState<
    ServiceContractDetails | undefined
  >(undefined)
  const [cardForService, setCardForService] = useState<
    CardForService | undefined
  >(undefined)

  const navigate = useNavigate()

  const fetch = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      gqlClient
        .query<{
          serviceContractDetails: ServiceContractDetails
          cardForService?: CardForService
        }>({
          query: SERVICE_CONTRACT_DETAILS,
          variables: { serviceKey },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          setServiceContractDetails({ ...res.data!.serviceContractDetails })
          res.data!.cardForService !== undefined &&
            setCardForService({ ...res.data!.cardForService })
          resolve()
        })
        .catch(() => {
          navigate(publicPaths.somethingWentWrong)
          reject()
        })
    })
  }, [navigate, serviceKey])

  useEffect(() => {
    fetch().catch(() => {})
  }, [fetch])

  if (cardForService === undefined || serviceContractDetails === undefined) {
    return <Loader />
  }

  return (
    <ServiceDetail
      serviceKey={serviceKey}
      cardForService={cardForService}
      serviceDetails={serviceContractDetails}
      refetch={fetch}
    />
  )
}

export default withPageFrame('Contract details', Page)
