import { Service } from '@/types'
import QualitiaCloud from './QualitiaCloud/CardService'

type Props = {
  service: Service
  reload: () => void
}

export default function ServiceList({ service, reload }: Props) {
  if (service.serviceType) {
    return <QualitiaCloud service={service} reload={reload} />
  }

  return <div>ServiceList</div>
}
