import { useCallback, useRef, useState } from 'react'
import { CalcPrice } from '@/types'
import { gqlClient } from '@/api/graphql'
import { CALC_PRICE } from '@/api/graphql/queries'

import errorHandler from '@/api/graphql/error'
import { useNavigate } from 'react-router-dom'
import { publicPaths } from '@/utils/path'
import { ApolloError } from 'apollo-server-errors'

export function useCheckPrice(
  serviceKey: string,
  command: string = 'current_contract'
) {
  const [price, setPrice] = useState<CalcPrice | undefined>(undefined)
  const [error, enableError] = useState(false)
  const navigate = useNavigate()
  // useRefだと再レンダリングが起きたときにも同じオブジェクト返す(らしい)
  // 普通の変数にしてしまうと霊レンダリング起こるたびにクリアされてIDとして機能
  // しない可能性があるのでuseRef使っておく。
  const id = useRef(0)

  const setError = useCallback(
    (err: ApolloError) => {
      const result = errorHandler(err)

      if (
        result.extentions &&
        (result.extentions.code === 'QualitiaCloudCannotIncreaseMaxUser' ||
          result.extentions.code === 'PaymentBatchIsNotExecutedYet' ||
          result.extentions.code === 'InvalidCouponCode')
      ) {
        enableError(true)
      } else {
        navigate(publicPaths.somethingWentWrong)
        // TODO: I want to close modal if opened
      }
    },
    [navigate]
  )

  const fetch = useCallback(
    (maxUser?: number, couponCode?: string) => {
      return new Promise<void>((resolve, reject) => {
        gqlClient
          .query<{ calcPrice: CalcPrice }>({
            query: CALC_PRICE,
            variables: {
              id: String(++id.current),
              serviceKey,
              couponCode: couponCode ? couponCode : '',
              orderType: command,
              quantity: maxUser && String(maxUser),
            },
            fetchPolicy: 'no-cache',
          })
          .then((res) => {
            const { calcPrice } = res.data
            console.log(Number(calcPrice.id))
            id.current === Number(calcPrice.id) && setPrice(calcPrice)
            resolve()
          })
          .catch((err) => {
            setError(err)
            reject(err)
          })
      })
    },
    [serviceKey, command, setError]
  )

  return { fetch, price, error, setError }
}
