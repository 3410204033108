import useTranslation from '@/hooks/i18n'
import Button from '@/components/Parts/Button/Blue'
import { useMutation } from '@apollo/client'
import { Subscription } from '@/types'
import { SUBSCRIPTION } from '@/api/graphql/mutations'
import {
  AVAILABLE_CARDS,
  QUERY_SERVICE_LIST,
  INVOICE_HISTORY,
  SERVICE_CONTRACT_DETAILS,
} from '@/api/graphql/queries'
import { useSpinner } from '@/hooks/spinner'
import { useModal } from '@/hooks/modal'
import errorHandler from '@/api/graphql/error'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { publicPaths, privatePaths } from '@/utils/path'

type Props = {
  serviceKey: string
  refech: () => Promise<void>
}

export default function SubscriptionComponent({ serviceKey, refech }: Props) {
  const { t } = useTranslation()
  const { enableSpinner, disableSpinner } = useSpinner()
  const [subscription] = useMutation<{ subscription: Subscription }>(
    SUBSCRIPTION,
    {
      refetchQueries: [
        { query: QUERY_SERVICE_LIST },
        { query: AVAILABLE_CARDS },
        { query: SERVICE_CONTRACT_DETAILS, variables: { serviceKey } },
        { query: INVOICE_HISTORY, variables: { offset: 100, limit: 100 } },
      ],
      awaitRefetchQueries: true,
    }
  )
  const { closeModal } = useModal()
  const [noCards, setNoCards] = useState(false)
  const navigate = useNavigate()

  const subsc = () => {
    enableSpinner()
    subscription({ variables: { serviceKey, enable: true } })
      .then(() => {
        refech()
          .then(() => {
            disableSpinner()
            closeModal()
          })
          .catch(() => {
            disableSpinner()
            closeModal()
          })
      })
      .catch((error) => {
        const result = errorHandler(error)
        // TODO: currently, we are not sure how to handle FailedChangeStatus which can not
        // enable subscription
        if (result.extentions && result.extentions.code === 'NotFoundTheCard') {
          setNoCards(true)
        } else {
          closeModal()
          navigate(publicPaths.somethingWentWrong)
        }

        disableSpinner()
      })
  }

  const redirectTo = () => {
    navigate({
      pathname: privatePaths.serviceContractSelectOrRegister,
      search: `?id=${encodeURIComponent(serviceKey)}`,
    })
    closeModal()
  }

  return (
    <div className="px-4 py-8">
      {noCards === false && (
        <>
          <div className="text-gray-700 font-bold text-xl mb-2">
            {t('tr_activateAutoRenewalTitle')}
          </div>
          {/* <p>{t('You are enabling subscrition. Are you sure?')}</p> */}
          <div className="mt-8">
            <Button
              name="tr_yes"
              onClick={subsc}
              id="qcp-qtc-confirm-activate-button-yes"
            />
          </div>
        </>
      )}
      {noCards === true && (
        <>
          <div className="text-red-600 font-bold text-xl mb-2">
            {t('tr_notFoundCardsError')}
          </div>
          <p>{t('tr_registerSelectCardExplain')}</p>
          <div className="mt-8">
            <Button name="tr_registerSelectCard" onClick={redirectTo} />
          </div>
        </>
      )}
    </div>
  )
}
