import { useEffect, useState } from 'react'
import Button from '@/components/Parts/Button/Blue'
import InputNumber from '@/components/Parts/Inputs/InputNumber'
import useTranslation, { useNumberFormatter } from '@/hooks/i18n'
import { CalcPrice } from '@/types'
import Loader from '@/components/Parts/Loader'
import { CREATE_ORDER_SESSION } from '@/api/graphql/mutations'
import { useMutation } from '@apollo/client'
import { useSpinner } from '@/hooks/spinner'
import { serviceTypeTable } from '../helper'
import { useNavigate } from 'react-router-dom'
import { privatePaths } from '@/utils/path'
import { useModal } from '@/hooks/modal'
import { useCheckPrice } from './hooks'
import Coupon from './Coupon'

type PropsLoaded = {
  calcPrice: CalcPrice
  serviceKey: string
  fetch: (maxUser?: number, coupon?: string) => Promise<void>
}

function Subscription({ calcPrice, fetch, serviceKey }: PropsLoaded) {
  const [maxUser, setMaxUser] = useState(Number(calcPrice.options[0].quantity))
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const n = useNumberFormatter(calcPrice.currency)
  const [createOrderSession] = useMutation<{
    createOrderSession: { sessionKey: string }
  }>(CREATE_ORDER_SESSION)
  const { enableSpinner, disableSpinner } = useSpinner()
  const navigate = useNavigate()
  const { closeModal } = useModal()
  const min = Number(calcPrice.options[0].option1)
  const max = Number(calcPrice.options[0].option2)
  const initialCost = Number(calcPrice.options[1].subtotal)
  const subscriptionFee = Number(calcPrice.options[0].subtotal)
  const [coupon, setCoupon] = useState('')
  const [couponError, setCouponError] = useState(false)

  const verify = (userNum: Number) => {
    return !(userNum < min || userNum > max)
  }

  const isDisable = !verify(maxUser)

  const createOrder = () => {
    enableSpinner()
    createOrderSession({
      variables: {
        serviceType: serviceTypeTable.qualitiaCloud,
        serviceKey,
        coupon,
        option1: 'trial_to_subscription',
        option2: maxUser,
      },
    })
      .then(({ data }) => {
        navigate({
          pathname: privatePaths.sessionInvoiceForSubscription,
          search: `?id=${data!.createOrderSession.sessionKey}`,
        })
        closeModal()
        disableSpinner()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const refech = (max: number, couponCode: string) => {
    return new Promise<void>((resolve, reject) => {
      setLoading(true)
      setCouponError(false)
      fetch(max, couponCode)
        .then(() => {
          resolve()
        })
        .catch(() => {
          setCouponError(true)
          setCoupon('')
          reject()
        })
        .finally(() => setLoading(false))
    })
  }
  console.log(
    calcPrice.options[0].rate
    // n('currency', Number(calcPrice.options[0].rate))
  )
  console.log(n('default', Number(calcPrice.options[0].option1)))
  return (
    <div>
      <p>
        {t('tr_explainTrialToSubscription {{rate}} {{min}}', {
          rate: n('currency', Number(calcPrice.options[0].rate)),
          min: n('default', Number(calcPrice.options[0].option1)),
        })}
      </p>

      <div className="mt-8 mb-2">
        <InputNumber
          title="tr_maximumUser"
          value={maxUser}
          change={(user) => {
            setMaxUser(user)
            refech(user, coupon)
          }}
          load={() => {
            setLoading(true)
          }}
          verify={verify}
          min={min}
          max={max}
          maxLength={7}
          id="qcp-qtc-input-max-user"
        />
      </div>

      <div className="mt-2 mb-8">
        <Coupon
          couponCode={coupon}
          error={couponError}
          loading={loading}
          apply={(code) => {
            setCoupon(code)
            return refech(maxUser, code)
          }}
        />
      </div>

      {loading && (
        <div className="mb-14 h-28">
          <Loader />
        </div>
      )}

      {!loading && (
        <div className="mt-6 mb-14 h-28">
          <p
            className="text-2xl sm:text-5xl font-bold text-gray-700 mb-2"
            id="qcp-qtc-p-total"
          >
            {n('currency', Number(calcPrice.total))}
          </p>
          <p className="text-gray-500">
            {t('tr_subscription')}
            <span className="ml-2" id="qcp-qtc-span-users-total">
              {n('currency', subscriptionFee)}
            </span>
            /{t('tr_year')}
          </p>
          <p className="text-gray-500">
            {t('tr_initialFee')}{' '}
            <span className="ml-2" id="qcp-qtc-span-initial-fee">
              {n('currency', initialCost)}
            </span>
          </p>

          {calcPrice.options.slice(2).map((price) => {
            return (
              <p className="text-gray-500">
                {t(price.optionName)}
                <span>&nbsp;</span>
                <span>{n('currency', Number(price.subtotal))}</span>
              </p>
            )
          })}
        </div>
      )}
      {/* 
      {!loading && isDisable && (
        <div className="mb-14 h-28">
          <></>
        </div>
      )} */}

      <div>
        <Button
          name="tr_next"
          disabled={isDisable}
          onClick={createOrder}
          id="qcp-qtc-trial-to-subscription-button-next"
        />
      </div>
    </div>
  )
}

type Props = {
  serviceKey: string
  currency: string
}

export default function TrialToSubscription({ serviceKey, currency }: Props) {
  const { t } = useTranslation()
  const { fetch, price } = useCheckPrice(serviceKey, 'trial_to_subscription')

  useEffect(() => {
    fetch().catch((err) => {
      console.log(err)
    })
  }, [fetch])

  return (
    <div className="md:w-104 p-2 sm:p-8">
      <p className="my-4 text-gray-600 text-xl font-bold">
        {t('tr_activateAutoRenewal')}
      </p>

      {!price && <Loader />}

      {price && (
        <Subscription calcPrice={price} fetch={fetch} serviceKey={serviceKey} />
      )}
    </div>
  )
}
