import Button from '@/components/Parts/Button/Blue'
import useTranslation, { useNumberFormatter } from '@/hooks/i18n'
import { useMutation } from '@apollo/client'
import { BUYING_SERVICE_BY_CARD } from '@/api/graphql/mutations'
import { BuyingServiceByCardResult } from '@/types'
import { useNavigate } from 'react-router-dom'
import { useSpinner } from '@/hooks/spinner'
import { useModal } from '@/hooks/modal'
import ConfirmError from './ConfirmError'
import errorHandler from '@/api/graphql/error'
import { privatePaths } from '@/utils/path'
import {
  AVAILABLE_CARDS,
  QUERY_SERVICE_LIST,
  INVOICE_HISTORY,
} from '@/api/graphql/queries'
import { useRef } from 'react'

type Props = {
  subtotal: number
  tax: number
  total: number
  sessionId: string
  cardKey: string
  currency: string
  id: string
}

export default function OrderSummary({
  subtotal,
  tax,
  total,
  sessionId,
  cardKey,
  currency,
  id,
}: Props) {
  const [buyingServiceByCard] = useMutation<{
    buyingServiceByCard: BuyingServiceByCardResult
  }>(BUYING_SERVICE_BY_CARD, {
    refetchQueries: [
      { query: QUERY_SERVICE_LIST },
      { query: AVAILABLE_CARDS },
      { query: INVOICE_HISTORY, variables: { offset: 100, limit: 100 } },
    ],
    awaitRefetchQueries: true,
  })
  const ref = useRef(false)
  const { t } = useTranslation()
  const n = useNumberFormatter(currency)
  const { enableSpinner, disableSpinner } = useSpinner()
  const { openModal } = useModal()
  const navigate = useNavigate()

  const click = () => {
    if (ref.current) return
    ref.current = true

    enableSpinner()
    buyingServiceByCard({ variables: { sessionId, cardKey } })
      .then(() => {
        disableSpinner()
        navigate(privatePaths.purchaseSuccess)
      })
      .catch((error) => {
        const result = errorHandler(error)

        disableSpinner()
        openModal(
          <ConfirmError
            message={result.message ? result.message : 'Unkown error'}
          />
        )
      })
      .finally(() => {
        ref.current = false
      })
  }

  return (
    <>
      <h1 className="font-bold text-gray-600 mb-2 text-2xl">
        {t('tr_orderSummary')}
      </h1>
      <table>
        <tbody>
          <tr className="">
            <td className="pr-12 align-top md:w-24">{t('tr_subtotal')}</td>
            <td className="text-right md:w-48 break-all">
              {n('currency', subtotal)}
            </td>
          </tr>
          <tr className="">
            <td className="pr-12 align-top md:w-24">{t('tr_tax')}</td>
            <td className="text-right md:w-48 break-all">
              {n('currency', tax)}
            </td>
          </tr>
          <tr className="">
            <td className="font-bold pr-12 align-top text-red-600 md:w-24">
              {t('Total')}
            </td>
            <td className="font-bold text-red-600 text-right md:w-48 break-all">
              {n('currency', total)}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="mt-8 md:w-80">
        <Button
          name="tr_purchaseNow"
          onClick={click}
          disabled={cardKey.length === 0}
          id={id}
        />
      </div>
    </>
  )
}
