import { HTMLProps } from 'react'

import PlainButton from './Plain'

type Props = {
  name: string
  onClick?: () => void
} & HTMLProps<HTMLButtonElement>

function White(props: Props) {
  return (
    <PlainButton
      {...props}
      textColor="text-gray-800"
      bgColor="bg-gray-300"
      bgColorHover="bg-gray-400"
    />
  )
}

export default White
