import { useState } from 'react'

import { forgotPassword } from '@/auth/token'
import Button from '@/components/Parts/Button/Blue'
import Input from '@/components/Parts/Inputs/Plain2'
import useTranslation from '@/hooks/i18n'
import { useSpinner } from '@/hooks/spinner'

interface Props {
  sent: (email: string) => void
}

function SendVerification({ sent }: Props) {
  const [error, setError] = useState('')
  const { enableSpinner, disableSpinner } = useSpinner()

  const [email, setEmail] = useState('')
  const { t } = useTranslation()

  const sendVerificationCode = (eml: string) => {
    setError('')
    enableSpinner()
    forgotPassword(eml)
      .then(() => {
        sent(eml)
        disableSpinner()
      })
      .catch((err) => {
        disableSpinner()
        setError(err.message)
      })
  }

  return (
    <div className="md:w-96 md:border md:shadow-lg p-2 md:p-8">
      <div className="mb-8">
        <p className="text-2xl text-gray-800 font-bold mb-2">
          {t('tr_forgotPassword')}
        </p>
        <p className=" text-gray-700 font-bold">
          {t('tr_sendEmailVerificationCode')}
        </p>
      </div>
      <div className="mb-12">
        <Input
          title="Email"
          maxLength={254}
          autoFocus={true}
          value={email}
          change={(value) => setEmail(value)}
          keydown={(value) => {
            setEmail(value)
            sendVerificationCode(email)
          }}
        />
        <p className="mt-2 text-red-500 text-sm">{error}</p>
      </div>

      <Button
        name="tr_sendVerificationCode"
        onClick={() => sendVerificationCode(email)}
        disabled={email.length === 0}
      />
    </div>
  )
}

export default SendVerification
