export type State = {
  showSpinner: boolean
}

export type Action = { type: 'enableSpinner' } | { type: 'disableSpinner' }

export const reducer = (action: Action) => {
  switch (action.type) {
    case 'enableSpinner':
      return {
        showSpinner: true,
      }
    case 'disableSpinner': {
      return {
        showSpinner: false,
      }
    }
  }
}

export const initialState = {
  showSpinner: false,
}
