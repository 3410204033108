import { sessionInfo, ParamRegister } from '@/types'
import axios from './axios'
import errorHandler from './error'

function register(params: ParamRegister): Promise<sessionInfo> {
  return new Promise<sessionInfo>((resolve, reject) => {
    const path = 'register'

    axios
      .post<sessionInfo>(path, {
        ...params,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(errorHandler(path, params, err))
      })
  })
}

export default register
