import useTranslation from '@/hooks/i18n'
import { getServiceType, serviceTypeTable } from './helper'

type Props = {
  serviceKey: string
}

export default function Description({ serviceKey }: Props) {
  const serviceType = getServiceType(serviceKey)
  const { t } = useTranslation()

  if (serviceType === serviceTypeTable.serviceA) {
    return <p>{t('service_a')}</p>
  } else if (serviceType === serviceTypeTable.serviceB) {
    return <p>{t('service_a')}</p>
  } else if (serviceType.startsWith(serviceTypeTable.qualitiaCloud)) {
    return <p>{t('tr_descriptionQualitiaCloud')}</p>
  }

  return <div>Order</div>
}
