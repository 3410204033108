import useTranslation from '@/hooks/i18n'
import { MdWarning } from 'react-icons/md'
import Button from '@/components/Parts/Button/Red'
import { useModal } from '@/hooks/modal'
import DeleteMe from './DeleteMe'
import withPageFrame from '@/components/PrivatePages'

function Index() {
  const { t } = useTranslation()
  const { openModal } = useModal()

  return (
    <div className="border border-gray-300 rounded-sm">
      <div className="bg-gray-100 px-2 md:px-8 py-4 border-b border-gray-300">
        <h2 className="font-bold text-lg text-gray-700">{t('tr_cancelQTC')}</h2>
      </div>
      <div className="flex items-center px-2 md:px-8 py-1 bg-red-200">
        <MdWarning className="text-red-800" />
        <p className="text-red-800 font-bold">{t('tr_extremelyImportant')}</p>
      </div>
      <div className="px-2 md:px-8 py-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <p className="text-gray-700 md:col-span-2 mb-2">
            {t('tr_operationNotUndone')}
          </p>
          <div className="md:w-40 md:mx-auto">
            <Button
              name="tr_continue"
              onClick={() => {
                openModal(<DeleteMe />)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withPageFrame('Settings', Index)
