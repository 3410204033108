import Canceled from '@/components/Result/Canceled'
import { privatePaths } from '@/utils/path'

export default function CanceledPage() {
  return (
    <Canceled
      title="tr_paymentIncomplete"
      message="tr_checkCreditCard"
      goBackWhere="tr_goBackHome"
      goBackTo={privatePaths.home}
    />
  )
}
