import { ApiError } from '@/types'

function fatalError(path: string, params: any, err: any) {
  const trace = `Fatal error: ${path}, params or query: ${JSON.stringify(
    params
  )}, ${JSON.stringify(err)}`

  return {
    type: 'Fatal',
    retry: false,
    status: 500,
    title: 'Fatal error has been occured',
    message:
      'Sorry for the incovinient. We are fixing this problems. Please wait for a while.',
    trace,
  } as ApiError
}

export default fatalError
