import { Service } from '@/types'
import useTranslation from '@/hooks/i18n'
import ServiceStatus from '@/components/Parts/ServiceStatus'
import { useState } from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import MicrosoftEn from '@/components/Svg/EnglishgetitfromMs864X312'
import Buy from '../Buy'
import { toDate } from '@/utils'

type Props = {
  service: Service
  reload: () => void
}

function Password({ password }: { password?: string }) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="sm:flex sm:items-center text-sm">
      {showPassword && (
        <>
          <p className="mr-2 sm:w-36 break-all ">{password}</p>
          <FaRegEyeSlash
            className="cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          />
        </>
      )}
      {!showPassword && (
        <>
          <p className="mr-2 sm:w-36 break-all">******</p>

          <FaRegEye
            className="cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          />
        </>
      )}
    </div>
  )
}

export default function Created({ service }: Props) {
  const { t, d } = useTranslation()

  return (
    <div className="">
      <div className="mt-4">
        <ServiceStatus status={service.status} />
      </div>
      <table className="text-sm mt-4">
        <tbody>
          <tr>
            <td className="pr-4">{t('tr_contractPeriodTitle')}</td>
            <td>
              <span>{d('ymd', toDate(service.startAt))}</span> 〜
              <span>
                <td>{d('ymd', toDate(service.expireAt))}</td>
              </span>
            </td>
          </tr>
          <tr>
            <td className="pr-4">{t('tr_tenantCode')}</td>
            <td id="qcp-qtc-td-tenant-code">{service.option4}</td>
          </tr>
          <tr>
            <td className="pr-4" id="qcp-qtc-td-initial-id">
              {t('tr_initialID')}
            </td>
            <td className="">
              <p className="w-36 break-all">{service.option5}</p>
            </td>
          </tr>
          <tr>
            <td className="pr-4">{t('tr_initialPassword')}</td>
            <td>
              <Password password={service.option6} />
            </td>
          </tr>
        </tbody>
      </table>

      <p className="mb-2 font-bold mt-8">{t('tr_download')}</p>
      <div className="w-24  pb-4">
        <MicrosoftEn
          className="cursor-pointer"
          onClick={() => window.open(service.option7, '_blank')}
        />
      </div>

      <div
        className={`mt-4 mb-4 ${service.status === 'trial' ? '' : 'hidden'}`}
      >
        <Buy serviceKey={service.serviceKey} currency={service.currency} />
      </div>
    </div>
  )
}
