import { MdInfo } from 'react-icons/md'
import useTranslation from '@/hooks/i18n'
import Button from '@/components/Parts/Button/Blue'
import QualitiaIcon from '@/components/Parts/QualitiaIcon'
import { useNavigate } from 'react-router-dom'

type Props = {
  title: string
  message: string
  goBackWhere: string
  goBackTo: string
}

export default function Canceled(props: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="w-full">
      <div className="sm:w-120 mx-auto py-24">
        <MdInfo className="text-red-400 text-8xl mx-auto mb-8" />
        <h1 className="text-xl text-gray-600 text-center font-bold">
          {t(props.title)}
        </h1>
        <p className="text-gray-500 text-center mt-12">{t(props.message)}</p>

        <div className="w-48 mx-auto mt-24">
          <Button
            name={props.goBackWhere}
            onClick={() => navigate(props.goBackTo)}
          />
        </div>
        <div className="mt-48">
          <QualitiaIcon />
        </div>
      </div>
    </div>
  )
}
