import { AVAILABLE_CARDS_FOR_SERVICE } from '@/api/graphql/queries'
import withPageFrame from '@/components/PrivatePages'
import Loader from '@/components/Parts/Loader'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Card, CardForService } from '@/types'
import Box from '@/components/PrivatePages/Box'
import CreditCard from '@/components/CreditCard'
import useTranslation from '@/hooks/i18n'
import { selectCardStyle } from '@/utils/styles'
import RegisterCard from '@/components/RegisterCard'
import { useModal } from '@/hooks/modal'
import ConfirmChange from './ConfirmChange'
import { privatePaths } from '@/utils/path'
import { publicPaths } from '@/utils/path'
import { useCallback, useEffect, useState } from 'react'
import StripeResult from '@/components/Parts/Modal/StripeResult'
import { gqlClient } from '@/api/graphql'
import errorHandler from '@/api/graphql/error'

function Page() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const query = searchParams.get('id')
  const serviceKey = decodeURIComponent(query == null ? '' : query)
  const [availableCards, setAvailableCards] = useState<Card[]>([])
  const [cardForService, setCardForService] = useState<
    CardForService | undefined
  >(undefined)
  const [loading, setLoading] = useState(true)

  const { t } = useTranslation()
  const { openModal } = useModal()

  const fetch = useCallback(() => {
    return new Promise<void>((resolve, reject) =>
      gqlClient
        .query<{
          availableCards: Card[]
          cardForService: CardForService
        }>({
          query: AVAILABLE_CARDS_FOR_SERVICE,
          variables: { serviceKey, query },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          setAvailableCards([...res.data!.availableCards])
          setCardForService({ ...res.data!.cardForService })
          setLoading(false)
          resolve()
        })
        .catch((error) => {
          const res = errorHandler(error)
          if (res.extentions && res.extentions.code === 'InProcessing') {
            setLoading(true)
          } else {
            navigate(publicPaths.somethingWentWrong)
          }

          reject()
        })
    )
  }, [navigate])

  useEffect(() => {
    fetch().catch(() => {})
  }, [fetch])

  useEffect(() => {
    if (!loading) return

    const timer = setInterval(() => {
      if (loading) {
        fetch().catch(() => {})
      }
    }, 3 * 1000)

    return () => {
      clearInterval(timer)
    }
  }, [loading, fetch])

  if (loading) {
    return <Loader />
  }

  const select = (cardKey: string) => {
    openModal(
      <ConfirmChange
        cardKey={cardKey}
        serviceKey={serviceKey}
        refetch={fetch}
      />
    )
  }

  return (
    <>
      <RegisterCard
        redirectURL={privatePaths.serviceContractCardService}
        query={serviceKey}
      />
      <Box title="tr_avalableCards">
        <p className="mb-8">{t('tr_changinCreditCardForPayment')}</p>
        <div className="w-full grid 2xl:grid-cols-3 grid-cols-1 gap-8">
          {availableCards.map((card, index) => {
            const isSelected =
              card.cardKey === (cardForService ? cardForService.cardKey : null)
            const styles = selectCardStyle(isSelected)

            return (
              <CreditCard
                styles={styles}
                key={index}
                select={isSelected ? () => {} : select}
                isSelected={isSelected}
                cardKey={card.cardKey!}
                name={card.name!}
                expMonth={card.expMonth!}
                expYear={card.expYear!}
                lastNumber={card.lastNumber!}
                brand={card.brand!}
                cardOwner={card.cardOwner}
                services={card.services}
                processing={card.processing}
                id={index}
              />
            )
          })}
          {availableCards.length === 0 && (
            <p className="text-gray-500">{t('tr_noCardsAvailable')}</p>
          )}
        </div>
      </Box>
      <StripeResult
        successMessage="tr_successUpdateCard"
        cancelMessage="tr_cancelUpdateCard"
      />
    </>
  )
}

export default withPageFrame('Cards for service', Page)
