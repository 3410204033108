import { useEffect, useState, useCallback } from 'react'
import { INVOICE } from '@/api/graphql/queries'
import { Invoice } from '@/types'
import Loader from '@/components/Parts/Loader'
import { useNavigate } from 'react-router-dom'
import InvoiceFrame from '@/components/Invoice'
import { useSearchParams } from 'react-router-dom'
import { publicPaths } from '@/utils/path'
import { gqlClient } from '@/api/graphql'

export default function Page() {
  const [searchParams] = useSearchParams()
  const invoiceId = searchParams.get('id')
  const navigate = useNavigate()

  const [invoice, setInvoice] = useState<Invoice | undefined>(undefined)

  const fetch = useCallback(() => {
    gqlClient
      .query<{ invoice: Invoice }>({
        query: INVOICE,
        variables: {
          invoiceId: decodeURIComponent(invoiceId == null ? '' : invoiceId),
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        const { invoice } = res.data
        setInvoice(invoice)
      })
      .catch(() => {
        navigate(publicPaths.somethingWentWrong)
      })
  }, [invoiceId, navigate])

  useEffect(() => {
    fetch()
  }, [fetch])

  if (invoice === undefined) {
    return (
      <div className="w-full">
        <div className="md:w-120 md:mx-auto">
          <Loader />
        </div>
      </div>
    )
  }

  return <InvoiceFrame title="Receipt" invoice={invoice} />
}
