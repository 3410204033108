import withPageFrame from '@/components/PrivatePages'
import { useNavigate } from 'react-router-dom'
import Loader from '@/components/Parts/Loader'
import { InvoiceHistory } from '@/types'
import { INVOICE_HISTORY } from '@/api/graphql/queries'
import useTranslation, { useNumberFormatter } from '@/hooks/i18n'
import Status from '@/components/Parts/Status'
import { NEXT_MONTH_INVOICE_ID, toDate } from '@/utils'
import { privatePaths } from '@/utils/path'
import { publicPaths } from '@/utils/path'
import Print from './Print'
import { useCallback, useEffect, useState } from 'react'
import { gqlClient } from '@/api/graphql'

function History({ invoiceHistory }: { invoiceHistory: InvoiceHistory[] }) {
  const { t, d } = useTranslation()
  const n = useNumberFormatter(
    invoiceHistory.length > 0 ? invoiceHistory[0].currency : 'SGD'
  )
  const navigate = useNavigate()

  const redirectTo = (invoiceId: string) => {
    const id = encodeURIComponent(invoiceId)

    navigate({
      pathname: privatePaths.paymentTransactionDetails,
      search: `?id=${id}`,
    })
  }

  return (
    <>
      <div className="w-full overflow-x-auto">
        <table className="w-full text-sm ">
          <thead className=" border-b border-gray-200">
            <tr className="text-gray-500 text-left">
              <th className="pr-2 font-normal py-2">{t('tr_total')}</th>
              <th className="pr-2 font-normal py-2">{t('tr_status')}</th>
              <th className="pr-2 font-normal py-2">{t('tr_services')}</th>
              <th className="pr-2 font-normal py-2">{t('ID')}</th>
              <th className="pr-2 font-normal py-2">{t('tr_paymentAt')}</th>
              <th className="pr-2 font-normal py-2">{t('')}</th>
            </tr>
          </thead>
          <tbody>
            {invoiceHistory.map(
              ({ createAt, status, total, services, invoiceId }, index) => {
                return (
                  <tr
                    className={`border-b border-gray-200 cursor-pointer hover:bg-gray-100`}
                    key={index}
                    onClick={() => redirectTo(invoiceId)}
                    id={`qcp-transaction-tr-${index}`}
                  >
                    <td
                      className="pr-8 text-right py-2"
                      id={`qcp-transaction-td-total-${index}`}
                    >
                      {n('currency', Number(total))}
                    </td>
                    <td className="pr-8 py-2">
                      <Status
                        status={status}
                        id={`qcp-transaction-div-status-${index}`}
                      />
                    </td>
                    <td
                      className="pr-4 w-1/3 max-w-md py-2 truncate"
                      id={`qcp-transaction-td-services-${index}`}
                    >
                      {services}
                    </td>
                    {invoiceId.startsWith(NEXT_MONTH_INVOICE_ID) === true && (
                      <td className="pr-4 max-w-xs py-2 truncate">
                        <p className="px-4 py-1 bg-yellow-100 rounded-lg text-yellow-700 inline-block">
                          {t('tr_nextMonth')}
                        </p>
                      </td>
                    )}
                    {invoiceId.startsWith(NEXT_MONTH_INVOICE_ID) === false && (
                      <td
                        className="pr-4 max-w-xs py-2 truncate"
                        id={`qcp-transaction-td-invoice-id-${index}`}
                      >
                        {invoiceId}
                      </td>
                    )}

                    <td className="pr-4 w-1/6 py-2">
                      {d('ymdhds', toDate(createAt))}
                    </td>
                    <td className="py-2">
                      <Print id={invoiceId} />
                    </td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

function Index() {
  const navigate = useNavigate()
  const [transactions, setTransactions] = useState<
    InvoiceHistory[] | undefined
  >(undefined)

  const fetch = useCallback(() => {
    gqlClient
      .query<{
        invoiceHistory: InvoiceHistory[]
      }>({
        query: INVOICE_HISTORY,
        variables: { offset: 100, limit: 100 },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        setTransactions([...res.data!.invoiceHistory])
      })
      .catch(() => {
        navigate(publicPaths.somethingWentWrong)
      })
  }, [navigate])

  useEffect(() => {
    fetch()
  }, [fetch])

  if (transactions === undefined) {
    return <Loader />
  }

  return <History invoiceHistory={transactions} />
}

export default withPageFrame('Transaction list', Index)
