import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { retrieveIdToken } from '@/auth'
import { useAuth } from '@/hooks/auth'
import { Me, Role } from '@/types'
import { QUERY_ME } from '@/api/graphql/queries'
import { redirectToSignIn } from '@/utils'
import { SpinnerView } from '@/components/Parts/Spinner'

interface Props {
  // Protected page
  page: JSX.Element

  // Authorized role for this page
  role?: Role
}

/**
 * @param assumeRole assume role
 * @param userRole user's role which is returned by Me
 * @returns if true, the user is authorized, if false, the user is not authorized
 */
export function verifyRole(assumeRole: Role, userRole: Role): boolean {
  switch (assumeRole) {
    case 'user':
      return userRole === 'user' || userRole === 'admin'

    case 'admin':
      return userRole === 'admin'

    default:
      return false
  }
}

/**
 * Authorization users
 * @param {JSX.Element} props.page Component of the protected page by GuardUser
 */
function GuardUser({ page, role = 'user' }: Props) {
  const client = useApolloClient()
  const { signout, signin, loading, isAuth, me } = useAuth()

  useEffect(() => {
    if (!isAuth) {
      retrieveIdToken()
        .then(() => {
          return client.query<{ me: Me }>({ query: QUERY_ME })
        })
        .then(({ data }) => {
          signin(data.me)
        })
        .catch((err) => {
          signout()
        })
    }
  }, [isAuth, me])

  // return <SpinnerView />
  if (loading) {
    return <SpinnerView />
  }

  if (!isAuth || !verifyRole(role, me.role)) {
    redirectToSignIn()
    return <></>
  }

  return page
}

export default GuardUser
