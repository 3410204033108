import useTranslation from '@/hooks/i18n'
import { EventAndLogs } from '@/types'
import { toDate } from '@/utils'

type Props = {
  eventAndLogs: EventAndLogs
  isSelected: boolean
  onClick: () => void
}

export default function Summary({ eventAndLogs, isSelected, onClick }: Props) {
  const { t, d } = useTranslation()
  return (
    <div
      className={`border cursor-pointer rounded-lg p-4 mb-4 ${
        isSelected ? 'border-blue-400 shadow-md' : 'border-gray-300'
      }`}
      onClick={onClick}
    >
      <p className="text-gray-600">{t(eventAndLogs.message)}</p>
      <p className="text-gray-500 text-xs mt-1">
        {d('ymdhms', toDate(eventAndLogs.createdAt))}{' '}
      </p>
    </div>
  )
}
