import ProductList from '@/components/Services/Products'
import withPageFrame from '@/components/PrivatePages'

function Order() {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-8">
      <ProductList />
    </div>
  )
}

export default withPageFrame('Product list', Order)
