import qualitiaLogo from '@/images/logo_qualitia.png'
import { thirdPartyPaths } from '@/utils/path'
import { officialCompanyName } from '@/utils'

export default function QualitiaIcon() {
  return (
    <div className="mx-auto w-52">
      <a
        href={thirdPartyPaths.qualitia}
        className="text-center"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={qualitiaLogo}
          alt={officialCompanyName}
          className="mx-auto"
        ></img>
        <p className="text-gray-500 font-bold text-xs mt-2">
          QUALITY MAKES FUTURE
        </p>
      </a>
    </div>
  )
}
