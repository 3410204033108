import { ApolloError } from 'apollo-server-errors'
import { GraphQLError } from '@/types'

function _setGraphQLError(code: string, message: string): GraphQLError {
  return {
    extentions: { code },
    message,
  }
}

function _errorHandler(code: string): GraphQLError | undefined {
  switch (code) {
    case 'QueryParamError':
      return _setGraphQLError(code, 'Parameters are wrong')

    case 'NotFoundUser':
      return _setGraphQLError(code, 'Not found a user')

    case 'Unauthorized':
      return _setGraphQLError(code, 'Unauthorized')

    case 'FoundService':
      return _setGraphQLError(
        code,
        'There is a service. You need to wait until the expiration of the service'
      )
    case 'StripeAllRetryFailed':
      return _setGraphQLError(code, 'Something happened. Please retry later')

    case 'StripeCardErrorNoRetry':
      return _setGraphQLError(
        code,
        'Failed to payment. Currently, we support Visa, Mastercard and American Express. Please change credit card or cofirm the status to the credit card company'
      )

    case 'StripeCardErrorWithRetry':
      return _setGraphQLError(
        code,
        'Failed to payment. Currently, we support Visa, Mastercard and American Express. Please change credit card or cofirm the status to the credit card company'
      )

    case 'StripeAlreadyPaid':
      return _setGraphQLError(code, 'Already paid for this service')

    case 'StripeAlreadyCanceled':
      return _setGraphQLError(code, 'Already canceled of this order')

    case 'StripeOtherError':
      return _setGraphQLError(code, 'Something happend. Please retry later.')

    case 'NotFoundSession':
      return _setGraphQLError(code, 'Not found the session')

    case 'CardUsedByService':
      return _setGraphQLError(
        code,
        `This card used by services. You need to change cards for them.`
      )

    case 'CannotStopSubscription':
      return _setGraphQLError(
        code,
        'You cancel at least one month before the contract renewal date'
      )
    case 'NotFoundTheCard':
      return _setGraphQLError(
        code,
        'Not found registered card. Please select or register a card.'
      )
    case 'FailedChangeStatus':
      return _setGraphQLError(code, 'Can not enable subscription.')

    case 'NoServiceConfiguration':
      return _setGraphQLError(code, 'No service configuration found')

    case 'AlreadyExists':
      return _setGraphQLError(code, 'Tenant code already exists')

    case 'QualitiaCloudCannotIncreaseMaxUser':
      return _setGraphQLError(code, 'Can not increase max user in this month')

    case 'PaymentBatchIsNotExecutedYet':
      return _setGraphQLError(
        code,
        'We are processing aggregation. Please wait for a while.'
      )
    case 'AlreadyExecutedRequest':
      return _setGraphQLError(code, 'Already executed the request')

    case 'InvalidCouponCode':
      return _setGraphQLError(code, 'Invalid coupon code')

    case 'InProcessing':
      return _setGraphQLError(code, 'In processing')

    case 'DetachPaymentMethodFailed':
      return _setGraphQLError(code, 'The card is not attached to customer')

    case 'RetryLater':
      return _setGraphQLError(
        code,
        'Something happened. Sorry for inconvenient. Please retry later'
      )
  }
}

function errorHandler(error: ApolloError): GraphQLError {
  if (error.networkError) {
    return {
      extentions: { code: 'NetworkError' },
      message: 'Sorry for incovinient. Please retry later',
    }
  }

  if (error.graphQLErrors) {
    const { extensions } = error.graphQLErrors[0]
    if (extensions === undefined) {
      throw Error(`Unkown error ${JSON.stringify(error)}`)
    }

    const result = _errorHandler(extensions.code)

    if (result === undefined) {
      throw Error(`Unkown error ${JSON.stringify(error)}`)
    }

    return result
  }

  throw Error(`Unkown error ${JSON.stringify(error)}`)
}

export default errorHandler
