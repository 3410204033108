import { BrowserRouter, Route, Routes } from 'react-router-dom'

import GuardUser from '@/components/Auth/Guard'
import Layout from '@/components/Layout'
import HelloAdmin from '@/views/Private/Admin/HelloAdmin'
import Settings from '@/views/Private/Admin/Settings'
import RedirectToHome from '@/views/Private/User/RedirectToHome'
import Service from '@/views/Private/User/Service'
import ServiceContract from '@/views/Private/User/Service/Contract'
import Order from '@/views/Private/User/Order'

import PurchaseSuccess from '@/views/Private/User/Purchase/Success'
import PurchaseCanceled from '@/views/Private/User/Purchase/Canceled'
import SessionInvoice from '@/views/Private/User/Session/Invoice'
import SelectCards from '@/views/Private/User/Session/SelectCards'
import CardService from '@/views/Private/User/Service/Contract/Card/Service'
import PaymentRegisterCanceled from '@/views/Private/User/Payment/Cards/Register/Canceled'
import SelectOrRegisterCard from '@/views/Private/User/Service/Contract/Card/SelectOrRegister'
import ServiceConfiguration from '@/views/Private/User/Service/ServiceConfiguration'

import CreateUser from '@/views/Public/CreateUser'
import ForgotPassword from '@/views/Public/ForgotPassword'
import NotFound from '@/views/Public/NotFound'
import PrivacyPolicy from '@/views/Public/PrivacyPolicy'
import RedirectToNotFound from '@/views/Public/RedirectToNotFound'
import Register from '@/views/Public/Register'
import SignIn from '@/views/Public/SignIn'
import TermsAndConditions from '@/views/Public/TermsAndConditions'
import Thanks from '@/views/Public/Thanks'
import SomethingWentWrong from '@/views/Public/SomethingWentWrong'
import Receipt from '@/views/Private/User/Transaction/Receipt'
import PaymentCards from '@/views/Private/User/Payment/Cards'
import RegisterCardSuccess from '@/views/Private/User/Payment/Cards/Register'
import RegisterCardCanceled from '@/views/Private/User/Payment/Cards/Register/Canceled'
import TransactionDetails from '@/views/Private/User/Transaction/Details'
import TransactionList from '@/views/Private/User/Transaction'
import { privatePaths, adminPaths, publicPaths } from '@/utils/path'
import Reload from '@/views/Public/Reload'

function Index() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<GuardUser page={<Layout />} />}>
          <Route path="" element={<RedirectToHome />} />
          <Route path={privatePaths.home} element={<Service />} />

          <Route
            path={privatePaths.sessionInvoiceForSubscription}
            element={<SessionInvoice />}
          />
          <Route
            path={privatePaths.paymentTransactionDetails}
            element={<TransactionDetails />}
          />
          <Route path={privatePaths.sessionCards} element={<SelectCards />} />

          <Route path={privatePaths.paymentCards} element={<PaymentCards />} />
          <Route
            path={privatePaths.paymentTransactions}
            element={<TransactionList />}
          />

          <Route path={privatePaths.paymentTransaction} element={<Receipt />} />
          <Route
            path={privatePaths.paymentRegister}
            element={<RegisterCardSuccess />}
          />
          <Route
            path={privatePaths.paymentRegisterCanceled}
            element={<RegisterCardCanceled />}
          />
          <Route path={privatePaths.order} element={<Order />} />

          <Route
            path={privatePaths.serviceContractSelectOrRegister}
            element={<SelectOrRegisterCard />}
          />
          <Route
            path={privatePaths.serviceContract}
            element={<ServiceContract />}
          />
          <Route
            path={privatePaths.serviceContractCardServiceCanceled}
            element={<PaymentRegisterCanceled />}
          />
          <Route
            path={privatePaths.serviceContractCardService}
            element={<CardService />}
          />
          <Route
            path={privatePaths.serviceServiceConfiguration}
            element={<ServiceConfiguration />}
          />
          <Route
            path={privatePaths.purchaseSuccess}
            element={<PurchaseSuccess />}
          />
          <Route
            path={privatePaths.purchaseCanceled}
            element={<PurchaseCanceled />}
          />
        </Route>
        <Route
          path="/admin"
          element={<GuardUser role={'admin'} page={<Layout />} />}
        >
          <Route path="hello" element={<HelloAdmin />} />
          <Route path={adminPaths.settings} element={<Settings />} />
        </Route>
        <Route
          path={publicPaths.termsAndConditions}
          element={<TermsAndConditions />}
        />
        <Route path={publicPaths.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={publicPaths.signin} element={<SignIn />} />
        <Route path={publicPaths.forgotPassword} element={<ForgotPassword />} />
        <Route path={publicPaths.createUser} element={<CreateUser />} />
        <Route path={publicPaths.register} element={<Register />} />
        <Route path={publicPaths.pageNotFound} element={<NotFound />} />
        <Route path={publicPaths.thanks} element={<Thanks />} />
        <Route path={publicPaths.reloadOrWait} element={<Reload />} />
        <Route
          path={publicPaths.somethingWentWrong}
          element={<SomethingWentWrong />}
        />
        <Route path="*" element={<RedirectToNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Index
