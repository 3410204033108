import { AxiosError } from 'axios'
import { RestAPIError } from '@/types'
import { ApiError } from '@/types'

export const ApiUserAlreadyExists = 1
export const ApiFailedVerifyPassword = 2
export const ApiFailedVerifyPhone = 3
export const ApiFailedVerifyZip = 4
export const ApiFailedVerifyOrg = 5
export const ApiFailedVerifyFullName = 6
export const ApiFailedVerifyAddress1 = 7
export const ApiFailedVerifyAddress2 = 8
export const ApiFailedVerifyAddress3 = 9
export const ApiFailedVerifyDepartment = 10
export const ApiFailedVerifyTitle = 11
export const ApiFailedVerifyMemo = 12
export const ApiFailedVerifyCountry = 13
export const ApiFailedVerifyEmail = 14

type DescribeError = {
  title: string
  message: string
  trace?: string
}

function _createMessage(err: RestAPIError): DescribeError {
  switch (err.subStatusCode) {
    case ApiUserAlreadyExists:
      return {
        title: 'The email already exists',
        message: 'Please register the other email address',
      }
    case ApiFailedVerifyPassword:
      return {
        title: 'Password is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyPhone:
      return {
        title: 'Phone number is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyZip:
      return {
        title: 'Zip is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyOrg:
      return {
        title: 'Organization is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyFullName:
      return {
        title: 'Full name is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyAddress1:
      return {
        title: 'Address 1 is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyAddress2:
      return {
        title: 'Address 2 is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyAddress3:
      return {
        title: 'Address 3 is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyDepartment:
      return {
        title: 'Department is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyTitle:
      return {
        title: 'Title is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyMemo:
      return {
        title: 'Memo is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyCountry:
      return {
        title: 'Country is invalid',
        message: err.detail,
      }
    case ApiFailedVerifyEmail:
      return {
        title: 'Failed validation',
        message: err.detail,
      }
  }

  return {
    title: 'Unkown error',
    message: 'Sorry for the incovinient. We are fixing this problem.',
    trace: JSON.stringify(err),
  }
}

function kownError(err: AxiosError<RestAPIError>) {
  const response = err.response!.data

  const { title, message, trace } = _createMessage(err.response!.data)

  return {
    type: 'Kown',
    retry: false,
    status: 422,
    subCode: response.subStatusCode,
    title,
    message,
    trace,
  } as ApiError
}

export default kownError
