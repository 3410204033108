export type State = {
  show: boolean
  message: string
}

export type Action =
  | { type: 'setTooltip'; message: string }
  | { type: 'clearTooltip' }

export const reducer = (action: Action) => {
  switch (action.type) {
    case 'setTooltip': {
      return {
        show: true,
        message: action.message,
      }
    }
    case 'clearTooltip': {
      return {
        show: false,
        message: '',
      }
    }
  }
}

export const initialState = {
  show: false,
  message: '',
}
