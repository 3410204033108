import { useMutation } from '@apollo/client'
import { BUYING_SERVICE } from '@/api/graphql/mutations'
import Button from '@/components/Parts/Button/Blue'
import { useSpinner } from '@/hooks/spinner'
import { useNavigate } from 'react-router-dom'
import { stripeRedirect } from '@/utils/stripe'
import { BuyingServiceResult } from '@/types'
import { privatePaths } from '@/utils/path'
import { publicPaths } from '@/utils/path'
import { useModal } from '@/hooks/modal'
import ConfirmRedirect from '@/components/ConfirmRedirectToStripe'

interface Props {
  sessionId: string
}

function ConfirmModal({ sessionId }: Props) {
  return (
    <div className="p-8 max-w-md">
      <ConfirmRedirect message={'tr_replaceCreditCard'} />

      <div className="mt-12">
        <Button
          name="tr_register"
          onClick={() => stripeRedirect(sessionId)}
          id="qcp-session-invoice-button-to-stripe"
        />
      </div>
    </div>
  )
}

export default function JumpToStripe({ sessionId }: Props) {
  const [buyingService] = useMutation<{ buyingService: BuyingServiceResult }>(
    BUYING_SERVICE
  )
  const { enableSpinner, disableSpinner } = useSpinner()
  const navigate = useNavigate()
  const { openModal } = useModal()

  const _redirectNext = (result: BuyingServiceResult) => {
    disableSpinner()
    const stripeSessionId = result.stripeSessionId
    if (stripeSessionId == null) {
      navigate({
        pathname: privatePaths.sessionCards,
        search: `?id=${encodeURIComponent(sessionId)}`,
      })
    } else {
      openModal(<ConfirmModal sessionId={stripeSessionId} />)
    }
  }

  const next = () => {
    enableSpinner()
    buyingService({
      variables: { sessionId, cancelRedirectUrl: window.location.pathname },
    })
      .then(({ data }) => {
        _redirectNext(data!.buyingService)
      })
      .catch(() => {
        // In this case, something happened in backend.
        // It might be serious trouble, so purchasing should not
        // be processed.
        navigate(publicPaths.somethingWentWrong)
        disableSpinner()
      })
  }

  return (
    <div className="w-full md:w-120 mx-auto bg-gray-50 py-4 px-1">
      <div className="md:w-48 md:ml-auto">
        <Button name="tr_next" onClick={next} id="qcp-session-invoice-next" />
      </div>
    </div>
  )
}
