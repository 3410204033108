import { useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'

import createUser from '@/api/restApi/createUser'
import Button from '@/components/Parts/Button/Blue'
import Input from '@/components/Parts/Inputs/Plain2'
import Anchor from '@/components/Parts/Text/Anchor'
import useTranslation from '@/hooks/i18n'
import Loader from '@/components/Parts/Loader'
import Invitation from './Invitation'
import { publicPaths } from '@/utils/path'
import { useSearchParams } from 'react-router-dom'

function CreateUser() {
  const [searchParams] = useSearchParams()
  const service = searchParams.get('service')
  const [sentEmail, setSentEmail] = useState('')
  const [email, setEmail] = useState('')
  const [start, setStart] = useState(false)
  const [error, setError] = useState('')
  const { t } = useTranslation()

  const create = (eml: string) => {
    setError('')
    setSentEmail('')
    setStart(true)

    createUser(eml, service == null ? undefined : service)
      .then(() => {
        setStart(false)
        setEmail('')
        setSentEmail(eml)
      })
      .catch((err) => {
        setError(`${err.title}: ${err.message}`)
        setStart(false)
      })
  }

  return (
    <div className="p-2 lg:pt-4 lg:px-8">
      <div className="lg:border border-gray-100 lg:shadow-md lg:py-6 lg:px-12 lg:rounded">
        <h1 className="text-gray-800 font-bold text-2xl mb-12">
          {t('tr_createAccount')}
        </h1>

        <div className="mb-14">
          {sentEmail.length === 0 && (
            <Input
              autoFocus={true}
              disabled={start}
              title="tr_email"
              value={email}
              change={setEmail}
              keydown={(email) => {
                setEmail(email)
                create(email)
              }}
              maxLength={254}
              id={'qcp-create-user-input-email'}
            />
          )}
          {start && <Loader title="tr_sendEmailInvitaion" />}
          {error.length > 0 && (
            <p className="text-red-500 text-sm mt-2">{error}</p>
          )}

          <div
            className={`${
              sentEmail.length > 0 ? 'qcp-fade-in mt-2' : 'hidden'
            }`}
          >
            <Invitation
              email={sentEmail}
              textColor="text-green-500"
              Icon={FaCheckCircle}
              iconColor="text-green-500"
            />
          </div>
        </div>

        <div className="mt-8 text-right">
          {sentEmail.length === 0 && (
            <Button
              disabled={start || email.length === 0}
              name="tr_sendEmailInvitaion"
              onClick={() => create(email)}
            />
          )}
        </div>
      </div>
      <Anchor
        text="tr_doYouHaveAccount"
        to={publicPaths.signin}
        label="tr_signIn"
      />
    </div>
  )
}

export default CreateUser
