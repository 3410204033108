import { AVAILABLE_CARDS } from '@/api/graphql/queries'
import { Card, CardStyle } from '@/types'
import { useNavigate } from 'react-router-dom'
import withPageFrame from '@/components/PrivatePages'
import CreditCard from '@/components/CreditCard'
import Loader from '@/components/Parts/Loader'
import Box from '@/components/PrivatePages/Box'
import RegisterCard from '@/components/RegisterCard'
import useTranslation from '@/hooks/i18n'
import { publicPaths } from '@/utils/path'
import { useCallback, useEffect, useState } from 'react'
import { gqlClient } from '@/api/graphql'
import StripeResult from '@/components/Parts/Modal/StripeResult'
import errorHandler from '@/api/graphql/error'

function Page() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [cards, setCards] = useState<Card[]>([])
  const [loading, setLoading] = useState(true)

  const fetch = useCallback(() => {
    setLoading(true)
    return new Promise<void>((resolve, reject) =>
      gqlClient
        .query<{
          availableCards: Card[]
        }>({
          query: AVAILABLE_CARDS,
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          setCards([...res.data!.availableCards])
          setLoading(false)
          resolve()
        })
        .catch((error) => {
          const res = errorHandler(error)
          if (res.extentions && res.extentions.code === 'InProcessing') {
            setLoading(true)
          } else {
            navigate(publicPaths.somethingWentWrong)
          }

          reject()
        })
    )
  }, [navigate])

  useEffect(() => {
    fetch().catch(() => {})
  }, [fetch])

  useEffect(() => {
    if (!loading) return

    const timer = setInterval(() => {
      if (loading) {
        fetch().catch(() => {})
      }
    }, 3 * 1000)

    return () => {
      clearInterval(timer)
    }
  }, [loading, fetch])

  if (loading) {
    return <Loader />
  }

  const styles = {
    border: 'py-4 px-8 border border-gray-300 shadow-md rounded-lg',
    title: 'text-gray-600 font-bold pr-4 align-top w-24',
    value: 'pb-2 break-all',
  } as CardStyle

  return (
    <>
      <RegisterCard redirectURL={window.location.pathname} />
      <Box title="tr_avalableCards">
        <div className="w-full grid 2xl:grid-cols-2 grid-cols-1 gap-4">
          {cards.map((card, index) => {
            return (
              <CreditCard
                styles={styles}
                key={index}
                select={() => {}}
                isSelected={false}
                cardKey={card.cardKey!}
                name={card.name!}
                expMonth={card.expMonth!}
                expYear={card.expYear!}
                lastNumber={card.lastNumber!}
                brand={card.brand!}
                cardOwner={card.cardOwner}
                services={card.services}
                refetch={fetch}
                processing={card.processing}
                id={index}
              />
            )
          })}
          {cards.length === 0 && (
            <p className="text-gray-500">{t('tr_noCardsAvailable')}</p>
          )}
        </div>
      </Box>
      <StripeResult
        successMessage="tr_successRegisterCard"
        cancelMessage="tr_cancelRegisterCard"
      />
    </>
  )
}

export default withPageFrame('Cards', Page)
