import useTranslation, { useNumberFormatter } from '@/hooks/i18n'
import Status from '@/components/Parts/Status'
import { FaMoneyBill } from 'react-icons/fa'
import Print from '../Print'
import { NEXT_MONTH_INVOICE_ID, toDate } from '@/utils'

type Props = {
  total: number
  status: string
  paymentAt: string
  currency: string
  paymentId: string
}

export default function Summary({
  total,
  status,
  paymentAt,
  currency,
  paymentId,
}: Props) {
  const { t, d } = useTranslation()
  const n = useNumberFormatter(currency)

  return (
    <>
      <div className="sm:flex sm:items-end mb-2">
        <div>
          <div className="flex items-center text-sm text-gray-500">
            <FaMoneyBill />
            <p className="ml-2 ">{t('tr_totalAmount')}</p>
          </div>
          <h1
            className="text-gray-800 text-3xl font-bold"
            id="qcp-transaction-details-h1-total"
          >
            {n('currency', total)}
          </h1>
        </div>
        {paymentId.startsWith(NEXT_MONTH_INVOICE_ID) === true && (
          <div className="sm:ml-auto">
            <p className="text-gray-500">{t('tr_draftStatus')}</p>
          </div>
        )}
        {paymentId.startsWith(NEXT_MONTH_INVOICE_ID) === false && (
          <div className="sm:ml-auto">
            <p
              className="text-gray-500"
              id="qcp-transaction-details-p-payment-id"
            >
              {paymentId}
            </p>
          </div>
        )}
      </div>
      <div className="w-full border-t border-gray-300">
        <div className="sm:flex sm:items-center mt-2 text-sm text-gray-600">
          <div className="md:border-r md:border-gray-200 md:pr-8 mb-2">
            <p className="mb-1 ">{t('tr_status')}</p>
            <Status status={status} id="qcp-transaction-details-div-status" />
          </div>
          <div className="text-sm mb-2 md:px-8 md:border-r md:border-gray-200">
            <p className="mb-1 ">{t('tr_paymentAt')}</p>
            <p className="text-gray-900">{d('ymdhms', toDate(paymentAt))}</p>
          </div>
          <div className="sm:ml-auto">
            <Print id={paymentId} className="font-bold py-2" />
          </div>
        </div>
      </div>
    </>
  )
}
