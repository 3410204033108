import '@/auth'
import '@/styles/style.scss'
import '@/styles/template.scss'

import React from 'react'
import ReactDOM from 'react-dom'

import { gqlClient } from '@/api/graphql'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from './App'
import reportWebVitals from './reportWebVitals'

Sentry.init({
  dsn:
    process.env.REACT_APP_SENTRY_DSN === undefined
      ? ''
      : process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  release:
    process.env.REACT_APP_GITHUB_SHA === undefined
      ? 'debug'
      : process.env.REACT_APP_GITHUB_SHA,
})

process.env.NODE_ENV !== 'development' && (console.log = () => {})

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <ApolloProvider client={gqlClient}>
        <App />
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
