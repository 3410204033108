import QualitiaCloud from './QualitiaCloud/CurrentContractDetails'
import { getServiceType, serviceTypeTable } from './helper'

type Props = {
  customerName: string
  customerKey: string
  billingMethod: string
  createAt: string
  startAt: string
  expireAt: string
  status: string
  serviceKey: string
  quantity: number
  option1?: string
}

export default function CurrentContractDetails(props: Props) {
  const serviceType = getServiceType(props.serviceKey)

  if (serviceType.startsWith(serviceTypeTable.qualitiaCloud)) {
    return <QualitiaCloud {...props} />
  }

  return <div>CurrentContractDetails</div>
}
