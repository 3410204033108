import { publicPaths } from './path'

export const NEXT_MONTH_INVOICE_ID = 'next_month_invoice'

export function redirectToSignIn() {
  redirectTo(publicPaths.signin)
}

export function redirectTo(to: string) {
  const uri = encodeURIComponent(
    window.location.pathname + window.location.search || '/home'
  )
  // To clear data in memory, we should use window.location.href to signout
  window.location.href = `${to}?redirect=${uri}`
}

export function modify(d: string) {
  if (d == null) return d

  return d.replace(/-/g, '/').replace(/T/g, ' ').replace('Z', '').split('.')[0]
}

export function toDate(d: string) {
  const agent = window.navigator.userAgent.toLowerCase()

  if (agent.indexOf('safari') > -1 && agent.indexOf('chrome') === -1) {
    // Safari can not handl '-' in Date format
    return new Date(modify(d))
  }

  return new Date(d)
}

export const officialCompanyName = 'QUALITIA PTE. LTD.'
export const contactUsEmail = 'portal-support@qualitia.sg'
