import ClickOutside from '@/components/ClickOutside'
import useTranslation from '@/hooks/i18n'

interface Props {
  title?: string
  selected: string
  onSelect: (data: string) => void
  list: string[]
  id?: string
}

function Scroll({ title, selected, list, onSelect, id }: Props) {
  const { t } = useTranslation()

  return (
    <div>
      <ClickOutside clicked={() => {}}>
        {title && (
          <label id="listbox-label" className="qcp-input-title mb-3">
            {t(title)}
          </label>
        )}

        <div className="mt-2">
          <div className="mb-3">
            <select
              onChange={(event) => {
                onSelect(event.target.value)
              }}
              className="form-select appearance-none block w-full px-1 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id={id}
            >
              <option hidden>{t('tr_select')}</option>
              {list.map((data) => {
                return (
                  <option value={data} key={data} selected={data === selected}>
                    {data}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </ClickOutside>
    </div>
  )
}

export default Scroll
