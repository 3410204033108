import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  resources: {
    ja: {
      translations: require('@/hooks/i18n/locales/ja.json'),
    },
    en: {
      translations: require('@/hooks/i18n/locales/en.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
    useSuspense: false,
  },
})

i18next.languages = ['ja', 'en']

export default i18next
