import { FaCheck } from 'react-icons/fa';

import useTranslation from '@/hooks/i18n';

interface Props {
  title: string
  content: string
}

function DescribeFrame({ title, content }: Props) {
  const { t } = useTranslation()
  return (
    <div className="relative flex items-center mb-8">
      <FaCheck className="absolute top-1 text-green-400" />
      <div className="ml-8">
        <p className="font-bold">{t(title)}</p>
        <p className="text-sm mt-1">{t(content)}</p>
      </div>
    </div>
  )
}

export default DescribeFrame
