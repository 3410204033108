import useTranslation from '@/hooks/i18n'
import { Organization } from '@/types'
import { countryIsoRevertedList } from '@/utils/countries'

interface Props {
  direction: string
  invoiceOrganization: Organization
}

function InvoiceOrganization({ direction, invoiceOrganization }: Props) {
  const { t } = useTranslation()
  return (
    <div className="border-indigo-400 px-2 md:px-8 rounded-sm">
      <h1 className="text-gray-500 font-bold ">{t(direction)}</h1>
      <p className="text-gray-800 font-bold text-lg">
        {invoiceOrganization.name}
      </p>
      <div className="text-sm">
        <table>
          <tbody>
            <tr>
              <td className="pr-8 align-top">{t('tr_country')}</td>
              <td className="pb-2">
                {t(countryIsoRevertedList[invoiceOrganization.country])}
              </td>
            </tr>
            <tr>
              <td className="pr-8 align-top">{t('tr_zip')}</td>
              <td className="pb-2">{invoiceOrganization.zip}</td>
            </tr>
            <tr>
              <td className="pr-8 align-top">{t('tr_address')}</td>
              <td className="pb-2">
                {invoiceOrganization.address1}
                {invoiceOrganization.address2.length > 0 && (
                  <>
                    <br />
                    {invoiceOrganization.address2}
                  </>
                )}
                {invoiceOrganization.address3.length > 0 && (
                  <>
                    <br />
                    {invoiceOrganization.address3}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="pr-8 align-top">{t('tr_phone')}</td>
              <td className="pb-2">{invoiceOrganization.phone}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default InvoiceOrganization
