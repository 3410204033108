import { GrClose } from 'react-icons/gr'

import Overlay from '@/components/Parts/Overlay'
import ClickOutside from '@/components/ClickOutside'
import { useModal } from '@/hooks/modal'
import { useHeight } from '@/hooks/height'
import { useRef } from 'react'

const Modal = () => {
  const { isOpenModal, children, closeModal } = useModal()
  const ref = useRef<HTMLDivElement>(null)
  const { height } = useHeight()

  const close = () => {
    closeModal()
  }

  return (
    <>
      {isOpenModal && (
        <Overlay click={() => {}}>
          <ClickOutside clicked={close}>
            <div
              className="h-auto p-1 m-0 text-left bg-white rounded shadow-xl z-50 overflow-auto"
              ref={ref}
              style={{
                maxHeight: height,
              }}
            >
              <div className="m-2 ">
                <GrClose
                  className="ml-auto cursor-pointer m-2"
                  onClick={close}
                  id="qcp-modal-close"
                />
                {children}
              </div>
            </div>
          </ClickOutside>
        </Overlay>
      )}
    </>
  )
}

export default Modal
