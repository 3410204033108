import { FaCheckCircle } from 'react-icons/fa'

import Button from '@/components/Parts/Button/Blue'
import IconText from '@/components/Parts/Text/IconText'
import { useModal } from '@/hooks/modal'

function Success() {
  const { closeModal } = useModal()

  return (
    <div className="px-8 pb-4">
      <div className="qcp-fade-in">
        <IconText
          Icon={FaCheckCircle}
          iconColor="text-green-500"
          text="tr_changePassword"
          textColor="text-green-500"
        />
      </div>
      <div className="mt-8 mb-8">
        <Button name="tr_close" onClick={() => closeModal()} />
      </div>
    </div>
  )
}

export default Success
