import { useLeftBar } from '@/hooks/leftBar'

import SideNav from './SideNav'

type Props = {
  height: number
  // refNavBurger: React.RefObject<HTMLDivElement>
}

const LeftBar = ({ height }: Props) => {
  const { isOpenLeftBar } = useLeftBar()

  return (
    <>
      {isOpenLeftBar && (
        <div
          className={
            'fixed bg-white border-r shadow-sm w-full sm:w-56 z-40 pt-12 slidein print:hidden'
          }
          style={{ height: `${height}px` }}
        >
          <SideNav />
        </div>
      )}
    </>
  )
}

export default LeftBar
