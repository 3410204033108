import BlueButton from '@/components/Parts/Button/Blue'
import RedButton from '@/components/Parts/Button/Red'
import { useModal } from '@/hooks/modal'
import Cancel from '../Confirm/Cancel'
import Subscription from '../Confirm/Subscription'
import { SERVICE_STATUS } from '../helper'
import Buy from './Buy'

type Props = {
  status: string
  serviceKey: string
  currency: string
  refech: () => Promise<void>
}

export default function ServiceStatusComponent(props: Props) {
  const { openModal } = useModal()

  const confirmCancel = () => {
    openModal(<Cancel serviceKey={props.serviceKey} refech={props.refech} />)
  }

  const confirmSubscription = () => {
    openModal(
      <Subscription serviceKey={props.serviceKey} refech={props.refech} />
    )
  }

  if (props.status === SERVICE_STATUS.TRIAL) {
    return <Buy serviceKey={props.serviceKey} currency={props.currency} />
  }

  return (
    <>
      {props.status === SERVICE_STATUS.CANCELED && (
        <BlueButton
          name="tr_activateQTC"
          onClick={confirmSubscription}
          id="qcp-qtc-button-activate"
        />
      )}
      {props.status === SERVICE_STATUS.SUBSCRIPTION && (
        <RedButton
          name="tr_cancel"
          onClick={confirmCancel}
          id="qcp-qtc-button-cancel"
        />
      )}
    </>
  )
}
