import useTranslation from '@/hooks/i18n'
import Microsoft from '@/components/Svg/EnglishgetitfromMs864X312'
import { Service } from '@/types'
// import laptop from '@/images/g176.png'

type Props = {
  service: Service
  laptop: JSX.Element
}

export default function Success({ service, laptop }: Props) {
  const { t } = useTranslation()

  return (
    <div
      className="grid xl:grid-cols-2 gap-4 h-110"
      id="qcp-qtc-dialog-download-app"
    >
      <div className="flex items-center justify-center w-full">
        <div className="">
          <div className="text-center px-8 ">
            <p className="text-gray-800 font-bold text-4xl sm:text-6xl mb-1">
              {t('tr_downalodUpperCase')}
            </p>
            <p className="text-gray-600 font-bold text-xl sm:text-4xl mb-12">
              {t('tr_ourApp')}
            </p>

            <div className="w-full ">
              <div className="w-48 mx-auto">
                <Microsoft
                  className="cursor-pointer"
                  onClick={() => window.open(service.option7, '_blank')}
                />
              </div>
            </div>
            <div className="w-full my-12">
              <table className="mx-auto text-left text-lg">
                <tbody>
                  <tr>
                    <td className="pr-4 text-gray-600 font-bold">
                      {t('tr_tenantCode')}
                    </td>
                    <td className="break-all" id="qcp-qtc-td-tenant-code">
                      {service.option4}
                    </td>
                  </tr>
                  <tr>
                    <td className="pr-4 text-gray-600 font-bold">
                      {t('tr_initialID')}
                    </td>
                    <td className="break-all">{service.option5}</td>
                  </tr>
                  <tr>
                    <td className="pr-4 text-gray-600 font-bold">
                      {t('tr_initialPassword')}
                    </td>
                    <td className="break-all">{service.option6}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden xl:flex">
        <div className="flex items-center justify-center w-full">{laptop}</div>
      </div>
    </div>
  )
}
