import { ServiceContractDetails, CardForService } from '@/types'
import Box from './Box'
import ServiceStatus from './ServiceStatus'
import SubscriptionDetails from '@/components/Services/CurrentContractDetails'
import CreditCard from './Cards'
import Description from '@/components/Services/Description'
import NextContract from '@/components/Services/NextContractDetails'
import useTranslation from '@/hooks/i18n'

type Props = {
  serviceKey: string
  serviceDetails: ServiceContractDetails
  cardForService?: CardForService
  refetch: () => Promise<void>
}

export default function Index({
  serviceKey,
  serviceDetails,
  cardForService,
  refetch,
}: Props) {
  const { t } = useTranslation()

  return (
    <div className="mt-8">
      <Box title={serviceDetails.serviceName}>
        <ServiceStatus
          serviceKey={serviceKey}
          status={serviceDetails.status}
          purchaseAt={serviceDetails.purchaseAt}
          purchasedBy={serviceDetails.purchasedBy}
          lastAvailableDate={serviceDetails.lastAvailableDate}
          currency={serviceDetails.currency}
          refech={refetch}
        />
      </Box>

      <Box title="tr_currentContractDetails">
        <SubscriptionDetails
          customerName={serviceDetails.customerName}
          customerKey={serviceDetails.customerKey}
          billingMethod={serviceDetails.billingMethod}
          createAt={serviceDetails.createAt}
          startAt={serviceDetails.startAt}
          expireAt={serviceDetails.expireAt}
          status={serviceDetails.status}
          serviceKey={serviceKey}
          quantity={serviceDetails.quantity}
          option1={serviceDetails.option1}
        />
      </Box>

      <Box title="tr_nextContractDetails">
        <NextContract
          serviceKey={serviceKey}
          nextContract={serviceDetails.nextContract}
          reload={refetch}
        />
      </Box>

      {serviceDetails.status === 'subscription' && cardForService && (
        <Box title="tr_creditCard">
          <CreditCard serviceKey={serviceKey} cardForService={cardForService} />
        </Box>
      )}

      <Box title="tr_description">
        <Description serviceKey={serviceKey} />
      </Box>
      <Box title="tr_logs">
        <p className="text-sm text-gray-600">{t('tr_noLogs')}</p>
      </Box>
    </div>
  )
}
