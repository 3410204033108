import CheckBox from '@/components/Parts/CheckBox'
import useTranslate from '@/hooks/i18n'

interface Props {
  url: string
  checked: boolean
  setChecked: (checked: boolean) => void
}

function Agreement({ url, checked, setChecked }: Props) {
  const { t } = useTranslate()

  return (
    <CheckBox
      checked={checked}
      onClick={() => {
        setChecked(!checked)
      }}
      id="qcp-checkbox-agreement"
    >
      <p className="font-bold text-sm">
        {t('tr_agree')}
        <a
          href={url}
          className="ml-2 text-blue-400 text-sm font-bold cursor-pointer hover:text-blue-500"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('tr_termsAndConditions')}
        </a>
      </p>
    </CheckBox>
  )
}

export default Agreement
