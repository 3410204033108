import QCLogo from '../QCLogo'
import { Service } from '@/types'
import { ReactNode } from 'react'
import useTranslation from '@/hooks/i18n'
import Loader from '@/components/Parts/CardLoader'
import Footer from './Footer'

type Props = {
  service: Service
  children: ReactNode
}

export default function Frame({ service, children }: Props) {
  const { t } = useTranslation()

  return (
    <div className="relative border border-gray-300 rounded-md shadow-md sm:min-w-min sm:max-w-md mx-auto">
      <div className="p-8">
        <QCLogo className="w-32 mx-auto" />
      </div>
      <div className="border-t border-gray-200 px-2 sm:px-8 pb-12">
        <div className="mt-4">
          <p className="text-gray-700">
            {t('tr_descriptionQualitiaCloudShortVer')}
          </p>
        </div>
        {children}
      </div>

      <div className="absolute bottom-4 right-8 flex w-full ">
        <Footer service={service} />
      </div>

      {service.processing && <Loader />}
    </div>
  )
}
