import { useContext } from 'react';

import { AppContext } from '@/contexts';

/**
 * Manage the global error state
 * This state is used for telling error to whole system.
 * The error state will be handle by Layout or upper layer.
 *
 * @returns errors: Array of id, status, message, params which depends on status
 * @returns setErr: args is message: string, status?: number, params?: any. Set error state
 * @returns removeErr: args is id. Remove the error state
 */
export function useErr() {
  const { state, dispatch } = useContext(AppContext)

  const setErr = (message: string, status?: number, params?: any) =>
    dispatch({ type: 'addErr', status, message, params })
  const removeErr = (id: string) => dispatch({ type: 'removeErr', id })

  if (state === undefined || state.error === undefined) {
    return { errors: [], setErr, removeErr }
  }

  return { errors: state.error.errors, setErr, removeErr }
}
