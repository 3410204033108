import { ChangeEvent, useState, HTMLProps } from 'react'

import useTranslation from '@/hooks/i18n'

type Props = {
  title?: string
  validate?: (text: string) => string
  change: (value: string) => void
  keydown?: (value: string) => void
  id?: string
} & HTMLProps<HTMLInputElement>

export default function Plain2(props: Props) {
  const { title, validate, change, keydown, placeholder, ...rest } = props
  const [error, setError] = useState('')
  const { t } = useTranslation()

  const onchange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const userInput = event.target.value
    if (validate) {
      setError(validate(userInput))
    }
    if (event.target.validity.valid) {
      change(userInput)
    }
  }

  const onkeydown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      keydown != null && keydown(event.target.value)
    }
  }

  return (
    <div className="w-full">
      {title && (
        <p className={`qcp-input-title ${error.length > 0 && 'text-red-500'}`}>
          {t(title)}
        </p>
      )}
      <input
        {...rest}
        placeholder={t(placeholder === undefined ? '' : placeholder)}
        onChange={onchange}
        onKeyDown={onkeydown}
        className={`qcp-input ${error.length > 0 && 'border-red-500'}`}
        id={props.id && props.id}
      />
      <p className="text-red-500 mt-1 text-sm">{t(error)}</p>
    </div>
  )
}
