import NotifyFrame from '@/components/PublicPages/NotifyFrame'

export default function Thanks() {
  return (
    <NotifyFrame
      title="tr_thanks"
      subTitle="tr_forwardToServing"
      titleColor="text-red-500"
    />
  )
}
