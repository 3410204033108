import useTranslation from '@/hooks/i18n'
import { EventAndLogs } from '@/types'
import Status from './Status'
import { toDate } from '@/utils'

type Props = {
  event: EventAndLogs
}

export default function PaymentSuccess({ event }: Props) {
  const { d, t } = useTranslation()
  console.log(event.createdAt)
  return (
    <div className="text-gray-700">
      <div className="my-4">
        <Status
          status={event.eventType}
          id="qcp-transaction-details-event-and-logs-status"
        />
      </div>

      <table className="text-sm">
        <tbody>
          <tr>
            <td className="pr-4">ID</td>
            <td>{event.eventId}</td>
          </tr>
          <tr>
            <td className="pr-4">Date</td>
            <td>{d('ymdhms', toDate(event.createdAt))}</td>
          </tr>
        </tbody>
      </table>

      <div className="mt-4 ">
        <p className="text-gray-900 font-bold text-xs mb-2">
          {t('tr_creditCard')}
        </p>
        <table className="text-xs">
          <tbody>
            <tr>
              <td className="pr-4">{t('tr_name')}</td>
              <td id="qcp-transaction-details-event-and-logs-card-name">
                {event.option1}
              </td>
            </tr>
            <tr>
              <td className="pr-4">{t('tr_lastNumber')}</td>
              <td id="qcp-transaction-details-event-and-logs-card-last-number">
                ****{event.option2}
              </td>
            </tr>
            <tr>
              <td className="pr-4">{t('tr_expireDate')}</td>
              <td id="qcp-transaction-details-event-and-logs-card-expire-date">
                {event.option3}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-4">
        <p className="text-gray-900 font-bold text-xs mb-2">
          {t('tr_message')}
        </p>
        <p className="text-gray-900">{event.message}</p>
      </div>
    </div>
  )
}
