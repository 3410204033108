import Button from '@/components/Parts/Button/Blue'
import { useModal } from '@/hooks/modal'
import Input from '@/components/Parts/Inputs/Plain2'
import { useEffect, useRef, useState } from 'react'
import { checkTenantCode, checkTenantName } from '@/utils/validate'
import QCLogo from '../QCLogo'
import useTranslation from '@/hooks/i18n'
import Agreement from '@/components/Parts/Agreement'
import { useMutation } from '@apollo/client'
import { APPLY_TRIAL } from '@/api/graphql/mutations'
import { QUERY_SERVICE } from '@/api/graphql/queries'
import { ApplyTrial, Service } from '@/types'
import { useSpinner } from '@/hooks/spinner'
import errorHandler from '@/api/graphql/error'
import Success from './Success'
import Failed from './Failed'
import { AVAILABLE_CARDS, INVOICE_HISTORY } from '@/api/graphql/queries'
import { gqlClient } from '@/api/graphql'
import { useNavigate } from 'react-router-dom'
import { privatePaths, publicPaths } from '@/utils/path'
import laptop from '@/images/g176.png'
import { thirdPartyPaths } from '@/utils/path'

type Props = {
  reload?: () => Promise<void>
}

function useCreateTrial(reload?: () => Promise<void>) {
  const [service, setService] = useState<Service | undefined>(undefined)
  const [applyTrial] = useMutation<{ applyTrial: ApplyTrial }>(APPLY_TRIAL, {
    refetchQueries: [
      { query: AVAILABLE_CARDS },
      { query: INVOICE_HISTORY, variables: { offset: 100, limit: 100 } },
    ],
    awaitRefetchQueries: true,
  })
  const { enableSpinner, disableSpinner } = useSpinner()
  const ref = useRef(false)
  const { openModal } = useModal()
  const navigate = useNavigate()

  useEffect(() => {
    const img = new Image()
    img.src = laptop
  }, [])

  const getService = (serviceKey: string) =>
    new Promise<Service>((resolve, reject) => {
      // QUERY_SERVICE
      gqlClient
        .query<{ service: Service }>({
          query: QUERY_SERVICE,
          variables: { serviceKey },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          setService({ ...res.data!.service })
          resolve(res.data!.service)
        })
        .catch(() => {
          disableSpinner()
          navigate(publicPaths.somethingWentWrong)
          reject()
        })
    })

  useEffect(() => {
    // Polling if creating service is completed.
    const timer = setTimeout(() => {
      if (service && service.processing) {
        getService(service.serviceKey).then((res) => {
          if (!res.processing) {
            if (reload) {
              reload().then(() => {
                disableSpinner()
                openModal(
                  <Success
                    service={res}
                    laptop={<img src={laptop} alt="laptop" className="w-110" />}
                  />
                )
              })
            } else {
              // When created trial by /order
              disableSpinner()
              navigate(privatePaths.home)
            }
          }
        })
      }
    }, 5 * 1000)

    return () => {
      return clearTimeout(timer)
    }
  }, [service])

  const apply = (tenantCode: string, tenantName: string) => {
    if (ref.current) return
    ref.current = true
    enableSpinner()

    applyTrial({
      variables: {
        serviceType: 'qualitia_cloud',
        option1: tenantCode,
        option2: tenantName,
      },
    })
      .then((res) => {
        getService(res.data!.applyTrial.serviceKey)
        // getService and setService
        console.log(res.data!.applyTrial.serviceKey)
      })
      .catch((error) => {
        const res = errorHandler(error)
        disableSpinner()
        openModal(
          <Failed errMessage={res.message ? res.message : 'Unkown error'} />
        )
      })
      .finally(() => {
        ref.current = false
      })
  }
  return apply
}

export default function Product({ reload }: Props) {
  const { t } = useTranslation()
  const [tenantCode, setTenantCode] = useState('')
  const [tenantName, setTenantName] = useState('')
  const [agreement, setAgreement] = useState(false)
  const apply = useCreateTrial(reload)

  const isDisable =
    checkTenantCode(tenantCode).length > 0 ||
    checkTenantName(tenantName).length > 0 ||
    agreement === false

  const click = () => {
    apply(tenantCode, tenantName)
  }

  return (
    <>
      <div className="border border-gray-300 rounded-md shadow-md">
        <div className="p-8">
          <QCLogo className="w-32 mx-auto" />
        </div>

        <div className="border-t border-gray-200 px-8 pb-8">
          <div className="mt-4">
            <p className="text-gray-700">{t('tr_descriptionQualitiaCloud')}</p>
          </div>
          <div className="my-4">
            <div className="my-4">
              <Input
                title="tr_tenantCode"
                validate={checkTenantCode}
                maxLength={8}
                change={setTenantCode}
                value={tenantCode}
                autoFocus={true}
                id={'qcp-qtc-input-tenant-code'}
              />
            </div>
            <div className="my-4">
              <Input
                title="tr_tenantName"
                validate={checkTenantName}
                maxLength={2048}
                change={setTenantName}
                value={tenantName}
                id={'qcp-qtc-input-tenant-name'}
              />
            </div>
            <div className="my-4">
              <Agreement
                url={thirdPartyPaths.qualitiaCloudPrivaryPolicy}
                checked={agreement}
                setChecked={setAgreement}
              />
            </div>
          </div>

          <div className="mt-8">
            <Button
              name="tr_apply60days"
              disabled={isDisable}
              onClick={click}
            />
          </div>
        </div>
      </div>
    </>
  )
}
