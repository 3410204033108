import SuccessFormat from '@/components/Result/Success'
import { privatePaths } from '@/utils/path'

function Success() {
  return (
    <SuccessFormat
      title="tr_registerNewCard"
      message1="tr_registerNewCard1"
      message2="tr_registerNewCard2"
      goBackWhere="tr_seeAvarableCards"
      goBackTo={privatePaths.paymentCards}
    />
  )
}

export default Success
