import useTranslation from '@/hooks/i18n'
import { thirdPartyPaths } from '@/utils/path'

function Support() {
  const { t } = useTranslation()

  return (
    <div className=" text-center">
      {/* <h1 className="text-lg font-bold">{t('Support')}</h1>
      <a
        href={thirdPartyPaths.qualitiaContact}
        className="hover:underline text-gray-700 mr-8"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('Contact')}
      </a> */}
      <a
        href={thirdPartyPaths.qualitiaSitepolicy}
        className="hover:underline text-gray-700 "
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('tr_privacyPolicy')}
      </a>
    </div>
  )
}

export default Support
