import QualitiaCloud from './QualitiaCloud/Product'

type Props = {
  reload?: () => Promise<void>
}

export default function Products({ reload }: Props) {
  return (
    <div>
      <QualitiaCloud reload={reload} />
    </div>
  )
}
