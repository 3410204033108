import axios from 'axios'
import { appVersion } from '../version'

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL!,
})

function version(): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    const path = 'version.json'
    const hash = new Date().getTime()

    instance
      .get<any>(`${path}?${hash}`)
      .then((res) => {
        console.log('version.json', res.data.version)
        console.log('appVersion', appVersion)
        resolve(res.data.version !== appVersion)
      })
      .catch(() => {
        reject()
      })
  })
}

// revision()

export default version
