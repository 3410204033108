import { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import Auth from '@aws-amplify/auth'

import { forgotPasswordSubmit, signIn } from '@/auth'
import Button from '@/components/Parts/Button/Blue'
import Password from '@/components/Parts/Inputs/Password'
import Input from '@/components/Parts/Inputs/Plain2'
import useTranslation from '@/hooks/i18n'
import { useSpinner } from '@/hooks/spinner'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { privatePaths } from '@/utils/path'

interface Props {
  email: string
  signedIn: (user: any) => void
}

function SetNewPassword({ email, signedIn }: Props) {
  const [verificationCode, setVerificationCode] = useState('')
  const [password, setPassword] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState('')
  const { enableSpinner, disableSpinner } = useSpinner()
  // const navigate = useNavigate()
  const navigate = useNavigate()
  const location = useLocation()
  const search = location.search
  const query = queryString.parse(search)

  const { t } = useTranslation()

  useEffect(() => {
    setDisabled(verificationCode.length === 0 && password.length === 0)
  }, [verificationCode, password])

  const setNewPassword = (code: string, newPwd: string) => {
    setError('')
    enableSpinner()
    forgotPasswordSubmit(email, code, newPwd)
      .then(() => {
        signIn(email, newPwd)
          .then((user) => {
            Auth.sendCustomChallengeAnswer(user, '123456')
              .then((res) => {
                if (res.challengeName === 'CUSTOM_CHALLENGE') {
                  setError('Invalid password')
                } else {
                  navigate(
                    query.redirect === undefined
                      ? privatePaths.home
                      : (query.redirect as string)
                  )
                }
              })
              .catch(() => {
                setError('Invalid password')
              })
              .finally(() => {
                disableSpinner()
              })
            // navigate('/home')
          })
          .catch((err) => {
            disableSpinner()
            setError(err.message)
          })
      })
      .catch((err) => {
        disableSpinner()
        setError(err.message)
      })
  }

  return (
    <div className="md:w-96 md:border md:shadow-lg p-2 md:p-8">
      <div className="mb-8">
        <p className=" text-gray-700 font-bold">
          {t('We sent you a verification code')}
        </p>
      </div>
      <div className="mb-12">
        <div className="mb-8">
          <Input
            title="Verification Code"
            maxLength={254}
            autoFocus={true}
            value={verificationCode}
            change={(value) => setVerificationCode(value)}
            keydown={(value) => {
              setVerificationCode(value)
              setNewPassword(value, password)
            }}
          />
        </div>
        <div className="mb-4">
          <Password
            password={password}
            onChange={(password) => {
              setPassword(password)
            }}
            keyDown={(password) => {
              setPassword(password)
              setNewPassword(verificationCode, password)
            }}
          />
        </div>
        <p className="text-red-500 text-sm">{error}</p>
      </div>

      <Button
        name="tr_setNewPassword"
        onClick={() => setNewPassword(verificationCode, password)}
        disabled={disabled}
      />
    </div>
  )
}

export default SetNewPassword
