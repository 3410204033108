import { useState } from 'react'
import Anchor from '@/components/Parts/Text/Anchor'
import VerifyPassword from './VerifyPassword'
import MFA from '@/components/SignIn/MFA'
import { publicPaths } from '@/utils/path'

function SignIn() {
  const [user, setUser] = useState<any>(undefined)

  return (
    <div>
      <div className="w-full md:w-96 h-96 md:border md:shadow-lg bg-white p-4 md:p-8">
        {user === undefined && <VerifyPassword />}
        {user && <MFA user={user} allFailed={() => setUser(undefined)} />}
      </div>
      <Anchor
        text="tr_doNotHaveAccount"
        to={publicPaths.createUser}
        label="tr_signUp"
      />
    </div>
  )
}

export default SignIn
