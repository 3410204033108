import { REGISTER_CARD } from '@/api/graphql/mutations'
import Box from '@/components/PrivatePages/Box'
import Button from '@/components/Parts/Button/Blue'
import { useMutation } from '@apollo/client'
import { useSpinner } from '@/hooks/spinner'
import { stripeRedirect } from '@/utils/stripe'
import { useNavigate } from 'react-router-dom'
import { publicPaths } from '@/utils/path'
import ConfirmRedirect from '@/components/ConfirmRedirectToStripe'

type Props = {
  redirectURL: string
  query?: string
  serviceKey?: string
}

function RegisterCard({ redirectURL, query, serviceKey }: Props) {
  const [registerCard] = useMutation<{
    registerCard: { stripeSessionId: string }
  }>(REGISTER_CARD)
  const { enableSpinner, disableSpinner } = useSpinner()
  const navigate = useNavigate()

  const click = () => {
    enableSpinner()
    registerCard({ variables: { redirectUrl: redirectURL, query, serviceKey } })
      .then(({ data }) => {
        stripeRedirect(data!.registerCard.stripeSessionId).catch(() => {
          navigate(publicPaths.somethingWentWrong)
        })
      })
      .finally(() => {
        disableSpinner()
      })
  }

  return (
    <Box title="tr_registerNewCard">
      <div className="md:flex md:items-center">
        <ConfirmRedirect message="tr_replaceCreditCard" />
        <div className="ml-auto">
          <Button name="tr_register" onClick={click} id="qcp-reg-card-button" />
        </div>
      </div>
    </Box>
  )
}

export default RegisterCard
