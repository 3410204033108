import { FaUserCircle } from 'react-icons/fa'

import ChangePassword from './ChangePassword'
import Signout from './Signout'
import ContactUs from './ContactUs'

export const UserIcon = () => {
  return <FaUserCircle className="text-4xl text-white" />
}

export const UserMenu = ({ close }: { close?: () => void }) => {
  if (close === undefined) {
    throw Error('Fixme UserMenu')
  }

  return (
    <>
      <div className="my-2">
        <ChangePassword close={close} />
        <Signout close={close} />
      </div>
      <div className="border-b w-full border-gray-300 mb-2"></div>
      <ContactUs close={close} />
    </>
  )
}
