export function getServiceType(serviceKey: string) {
  return serviceKey.split('_').slice(2, -1).join('_')
}

export const serviceTypeTable = {
  serviceA: 'service_a',
  serviceB: 'service_b',
  qualitiaCloud: 'qualitia_cloud',
}

export const SERVICE_STATUS = {
  TRIAL: 'trial',
  SUBSCRIPTION: 'subscription',
  CREATING: 'creating',
  CANCELED: 'canceled',
}
