import useTranslation, { useNumberFormatter } from '@/hooks/i18n'

type Props = {
  subtotal: number
  tax: number
  currency: string
}

export default function Subtotal({ subtotal, tax, currency }: Props) {
  const n = useNumberFormatter(currency)
  const { t } = useTranslation()

  return (
    <div className="w-full mt-4 font-bold">
      <div className="flex items-center text-gray-800">
        <div className="flex items-center ml-auto">
          <p className="">{t('tr_subtotal')}</p>
          <p className="ml-2" id="qcp-transaction-details-p-subtotal">
            {n('currency', subtotal)}
          </p>
        </div>
        <div className="flex items-center ml-8">
          <p className="">{t('tr_tax')}</p>
          <p className="ml-2" id="qcp-transaction-details-p-tax">
            {n('currency', tax)}
          </p>
        </div>
      </div>
    </div>
  )
}
