import useTranslation from '@/hooks/i18n'
import NotifyFrame from './NotifyFrame'

interface Props {
  title: string
  titleColor: string
  subTitle: string
  to: string
  buttonName: string
}

function OutFrame(props: Props) {
  const { t } = useTranslation()

  return (
    <NotifyFrame {...props}>
      <div className="flex items-center mb-4 ">
        <div className="w-2 mr-4" />
        <a
          href={props.to}
          className="font-bold text-white hover:bg-blue-600 text-lg mt-4 rounded-full bg-blue-500 px-6 py-2"
        >
          {t(props.buttonName)}
        </a>
      </div>
    </NotifyFrame>
  )
}

export default OutFrame
