import Button from '@/components/Parts/Button/Blue'
import useTranslation from '@/hooks/i18n'
import { useMutation } from '@apollo/client'
import { UpdateCard } from '@/types'
import { UPDATE_CARD } from '@/api/graphql/mutations'
import { useSpinner } from '@/hooks/spinner'
import { useModal } from '@/hooks/modal'
import { stripeRedirect } from '@/utils/stripe'
import ConfirmRedirect from '@/components/ConfirmRedirectToStripe'

type Props = {
  cardKey: string
}

export default function ConfirmChange({ cardKey }: Props) {
  const { t } = useTranslation()
  const [updateCard] = useMutation<{ updateCard: UpdateCard }>(UPDATE_CARD)
  const { enableSpinner, disableSpinner } = useSpinner()
  const { closeModal } = useModal()

  const click = () => {
    enableSpinner()
    updateCard({
      variables: { cardKey, cancelRedirectUrl: window.location.pathname },
    })
      .then((result) => {
        disableSpinner()
        closeModal()
        stripeRedirect(result!.data!.updateCard.redirectTo)
      })
      .catch(() => {
        disableSpinner()
        closeModal()
      })
  }

  return (
    <div className="px-4 py-8 md:w-108">
      <div className="text-xl font-bold text-gray-600 mb-4">
        {t('tr_replaceCreditCardTitle')}
      </div>

      <ConfirmRedirect message={'tr_replaceCreditCard'} />

      <Button
        name="tr_yes"
        onClick={click}
        id="qcp-replace-card-button-confirm"
      />
    </div>
  )
}
