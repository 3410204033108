import React, { useEffect, useRef, useState } from 'react'

type ComponentsProps = {
  ref?: React.RefObject<HTMLDivElement>
  close?: () => void
}

type Props = {
  Icon: (props: ComponentsProps) => JSX.Element
  Menu: (props: ComponentsProps) => JSX.Element
}

const DropDownMenu = ({ Icon, Menu }: Props) => {
  const [isOpen, setOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const ddRef = useRef<HTMLDivElement>(null)

  const check = (divRef: React.RefObject<HTMLDivElement>, event: any) => {
    return (
      divRef != null &&
      divRef.current != null &&
      !divRef.current.contains(event.target)
    )
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (check(menuRef, event) && check(ddRef, event)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  const click = () => setOpen(!isOpen)

  return (
    <>
      <div className="relative inline-block text-left h-full">
        <div
          onClick={click}
          className="flex items-center h-full cursor-pointer"
          ref={menuRef}
          id={'qcp-menu-div-user-icon'}
        >
          <Icon />
        </div>
        {isOpen && (
          <div
            ref={ddRef}
            className="origin-top-right absolute right-0 mt-2 py-2 w-56 rounded-md border border-gray-200 shadow-xl bg-white ring-1 ring-black ring-opacity-5 z-50"
          >
            <div className="px-2 md:px-0">
              <Menu close={() => setOpen(false)} />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default DropDownMenu
