import useTranslation from '@/hooks/i18n'
import FadeLoader from 'react-spinners/FadeLoader'

export default function Loader() {
  const { t } = useTranslation()

  return (
    <div
      className="absolute top-0 left-0 w-full h-full bg-black opacity-60 flex items-center justify-center"
      id="qcp-div-card-loader"
    >
      <div>
        <FadeLoader
          color={'white'}
          loading={true}
          css={`
            display: block;
            margin: 0 auto;
            border-color: white;
          `}
        />
        <p className="text-white text-lg mt-2">{t('tr_nowProcessing')}</p>
      </div>
    </div>
  )
}
