import PulseLoader from 'react-spinners/PulseLoader'

import Overlay from '@/components/Parts/Overlay'
import useTranslation from '@/hooks/i18n'
import { useSpinner } from '@/hooks/spinner'
// import qualitiaLoader from '@/images/loading_1.png'

export function SpinnerView() {
  const { t } = useTranslation()

  return (
    <>
      <Overlay backgroundColor={'rgba(255,255,255,.8)'}>
        <div className="  text-center">
          {/* <div className="md:flex md:items-center p-8 ">
            <img src={qualitiaLoader} alt="loader" className="mx-auto" />
            <div className="text-center md:text-left">
              <h1 className="text-gray-800 text-2xl font-bold">
                {t('tr_nowProcessing')}
              </h1>
              <p className="text-gray-800 text-lg mb-1">
                {t('tr_LoadingAndWait')}
              </p>
              <p className="text-gray-600 text-sm">QUALITIA CUSTOMER PORTAL</p>
            </div>
          </div> */}
          <div className="text-center mb-4">
            <h1 className="text-gray-800 text-2xl font-bold">
              {t('tr_nowProcessing')}
            </h1>
            <p className="text-gray-800 text-lg mb-1">
              {t('tr_LoadingAndWait')}
            </p>
            <p className="text-gray-600 text-sm">QUALITIA CUSTOMER PORTAL</p>
          </div>
          <PulseLoader
            color={'#10B981'}
            loading={true}
            css={`
              display: block;
              margin: 0 auto;
              border-color: #10b981;
            `}
            size={16}
          />
        </div>
      </Overlay>
    </>
  )
}

const Spinner = () => {
  const { showSpinner } = useSpinner()
  const { t } = useTranslation()

  return <>{showSpinner && <SpinnerView />}</>
}

export default Spinner
