import useTranslation from '@/hooks/i18n'
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineLoading3Quarters,
  AiOutlineInfo,
} from 'react-icons/ai'

export type Props = {
  status: string
  id?: string
}

const _table: {
  [key: string]: { style: string; name: string; icon: JSX.Element }
} = {
  confirmed: {
    style: 'px-1 rounded-sm text-green-800 bg-green-100 inline-block',
    name: 'tr_paidStatus',
    icon: <AiOutlineCheck />,
  },
  canceled: {
    style: 'px-1 rounded-sm text-red-800 bg-red-100 inline-block',
    name: 'tr_canceledStatus',
    icon: <AiOutlineClose />,
  },
  waiting: {
    style: 'px-1 rounded-sm text-gray-800 bg-gray-100 inline-block',
    name: 'tr_incompletedStatus',
    icon: <AiOutlineLoading3Quarters />,
  },
  incoming: {
    style: 'px-1 rounded-sm text-yellow-800 bg-yellow-100 inline-block',
    name: 'tr_draftStatus',
    icon: <AiOutlineInfo />,
  },
  renewed: {
    style: 'px-1 rounded-sm text-blue-800 bg-blue-100 inline-block',
    name: 'tr_contractRenewalStatus',
    icon: <AiOutlineCheck />,
  },
  ordered: {
    style: 'px-1 rounded-sm text-gray-800 bg-gray-100 inline-block',
    name: 'tr_acceptOrdered',
    icon: <AiOutlineLoading3Quarters />,
  },
}

export default function Status({ status, id }: Props) {
  const { t } = useTranslation()
  const statusStyle = _table[status]

  if (statusStyle === undefined) {
    return (
      <div className="px-2" id={id}>
        {status}
      </div>
    )
  }

  return (
    <div className={statusStyle.style}>
      <div className={`flex items-center`}>
        <div className="mr-2" id={id}>
          {t(statusStyle.name)}
        </div>
        {statusStyle.icon}
      </div>
    </div>
  )
}
