import { ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import useTranslation from '@/hooks/i18n';

interface Props {
  title: string
  children: ReactNode
}

function PageTitle({ children, title }: Props) {
  const { t } = useTranslation()
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t(title)}</title>
      </Helmet>

      {children}
    </HelmetProvider>
  )
}

export default PageTitle
