import QualitiaCloud from './QualitiaCloud/Subscription'
import { getServiceType, serviceTypeTable } from './helper'

type Props = {
  status: string
  purchaseAt: string
  purchasedBy: string
  serviceKey: string
  lastAvailableDate?: string
  currency: string
  refech: () => Promise<void>
}

export default function Subsc(props: Props) {
  const serviceType = getServiceType(props.serviceKey)

  if (serviceType.startsWith(serviceTypeTable.qualitiaCloud)) {
    return <QualitiaCloud {...props} />
  }

  return <div>Subscription</div>
}
