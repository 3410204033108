import { useAuth } from '@/hooks/auth'

import UserProfile from './UserProfile'
import StyleLink from './Link'
import { privatePaths, adminPaths } from '@/utils/path'

const SideNav = () => {
  const { me } = useAuth()

  return (
    <>
      <div className="px-8 sm:px-2 py-4 w-full sm:w-56">
        <div className="flex items-center text-md mb-4 border-b outline-none focus:outline-none border-gray-300 px-1 text-gray-600">
          <UserProfile
            fullName={me.fullName}
            email={me.email}
            organization={me.organization}
          />
        </div>
        <nav className="">
          <ul>
            <StyleLink
              indent="pl-8"
              py="py-2"
              path="/home"
              name="tr_home"
              id="qcp-menu-li-home"
            />
            <li className="pl-0 my-4 border-t border-gray-200 py-4">
              <ul>
                <StyleLink
                  indent="pl-8"
                  py="py-2"
                  path={privatePaths.paymentCards}
                  name="tr_cards"
                  id="qcp-menu-li-cards"
                />
                <StyleLink
                  indent="pl-8"
                  py="py-2"
                  path={privatePaths.paymentTransactions}
                  name="tr_transactions"
                  id="qcp-menu-li-transactions"
                />
              </ul>
            </li>
            <ul>
              <li className="pl-0 my-4 border-t border-gray-200 py-2">
                <ul>
                  <StyleLink
                    indent="pl-8"
                    py="py-2"
                    path={`/admin/${adminPaths.settings}`}
                    name="tr_settings"
                    id="qcp-menu-li-settings"
                  />
                </ul>
              </li>
            </ul>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default SideNav
