import InvoiceFromat from '@/components/Invoice'
import { Invoice } from '@/types'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { SESSION_INVOICE } from '@/api/graphql/queries'
import { useNavigate } from 'react-router-dom'
import Loader from '@/components/Parts/Loader'
import JumpToStripe from './JumpToStripe'
import { publicPaths } from '@/utils/path'
import StripeResult from '@/components/Parts/Modal/StripeResult'

export default function InvoicePage() {
  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get('id')
  const navigate = useNavigate()
  const { data, error, loading } = useQuery<{ sessionInvoice: Invoice }>(
    SESSION_INVOICE,
    {
      // Need URL decoding if you want to get query
      variables: { sessionId: sessionId == null ? '' : sessionId },
    }
  )

  if (loading) {
    return (
      <div className="md:w-120 md:mx-auto py-4">
        <Loader />
      </div>
    )
  }

  if (error) {
    navigate(publicPaths.somethingWentWrong)
    return <></>
  }

  return (
    <div>
      <InvoiceFromat title="tr_order" invoice={data!.sessionInvoice} />
      <div className="mt-2">
        <JumpToStripe sessionId={sessionId == null ? '' : sessionId} />
      </div>
      <StripeResult
        successMessage="tr_successBuy"
        cancelMessage="tr_cancelBuy"
      />
    </div>
  )
}
