import PageFrame from '@/components/PublicPages/PageFrame'
import useTranslation from '@/hooks/i18n'
import { ReactNode } from 'react'

interface Props {
  title: string
  titleColor: string
  subTitle: string
  children?: ReactNode
}

function OutFrame(props: Props) {
  const { t } = useTranslation()

  return (
    <PageFrame title={props.title}>
      <div className="mt-8 md:mt-48">
        <div className="flex items-center mb-4">
          <div className="border-l border-gray-200 pl-4">
            <h1 className={`font-bold text-6xl ${props.titleColor} mb-4`}>
              {t(props.title)}
            </h1>
            <p className="text-lg text-gray-700 font-bold mb-4">
              {t(props.subTitle)}
            </p>
          </div>
        </div>
        {props.children && props.children}
      </div>
    </PageFrame>
  )
}

export default OutFrame
