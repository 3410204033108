import useTranslation from '@/hooks/i18n'
import { ReactNode } from 'react'

type Props = {
  title: string
  children: ReactNode
}

export default function LabelText({ title, children }: Props) {
  const { t } = useTranslation()
  return (
    <div className="flex items-center my-1">
      <div className="w-36 pr-4 text-gray-600">{t(title)}</div>
      <div className="w-48 break-all flex items-center">{children}</div>
    </div>
  )
}
