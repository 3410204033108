export type State = {
  isOpen: boolean
  children?: JSX.Element
}

export type Action =
  | { type: 'openModal'; payload: { isOpen: boolean; children?: JSX.Element } }
  | { type: 'closeModal' }

export const reducer = (action: Action) => {
  switch (action.type) {
    case 'openModal':
      return {
        ...action.payload,
      }
    case 'closeModal': {
      return {
        isOpen: false,
      }
    }
  }
}

export const initialState = {
  isOpen: false,
}
