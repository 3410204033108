import { gql } from '@apollo/client'

export const CANCELE_CUSTOMER_PORTAL = gql`
  mutation {
    deleteOrganization {
      result
    }
  }
`

export const BUYING_SERVICE = gql`
  mutation BuyingService($sessionId: String!, $cancelRedirectUrl: String!) {
    buyingService(
      sessionId: $sessionId
      cancelRedirectUrl: $cancelRedirectUrl
    ) {
      stripeSessionId
    }
  }
`

export const BUYING_SERVICE_BY_CARD = gql`
  mutation BuyingServiceByCard($sessionId: String!, $cardKey: String!) {
    buyingServiceByCard(sessionId: $sessionId, cardKey: $cardKey) {
      result
      message
    }
  }
`

export const BUYING_SERVICE_BY_NEW_CARD = gql`
  mutation BuyingServiceByNewCard(
    $sessionId: String!
    $cancelRedirectUrl: String!
  ) {
    buyingServiceByNewCard(
      sessionId: $sessionId
      cancelRedirectUrl: $cancelRedirectUrl
    ) {
      stripeSessionId
    }
  }
`

export const CREATE_ORDER_SESSION = gql`
  mutation CreateOrderSession(
    $serviceType: String!
    $serviceKey: String
    $coupon: String
    $option1: String
    $option2: String
  ) {
    createOrderSession(
      serviceType: $serviceType
      serviceKey: $serviceKey
      coupon: $coupon
      option1: $option1
      option2: $option2
    ) {
      sessionKey
    }
  }
`

export const REGISTER_CARD = gql`
  mutation RegisterCard(
    $redirectUrl: String!
    $serviceKey: String
    $query: String
  ) {
    registerCard(
      redirectUrl: $redirectUrl
      serviceKey: $serviceKey
      query: $query
    ) {
      stripeSessionId
    }
  }
`

export const DELETE_CARD = gql`
  mutation DeleteCard($cardKey: String!) {
    deleteCard(cardKey: $cardKey) {
      result
      message
    }
  }
`

export const SELECT_CARD_FOR_SERVICE = gql`
  mutation SelectCardForService($cardKey: String!, $serviceKey: String!) {
    selectCardForService(cardKey: $cardKey, serviceKey: $serviceKey) {
      result
    }
  }
`

export const SUBSCRIPTION = gql`
  mutation Subscription($serviceKey: String!, $enable: Boolean!) {
    subscription(serviceKey: $serviceKey, enable: $enable) {
      result
      invoiceHistory {
        invoiceId
        createAt
        status
        subtotal
        tax
        total
        services
      }
    }
  }
`

export const SELECT_CARD_FOR_SUBSCRIPTION = gql`
  mutation SelectCardForSubscription($cardKey: String!, $serviceKey: String!) {
    selectCardForSubscription(cardKey: $cardKey, serviceKey: $serviceKey) {
      serviceName
      availableCards {
        name
        lastNumber
        brand
        expMonth
        expYear
        cardKey
        services {
          serviceName
          serviceKey
        }
        cardOwner {
          userName
          email
        }
      }
    }
  }
`

export const UPDATE_CARD = gql`
  mutation UpdateCard($cardKey: String!, $cancelRedirectUrl: String!) {
    updateCard(cardKey: $cardKey, cancelRedirectUrl: $cancelRedirectUrl) {
      redirectTo
    }
  }
`

export const APPLY_TRIAL = gql`
  mutation ApplyTrial(
    $serviceType: String!
    $option1: String
    $option2: String
    $option3: String
    $option4: String
  ) {
    applyTrial(
      serviceType: $serviceType
      option1: $option1
      option2: $option2
      option3: $option3
      option4: $option4
    ) {
      serviceKey
    }
  }
`

export const UPDATE_NEXT_CONTRACT = gql`
  mutation UpdateNextContract(
    $serviceKey: String!
    $option1: String
    $option2: String
    $option3: String
    $option4: String
    $option5: String
    $option6: String
    $option7: String
  ) {
    updateNextContract(
      serviceKey: $serviceKey
      option1: $option1
      option2: $option2
      option3: $option3
      option4: $option4
      option5: $option5
      option6: $option6
      option7: $option7
    ) {
      result
    }
  }
`
